import React, { Component } from "react";
import i18next from "i18next";
//import { Multiselect } from 'multiselect-react-dropdown';
import MultiSelect from "react-multi-select-component";
import axios from "axios";
import Swal from "sweetalert2";
import { baseurl, baseurlImg } from "../../../../Components/BaseUrl";
import ClipLoader from "react-spinners/ClipLoader";
import cookie from "js-cookie";
import toast, { Toaster } from "react-hot-toast";
import API from "../../../../utils/API";
import { connect } from "react-redux";

class EditAgency extends Component {
  constructor(props) {
    super(props);
    this.state = {
      agency: {},
      name: "",
      name_ar: "",
      email: "",
      password: "",
      password_confirmation: "",
      mobile: "",

      tradelicense: "",
      expire_date: "",
      basic: 0,
      featured: 0,
      premium: 0,

      image: null,
      imageShow: null,
      profile: "",
      tradelicenseprofile: "",
      selected: [],
      input: {},
      errors: {},
      isLoading: false,
      newerrors: {},
      checked: false,
      emirates: [],
      agency_summary_en: "",
      agency_summary_ar: "",
      fulladdress_en: "",
      fulladdress_ar: "",
      tradelicenseimage: null,
      tradelicenseShow: null,
      hasError: false,
      success: false,
    };
  }

  componentDidMount() {
    let id = this.props.match.params.id;
    const token = cookie.get("token");

    // emirates
    API.get("/cities")
      .then((response) => {
        // console.log("emirates",response.data.emirates)
        this.setState({ emirates: response.data.emirates });
      })
      .catch((e) => console.log("error"));

    API.get("/edit-agency/" + id)
      .then((response) => {
        this.setState({
          agency: response.data,
          input: response.data,
          name: response.data.name_en,
          name_ar: response.data.name_ar,
          email: response.data.email,
          mobile: response.data.mobile,
          tradelicense: response.data.tradelicense,
          expire_date: response.data.expire_date,
          fulladdress_en: response.data.fulladdress_en,
          fulladdress_ar: response.data.fulladdress_ar,
          tradelicenseprofile: response.data.tradelicenceimage,
          basic: response.data.basic,
          featured: response.data.featured,
          premium: response.data.premium,
          profile: response.data.profile,
          agency_summary_en: response.data.agency_summary_en,
          agency_summary_ar: response.data.agency_summary_ar,
          active: response.data.active,
          checked: response.data.active == 1 ? true : false,
        });
        //this.setState({categories:response.data.categories})
      })
      .catch((e) => console.log("error"));
  }
  onSelect(selectedList, selectedItem) {
    console.log("selectedList", selectedList);
    console.log("selectedItem", selectedItem);
    console.log("selectedValues", this.state.selected);
  }

  onRemove(selectedList, removedItem) {
    console.log("selectedList", selectedList);
    console.log("removedItem", removedItem);
  }

  handleName = (e) => {
    e.preventDefault();
    let input = this.state.input;
    input[e.target.name] = e.target.value;
    this.setState({ name: e.target.value, input });
  };
  handleName_ar = (e) => {
    e.preventDefault();
    let input = this.state.input;
    input[e.target.name] = e.target.value;
    this.setState({ name_ar: e.target.value, input });
  };
  handleEmail = (e) => {
    e.preventDefault();
    let input = this.state.input;
    input[e.target.name] = e.target.value;
    this.setState({ email: e.target.value, input });
  };
  handleMobile = (e) => {
    e.preventDefault();
    let input = this.state.input;
    input[e.target.name] = e.target.value;
    this.setState({ mobile: e.target.value, input });
  };
  handlePassword = (e) => {
    e.preventDefault();
    let input = this.state.input;
    input[e.target.name] = e.target.value;
    this.setState({ password: e.target.value, input });
  };
  handlePasswordConfirmation = (e) => {
    e.preventDefault();
    let input = this.state.input;
    input[e.target.name] = e.target.value;
    this.setState({ password_confirmation: e.target.value, input });
  };

  // only agencies
  handleTradelicense = (e) => {
    e.preventDefault();
    let input = this.state.input;
    input[e.target.name] = e.target.value;
    this.setState({ tradelicense: e.target.value, input });
  };
  handleExpiredate = (e) => {
    e.preventDefault();
    this.setState({ expire_date: e.target.value });
  };

  handleBasic = (e) => {
    e.preventDefault();
    let input = this.state.input;
    input[e.target.name] = e.target.value;
    this.setState({ basic: e.target.value, input });
  };

  handleFeatured = (e) => {
    e.preventDefault();
    let input = this.state.input;
    input[e.target.name] = e.target.value;
    this.setState({ featured: e.target.value, input });
  };
  handlePremium = (e) => {
    e.preventDefault();
    let input = this.state.input;
    input[e.target.name] = e.target.value;
    this.setState({ premium: e.target.value, input });
  };

  handleFulladdressEn = (e) => {
    e.preventDefault();
    this.setState({ fulladdress_en: e.target.value });
  };
  handleFulladdressAr = (e) => {
    e.preventDefault();
    this.setState({ fulladdress_ar: e.target.value });
  };
  handleUpload = (e) => {
    e.preventDefault();

    let files = e.target.files || e.dataTransfer.files;
    if (!files.length) return;
    this.createImage(files[0]);

    //console.log(e.target.files[0])
    this.setState({ imageShow: URL.createObjectURL(e.target.files[0]) });
  };

  createImage(file) {
    let reader = new FileReader();
    reader.onload = (e) => {
      this.setState({
        image: e.target.result,
      });
    };
    reader.readAsDataURL(file);
  }

  handleTradelicenceUpload = (e) => {
    e.preventDefault();

    let files = e.target.files || e.dataTransfer.files;
    if (!files.length) return;
    this.createtradelicenceImage(files[0]);

    //console.log(e.target.files[0])
    this.setState({ tradelicenseShow: URL.createObjectURL(e.target.files[0]) });
  };

  createtradelicenceImage(file) {
    let reader1 = new FileReader();
    reader1.onload = (e) => {
      this.setState({
        tradelicenseimage: e.target.result,
      });
    };
    reader1.readAsDataURL(file);
  }

  setSelected = (e) => {
    this.setState({ selected: e });
  };

  handleagencySummaryEn = (e) => {
    //console.log(e.target.checked)
    this.setState({ agency_summary_en: e.target.value });
  };
  handleagencySummaryAr = (e) => {
    //console.log(e.target.checked)
    this.setState({ agency_summary_ar: e.target.value });
  };

  saveData = (e) => {
    e.preventDefault();
    const token = cookie.get("token");
    // console.log(this.state.input)
    let id = this.props.match.params.id;

    // alert("begin")
    //console.log(this.state);

    const data = {
      name: this.state.name,
      name_ar: this.state.name_ar,
      email: this.state.email,
      address: this.state.address,
      mobile: this.state.mobile,
      password: this.state.password,
      password_confirmation: this.state.password_confirmation,
      tradelicense: this.state.tradelicense,
      expire_date: this.state.expire_date,
      fulladdress_en: this.state.fulladdress_en,
      fulladdress_ar: this.state.fulladdress_ar,
      basic: this.state.basic,
      featured: this.state.featured,
      premium: this.state.premium,
      agency_summary_en: this.state.agency_summary_en,
      agency_summary_ar: this.state.agency_summary_ar,
      image: this.state.image,
      tradelicenseimage: this.state.tradelicenseimage,
      active: this.state.checked == true ? 1 : 0,
    };
    this.setState({ isLoading: true });
    API.put("/agency/" + id, data)
      .then((response) => {
        if (response.data.hasError) {
          this.setState({
            isLoading: false,
            hasError: response.data.hasError,
            success: response.data.success,
          });
          toast.error(response.data.msg);
          return;
        } else {
          this.setState({ isLoading: false });

          Swal.fire({
            title: "Done!",
            text: "user is updated.",
            icon: "success",
            timer: 2000,
            button: false,
          });

          this.setState({
            name: "",
            name_ar: "",
            email: "",
            image: null,
            imageShow: null,
            mobile: "",
            password: "",
            password_confirmation: "",
            selected: "",
            isLoading: false,
            hasError: response.data.hasError,
            success: response.data.success,
          });
          this.props.history.push("/agencies");
        }
      })
      .catch((error) => {
        if (!error.response) {
          // network error
          this.setState({
            newerrors: JSON.stringify({ errors: "Error: Network Error" }),
            isLoading: false,
          });
        } else {
          this.setState({ newerrors: error.response.data, isLoading: false });
        }
      });

    // console.log("data",data)
  };

  buttonRender = () => {
    if (this.state.isLoading) {
      return <ClipLoader color={"blue"} loading={true} size={30} />;
    }
    return (
      <button type="submit" className="btn btn-primary" onClick={this.saveData}>
        {i18next.t("submit")}
      </button>
    );
  };

  handleChecked = (e) => {
    //console.log(e.target.checked)
    this.setState({ checked: !this.state.checked });
  };

  render() {
    const { lang } = this.props;

    return (
      <div className="container-fluid">
        <Toaster />

        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">
                {lang=="en"? "Account information":" معلومات الحساب"}
                </h4>
              </div>
              <div className="card-body">
                <div className="basic-form">
                  <form>
                    <div className="row">
                      <div className="mb-3">
                        <label>{lang=="en"? "Name":" الاسم"}</label>
                        <input
                          type="text"
                          value={this.state.name}
                          name="name"
                          className="form-control input-default "
                          placeholder={lang=="en"? "Name":" الاسم"}
                          onChange={this.handleName}
                        />
                      </div>
                      <div className="mb-3">
                        <label>{lang=="en"? "Name arabic":" الاسم بالعربية"}</label>
                        <input
                          type="text"
                          value={this.state.name_ar}
                          name="name_ar"
                          className="form-control input-rounded"
                          placeholder={lang=="en"? "Name arabic":" الاسم بالعربية"}
                          onChange={this.handleName_ar}
                        />
                      </div>
                      <div className="mb-3">
                        <label>{lang=="en"? "Email":" البريد الالكتروني"}</label>
                        <input
                          type="email"
                          value={this.state.email}
                          name="email"
                          className="form-control input-default "
                          placeholder={lang=="en"? "Email":" البريد الالكتروني"}
                          onChange={this.handleEmail}
                        />
                      </div>

                      <div className="mb-3">
                        <label>{lang=="en"? "Mobile":" الجوال"}</label>

                        <input
                          type="text"
                          name="mobile"
                          value={this.state.mobile}
                          className="form-control input-default "
                          placeholder={lang=="en"? "Mobile":" الجوال"}
                          onChange={this.handleMobile}
                        />
                      </div>

                      <div className="mb-3">
                        <label>{lang=="en"? "Description":" الوصف"}</label>

                        <textarea
                          type="text"
                          value={this.state.agency_summary_en}
                          className="form-control wizard-required"
                          id="agency_summary_en"
                          placeholder={lang=="en"? "Description":" الوصف"}
                          rows={5}
                          onChange={this.handleagencySummaryEn}
                        />
                      </div>

                      <div className="mb-3">
                        <label>{lang=="en"? "Description arabic":" الوصف عربي"}</label>
                        <textarea
                          type="text"
                          value={this.state.agency_summary_ar}
                          className="form-control"
                          id="agency_summary_ar"
                          placeholder={lang=="en"? "Description arabic":" الوصف عربي"}
                          rows={5}
                          onChange={this.handleagencySummaryAr}
                        />
                      </div>

                      <div className="mb-3">
                        <label>{lang=="en"? "Trade license":" الرخصة التجارية"}</label>
                        <input
                          type="text"
                          name="tradelicense"
                          value={this.state.tradelicense}
                          className="form-control input-default "
                          placeholder={lang=="en"? "Trade license ":" الرخصة التجارية"}
                          onChange={this.handleTradelicense}
                        />
                      </div>

                      <div className="row mt-3">
                        <div className="input-group mb-3">
                          <span className="input-group-text">
                            {lang == "en"
                              ? "Trade licence copy"
                              : "ملف الرخصة التجارية"}
                          </span>
                          <div className="form-file">
                            <input
                              type="file"
                              className="form-file-input form-control"
                              onChange={this.handleTradelicenceUpload}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="mb-3">
                        <label>
                          {lang == "en" ? "Expire date" : "تاريخ الانتهاء"}
                        </label>
                        <input
                          type="date"
                          name="expire_date"
                          value={this.state.expire_date}
                          className="form-control input-default "
                          onChange={this.handleExpiredate}
                        />
                      </div>

                      <div className="mb-3">
                        <label>
                          {lang == "en" ? "Full address " : "عنوان المكتب "}
                        </label>
                        <input
                          type="text"
                          name="fulladdress_en"
                          value={this.state.fulladdress_en}
                          className="form-control input-default "
                          placeholder={
                            lang == "en" ? "Full address " : "عنوان المكتب "
                          }
                          onChange={this.handleFulladdressEn}
                        />
                      </div>

                      <div className="mb-3">
                        <label>
                          {lang == "en"
                            ? "Full address arabic "
                            : "عنوان المكتب بالعربية"}
                        </label>

                        <input
                          type="text"
                          name="fulladdress_ar"
                          value={this.state.fulladdress_ar}
                          className="form-control input-default "
                          placeholder={
                            lang == "en"
                              ? "Full address arabic "
                              : "عنوان المكتب بالعربية"
                          }
                          onChange={this.handleFulladdressAr}
                        />
                      </div>

                      <h3>{lang=="en"? "Package":" الحزمة"}</h3>
                      <hr />

                      <div className="row">
                        <div className="col-md-3">
                          <label>{lang=="en"? "Basic":" اساسي"}</label>
                          <div className="mb-3">
                            <input
                              type="text"
                              name="basic"
                              value={this.state.basic}
                              className="form-control input-default "
                              placeholder={lang=="en"? "Basic":" اساسي"}
                              onChange={this.handleBasic}
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <label>{lang=="en"? "Featured":" مميز"}</label>
                          <div className="mb-3">
                            <input
                              type="text"
                              name="featured"
                              value={this.state.featured}
                              className="form-control input-default "
                              placeholder={lang=="en"? "Featured":" مميز"}
                              onChange={this.handleFeatured}
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <label>{lang=="en"? "Premium":" عالي"}</label>
                          <div className="mb-3">
                            <input
                              type="text"
                              name="premium"
                              value={this.state.premium}
                              className="form-control input-default "
                              placeholder={lang=="en"? "Premium":" عالي"}
                              onChange={this.handlePremium}
                            />
                          </div>
                        </div>
                      </div>
                      <div className=" ">
                        {this.state.image == null ? (
                          <img
                            style={{ height: "100px", width: "100px" }}
                            src={
                              this.state.agency.logo == undefined
                                ? baseurlImg + "/uploads/profiles/no_logo.png"
                                : baseurlImg +
                                  "/uploads/profiles/" +
                                  this.state.agency.logo
                            }
                          />
                        ) : null}
                        {this.state.image != null ? (
                          <img
                            src={this.state.imageShow}
                            style={{
                              width: "100px",
                              height: "100px",
                              borderRadius: "50px",
                            }}
                          />
                        ) : null}
                        <div className="input-group mb-3">
                          <span className="input-group-text">
                            {lang == "en"
                              ? "Profile Image"
                              : "صورة الملف الشخصي"}
                          </span>
                          <div className="form-file">
                            <input
                              type="file"
                              className="form-file-input form-control"
                              onChange={this.handleUpload}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="mb-3">
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="flexSwitchCheckChecked"
                            checked={this.state.checked}
                            onChange={this.handleChecked}
                          />
                          <label
                            className="form-check-label"
                            for="flexSwitchCheckChecked"
                          >
                            {this.state.checked == true ? (
                              <span className="badge light badge-success">
                                {i18next.t("active")}
                              </span>
                            ) : (
                              <span className="badge light badge-danger">
                                {i18next.t("inactive")}
                              </span>
                            )}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-4">{this.buttonRender()}</div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  lang: state.auth.lang, 
});
export default connect(mapStateToProps)(EditAgency);

