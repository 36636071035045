import React, { useState, useRef } from 'react';
import { FiSend, FiPaperclip, FiMic } from 'react-icons/fi'; // Import FiMic for audio recording icon

function MessageInput({ index, onSend, onAttach, onAudioRecorded }) {
  const [inputValue, setInputValue] = useState('');
  const [files, setFiles] = useState([]);
  const [audioURL, setAudioURL] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);

  const startRecording = async () => {
    try {
      // Request permission to access the microphone
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

      // Initialize MediaRecorder
      mediaRecorderRef.current = new MediaRecorder(stream);
      audioChunksRef.current = []; // Clear previous recordings

      // Collect audio data chunks
      mediaRecorderRef.current.ondataavailable = (event) => {
        audioChunksRef.current.push(event.data);
      };

      // Stop event: Generate audio file URL
      mediaRecorderRef.current.onstop = () => {
        const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/webm' });
        const url = URL.createObjectURL(audioBlob);
        setAudioURL(url);
        if (onAudioRecorded) onAudioRecorded(url);
      };

      // Start recording
      mediaRecorderRef.current.start();
      setIsRecording(true);
    } catch (error) {
      console.error('Error accessing microphone:', error);
      alert('Microphone permission is required.');
    }
  };

  const stopRecording = () => {
    // Stop recording and finalize audio
    mediaRecorderRef.current?.stop();
    setIsRecording(false);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSend = () => {
    if (onSend && (inputValue.trim() || audioURL)) {
        
      onSend(inputValue, files, audioURL);
      setInputValue('');
      setFiles([]);
      setAudioURL(null); // Clear after sending
    }
  };

  const handleAttach = (e) => {
    if (onAttach) {
      setFiles(e.target.files);
      onAttach(e.target.files);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        marginTop: '3px',
        marginLeft: '10px',
        border: '1px solid #ccc',
        borderRadius: '20px',
        padding: '1px 5px',
        width: '900px',
        backgroundColor: '#f9f9f9',
      }}
    >
      {/* Attach File Icon */}
      <label style={{ cursor: 'pointer' }}>
        <FiPaperclip style={{ marginRight: '10px', fontSize: '1.2rem', color: '#888' }} />
        <input
          type="file"
          multiple
          style={{ display: 'none' }}
          onChange={handleAttach}
        />
      </label>

      {/* Audio Record Icon */}
      <div
        onClick={isRecording ? stopRecording : startRecording}
        style={{
          cursor: 'pointer',
          marginRight: '10px',
          color: isRecording ? '#ff0000' : '#888',
          pointerEvents: inputValue.trim() ? 'none' : 'auto', // Disable recording when text is present
        }}
      >
        <FiMic style={{ fontSize: '1.2rem' }} />
      </div>

      {/* Text Input */}
      <input
        type="text"
        placeholder={"Type your message here..."}
        value={inputValue}
        onChange={handleInputChange}
        style={{
          flex: 1,
          border: 'none',
          outline: 'none',
          fontSize: '0.9rem',
          backgroundColor: 'transparent',
          height: '25px',
          pointerEvents: isRecording ? 'none' : 'auto', // Disable typing when recording
        }}
      />

      {/* Send Icon */}
      <FiSend
        onClick={handleSend}
        style={{
          marginLeft: '10px',
          fontSize: '1.2rem',
          color: inputValue.trim() || audioURL ? '#007bff' : '#ccc',
          cursor: inputValue.trim() || audioURL ? 'pointer' : 'not-allowed',
        }}
      />
    </div>
  );
}

export default MessageInput;
