import React, { Component } from "react";

import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  XAxis,
  YAxis,
  CartesianGrid,
  Bar,
  BarChart,
} from "recharts";
import ReactECharts from "echarts-for-react";
import API from "../../utils/API";
import { connect } from "react-redux";
import { Alert  } from "antd";
import {Link} from "react-router-dom"
import { ArrowRightOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { Breadcrumb } from "antd";
import { Spin } from "antd";
const COLORS = [
  "#0088FE",
  "#00C49F",
  "#FFBB28",
  "#FF8042",
  "#8A2BE2",
  "#A52A2A",
  "#D2691E",
  "#2F4F4F",
];
const RADIAN = Math.PI / 180;

let loopData = [];
const width = window.screen.width;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

class AgencyView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      agents: [],
      properties: [],
      chartproperties: [],
      agency: {},
      basic: 0,
      featured: 0,
      premium: 0,
      propertyviews: [],
      total_agents: 0,
      rents: 0,
      sales: 0,
      viewed: 0,
      calls: 0,
      whatsapp: 0,
      isLoading: true,
      properties_chart: [],
      clickpermonth: {},
      allmonths: {},
      whatsapppermonth: {},
      callpermonth: {},
      isRejected:false,
      rejectedCount:""
      
    };
  }
  componentDidMount() {
  

    this.fetchData();
  }
  fetchData() {
    this.setState({ isLoading: true });
    const { lang } = this.props;
    API.post("/dashboard")
      .then(async (response) => {
        let i;

        await response.data;
        this.setState({isRejected:response.data.isRejected,rejectedCount:response.data.rejectedCount})
        let data =
          lang == "en"
            ? response.data.properties_chart_en
            : response.data.properties_chart_ar;
        this.setState({ properties_chart: [] });
        for (i = 0; i < data.length; i++) {
          var loopObject = {};
          loopObject["name"] = data[i].name;
          loopObject["for_rent"] =
            data[i].for_rent.length > 0
              ? data[i].for_rent[0].property_count
              : 0;
          loopObject["for_sale"] =
            data[i].for_sale.length > 0
              ? data[i].for_sale[0].property_count
              : 0;

          loopData.push(loopObject);
        }
        this.setState({ properties_chart: loopData });
        loopData = [];
        this.setState({
          agency: response.data.agency,
          agents: response.data.agents,
          basic: response.data.basic,
          featured: response.data.featured,
          premium: response.data.premium,
          rents: response.data.rents,
          sales: response.data.sales,
          properties: response.data.properties,
          viewed: response.data.viewed,
          whatsapp: response.data.whatsapp,
          calls: response.data.calls,
          isLoading: false,
          chartproperties:
            lang == "en"
              ? response.data.properties_en
              : response.data.properties_ar,
          allmonths: response.data.allmonths,
          whatsapppermonth: response.data.whatsapppermonth,
          callpermonth: response.data.callpermonth,
          clickpermonth: response.data.clickpermonth,
        });
      })
      .catch((error) => this.setState({ isLoading: false }));
  }
  render() {
    const { lang } = this.props;

    const Stackedoption = {
      tooltip: {
        trigger: "axis",
      },
      legend: {
        data: ["Clicks", "Calls", "Whatsapp"],
        textStyle: {
          //color: isDark ? "var(--txt-color)" : "black",
        },
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      xAxis: {
        type: "category",
        boundaryGap: false,
        data: this.state.allmonths,
      },
      yAxis: {
        type: "value",
      },
      series: [
        {
          name: "Clicks",
          type: "line",
          //stack: "Total",
          data: this.state.clickpermonth,
        },
        {
          name: "Calls",
          type: "line",
         // stack: "Total",
          data: this.state.callpermonth,
        },
        {
          name: "Whatsapp",
          type: "line",
          stack: "Total",
          data: this.state.whatsapppermonth,
        },
      ],
    };

    return (
      <div>
        <div className="container-fluid" style={{ backgroundColor: "#edf2f6" }}>
        {this.state.isRejected?
        <Link  style={{ fontSize: "16px", fontWeight: "bold", color: "#ff4d4f" }} to="/rejected">
                    <Alert
                    message={lang =="en"? "Rejected Listings":"عقارات مرفوضة"}
                    description={
              
                      <div style={{ fontSize: "16px", fontWeight: "bold", color: "#ff4d4f" }}>
                      {this.state.rejectedCount === 1 ? (
                        <>
                         {lang =="en"? "There is":"هنالك"}{" "}
                          <Link
                            to="/rejected"
                            style={{ fontSize: "16px", fontWeight: "bold", color: "#ff4d4f" }}
                          >
                           (  {this.state.rejectedCount}  )
                          </Link>{" "}
                          {lang =="en"? "rejected item. Click  to view it":"عقار مرفوض اضغط للعرض"} .
                        </>
                      ) : (
                        <>
                         {lang =="en"? "There are":"هنالك"} {" "}
                          <Link
                            to="/rejected"
                            style={{ fontSize: "16px", fontWeight: "bold", color: "#ff4d4f" }}
                          >
                           (  {this.state.rejectedCount}   )
                          </Link>{" "}
                          {lang =="en"? "rejected items. Click  to view it":"عقار مرفوض اضغط للعرض"}
                         
                        </>
                      )}
                    </div>
                    }
                    type="warning"
                    showIcon
                    closable
                  /></Link>:null}
          <Breadcrumb
            style={{
              margin: "16px 0",
            }}
          >
        
            <Breadcrumb.Item>
              {lang == "en" ? "Dashboard" : "لوحة البيانات"}
            </Breadcrumb.Item>
          </Breadcrumb>

  

          <div className="row mb-4">
            <div className="">
              <div className="col-md-12">
                <div className="">
                  <div className="">
                    {this.state.isLoading ? (
                      <div
                        style={{
                          justifyContent: "center",
                          display: "flex",
                          alignItems: "center",
                          height: "500px",
                          verticalAlign: "middle",
                        }}
                      >
                        <Spin size="large" />
                      </div>
                    ) : (
                      <div>
                        <div className="row">
                          <div className="col-xl-4 col-sm-6">
                            <div className="card align-items-center material-green">
                              <div className="card-body d-flex align-items-center justify-content-between">
                                <div className="card-data me-2 text-center">
                                  <h5>
                                    {lang == "en"
                                      ? "Properties for Sale"
                                      : "عقارات للبيع"}
                                  </h5>
                                  <h2 className="fs-40 font-w600">
                                    {this.state.sales}
                                  </h2>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-4 col-sm-6">
                            <div className="card align-items-center material-blue">
                              <div className="card-body d-flex align-items-center justify-content-between">
                                <div className="card-data me-2 text-center">
                                  <h5>
                                    {lang == "en"
                                      ? "Properties for Rent"
                                      : "عقارات للايجار"}
                                  </h5>
                                  <h2 className="fs-40 font-w600">
                                    {this.state.rents}
                                  </h2>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-4 col-sm-6">
                            <div className="card align-items-center material-red">
                              <div className="card-body d-flex align-items-center justify-content-between ">
                                <div className="card-data text-center">
                                  <h5>
                                    {" "}
                                    {lang == "en"
                                      ? "Agents"
                                      : "الوسطاء / الموظفين"}
                                  </h5>
                                  <h2 className="fs-40 font-w600 text-center">
                                    {this.state.agents}
                                  </h2>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-xl-9 col-xxl-8">
                            <div className="row">
                              <div className="col-xl-8 col-xxl-12">
                                <div className="card">
                                  <div className="card-header d-block border-0">
                                    <div className="d-flex align-items-center justify-content-between mb-3">
                                      <h4 className="fs-20">
                                        Packagies{lang == ""}
                                      </h4>
                                    </div>
                                    <div className="row">
                                      <div className="table-responsive">
                                        <table className="table table-striped">
                                          <thead>
                                            <tr>
                                              <th scope="col"></th>
                                              <th scope="col">
                                                {lang == "en"
                                                  ? "Basic"
                                                  : "اساسي"}
                                              </th>
                                              <th scope="col">
                                                {lang == "en"
                                                  ? "Featured"
                                                  : "مميز"}
                                              </th>
                                              <th scope="col">
                                                {lang == "en"
                                                  ? "Premium"
                                                  : "ممتازة"}
                                              </th>
                                              <th scope="col">
                                                {lang == "en"
                                                  ? "Total"
                                                  : "المجموع"}
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <td>
                                                {lang == "en" ? "All" : "الكل"}
                                              </td>
                                              <td>{this.state.agency.basic}</td>
                                              <td>
                                                {this.state.agency.featured}
                                              </td>
                                              <td>
                                                {this.state.agency.premium}
                                              </td>
                                              <td>
                                                {this.state.agency.totalpackage}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                {lang == "en"
                                                  ? "Used"
                                                  : "المستخدم"}
                                              </td>
                                              <td>{this.state.basic}</td>
                                              <td>{this.state.featured}</td>
                                              <td>{this.state.premium}</td>
                                              <td>
                                                {this.state.basic +
                                                  this.state.featured +
                                                  this.state.premium}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                {lang == "en"
                                                  ? "Available"
                                                  : "المتوفر"}
                                              </td>
                                              <td>
                                                {this.state.agency.basic -
                                                  this.state.basic}
                                              </td>
                                              <td>
                                                {this.state.agency.featured -
                                                  this.state.featured}
                                              </td>
                                              <td>
                                                {this.state.agency.premium -
                                                  this.state.premium}
                                              </td>
                                              <td>
                                                {this.state.agency
                                                  .totalpackage -
                                                  (this.state.basic +
                                                    this.state.featured +
                                                    this.state.premium)}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="card-body py-0 px-sm-3 px-2">
                                    <div
                                      id="marketChart"
                                      className="market-line"
                                    ></div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xl-4 col-xxl-12">
                                <div className="card">
                                  <div className="card-header border-0">
                                    <h4 className="fs-20"></h4>
                                  </div>
                                  <div className="card-body pt-0 text-center">
                                    {this.state.properties.length > 0 ? (
                                      <PieChart width={300} height={300}>
                                        <Tooltip />
                                        <Legend />
                                        <Pie
                                          data={this.state.properties}
                                          cx={150}
                                          cy={150}
                                          labelLine={false}
                                          label={renderCustomizedLabel}
                                          outerRadius={80}
                                          fill="#8884d8"
                                          dataKey="value"
                                        >
                                          {this.state.properties.map(
                                            (entry, index) => (
                                              <Cell
                                                key={index}
                                                fill={
                                                  COLORS[index % COLORS.length]
                                                }
                                              />
                                            )
                                          )}
                                        </Pie>
                                      </PieChart>
                                    ) : (
                                      <>
                                        {lang == "en"
                                          ? "No data"
                                          : "لاتوجد بيانات"}
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-3 col-xxl-4">
                            <div className="row">
                              <div className="col-xl-12">
                                <div className="card">
                                  <div className="card-body pb-3">
                                    <p className=" d-flex  ">
                                      {lang == "en"
                                        ? "Properties Clicks"
                                        : "مشاهدة العقارات"}
                                      &nbsp;&nbsp;
                                      <span className=" ">
                                        {this.state.viewed}
                                      </span>
                                    </p>
                                    <div
                                      className="progress "
                                      style={{ height: "15px" }}
                                    >
                                      <div
                                        className="progress-bar bg-primary progress-animated"
                                        style={{
                                          width: this.state.viewed + "%",
                                      
                                        }}
                                        role="progressbar"
                                      >
                                        <span className="sr-only"></span>
                                      </div>
                                    </div>
                                    <div className="" />
                                    <p className=" d-flex ">
                                      {lang == "en"
                                        ? "Call Leads"
                                        : "حالات الاتصال"}
                                      &nbsp;&nbsp;
                                      <span className="">
                                        {this.state.calls}
                                      </span>
                                    </p>
                                    <div
                                      className="progress "
                                      style={{ height: "15px" }}
                                    >
                                      <div
                                        className="progress-bar bg-primary progress-animated"
                                        style={{
                                          width: this.state.calls + "%",
                                          
                                        }}
                                        role="progressbar"
                                      >
                                        <span className="sr-only"></span>
                                      </div>
                                    </div>
                                    <div className="" />
                                    <p className=" d-flex  ">
                                      {lang == "en"
                                        ? "Whatsapp Leads"
                                        : " رسائل واتساب"}
                                      &nbsp;&nbsp;
                                      <span className="">
                                        {this.state.whatsapp}
                                      </span>
                                    </p>
                                    <div
                                      className="progress "
                                      style={{ height: "15px" }}
                                    >
                                      <div
                                        className="progress-bar bg-primary progress-animated"
                                        style={{
                                          width: this.state.whatsapp + "%",
                                          
                                        }}
                                        role="progressbar"
                                      >
                                        <span className="sr-only"></span>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="card-body pb-3">
                                    <p className=" ">
                                      &nbsp;&nbsp;
                                      <span className=""></span>
                                    </p>
                                    <div
                                      className=" "
                                      
                                    >
                                      <div className="progress-bar bg-primary progress-animated">
                                        <span className="sr-only"></span>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="card-body pb-3">
                                    <p className=" ">
                                      &nbsp;&nbsp;
                                      <span className="ms-auto"></span>
                                    </p>
                                    <div
                                      className=" "
                                      
                                    >
                                      <div className="progress-bar bg-primary progress-animated">
                                        <span className="sr-only"></span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                   
                     
                          <div className="row">
                            <div className="col-md-6">
                              <div className="card" id="responsive-map">
                                <div className="card-header border-0">
                                  <h4 className="fs-20">
                                    {" "}
                                    {lang == "en"
                                      ? "Propeties status "
                                      : "  حالات العقارات"}
                                  </h4>
                                </div>
                                <div className="card-body">
                                  {this.state.properties_chart.length > 0 ? (
                                    <BarChart
                                      className="barChart"
                                      width={300}
                                      height={400}
                                      data={this.state.properties_chart}
                                      margin={{
                                        top: 5,
                                        right: 30,
                                        left: 20,
                                        bottom: 5,
                                      }}
                                    >
                                      <CartesianGrid strokeDasharray="5 5" />
                                      <XAxis dataKey="name" />
                                      <YAxis /> <Tooltip />
                                      <Legend />{" "}
                                      <Bar
                                        dataKey="for_rent"
                                        barSize={25}
                                        fill="#8884d8"
                                      />
                                      <Bar
                                        dataKey="for_sale"
                                        barSize={25}
                                        fill="#82ca9d"
                                      />
                                    </BarChart>
                                  ) : (
                                    <>
                                      {lang == "en"
                                        ? "No Data to display"
                                        : "لاتوجد بيانات للعرض"}
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                            <div className="card" id="responsive-map">
                            <div className="card-header border-0">
                                  <h4 className="fs-20">
                              
                                    {lang == "en"
                                      ? "Leads"
                                      : "العملاء المحتملون"}
                                  </h4>
                                </div>
                                <div className="card-body">
                            <ReactECharts option={Stackedoption} />
                            </div>
                            </div>
                          </div>
                          </div>
                    
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  lang: state.auth.lang, 
});
export default connect(mapStateToProps)(AgencyView);
