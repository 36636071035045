import React, { useState,useEffect } from 'react';
import API from '../../../../utils/API';
import {MultiSelect} from "react-multi-select-component";

const UpdateServiceProvider = ({xCLoseModal,updateData}) => {
    const [formData, setFormData] = useState({
        name_en: '',
        name_ar: '',
        email: '',
        mobile: '',
        mobile2: '',
        emirate: '',
        service_type:[],
        website_url: '',
        logo: null, // For base64 file
    });

    const options = [
        { label: "Cleaning", value: "Cleaning" },
        { label: "Movers", value: "Movers" },
        { label: "Maintenance", value: "Maintenance" },
        { label: "Painting", value: "Painting" },
        { label: "Pest Control", value: "Pest Control" },
        { label: "Interior Design", value: "Interior Design" },
        { label: "Appliance Repair", value: "Appliance Repair" },
        { label: "Security Services", value: "Security Services" },
        { label: "Others", value: "Others" },
      ];

    useEffect(() => {
        if (updateData) {
            setFormData((prevData) => ({
                ...prevData,
                name_en: updateData.name_en || '',
                name_ar: updateData.name_ar || '',
                email: updateData.email || '',
                mobile: updateData.mobile || '',
                mobile2: updateData.mobile2 || '',
                emirate: updateData.emirate || '',
                service_type: updateData.service_types || '',
                website_url: updateData.website_url || '',
            }));
        }
    }, [updateData]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setFormData({ ...formData, logo: file })
            // const reader = new FileReader();
            // reader.onloadend = () => {
            //     setFormData({ ...formData, logo: reader.result }); // Base64 encoded string
            // };
            // reader.readAsDataURL(file); // Convert file to base64
        }
    };

    const handleServiceType = (e) => {
        console.log("eeeee",e);
        
        setFormData({ ...formData, service_type: e })
      };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const submitData = new FormData();

        // Append each field to FormData
        submitData.append('id', updateData.id);
        submitData.append('name_en', formData.name_en);
        submitData.append('name_ar', formData.name_ar);
        submitData.append('email', formData.email);
        submitData.append('mobile', formData.mobile);
        submitData.append('mobile2', formData.mobile2);
        submitData.append('emirate', formData.emirate);
        formData.service_type.forEach((service) => {
        
            
            submitData.append('service_type[]', service.value); // Notice the [] for array data
          });
        submitData.append('website_url', formData.website_url);

        // Append logo (base64 string)
        if (formData.logo) {
            submitData.append('image', formData.logo);
        }

        // Submit the FormData
        try {
            const response = await API.post('/update_service_providers',submitData);
           
            
           
            if(response.data.success){
                xCLoseModal()
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <div className="container mt-1">
           
            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label htmlFor="name_en" className="form-label">Name (English):</label>
                    <input
                        type="text"
                        className="form-control"
                        id="name_en"
                        name="name_en"
                        value={formData.name_en}
                        onChange={handleChange}
                        placeholder="Enter name in English"
                    />
                </div>

                <div className="mb-3">
                    <label htmlFor="name_ar" className="form-label">Name (Arabic):</label>
                    <input
                        type="text"
                        className="form-control"
                        id="name_ar"
                        name="name_ar"
                        value={formData.name_ar}
                        onChange={handleChange}
                        placeholder="Enter name in Arabic"
                    />
                </div>

                <div className="mb-3">
                    <label htmlFor="email" className="form-label">Email:</label>
                    <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder="Enter email"
                    />
                </div>

                <div className="mb-3">
                    <label htmlFor="mobile" className="form-label">Mobile:</label>
                    <input
                        type="text"
                        className="form-control"
                        id="mobile"
                        name="mobile"
                        value={formData.mobile}
                        onChange={handleChange}
                        placeholder="Enter mobile number"
                    />
                </div>

                <div className="mb-3">
                    <label htmlFor="location" className="form-label">Mobile 2:</label>
                    <input
                        type="text"
                        className="form-control"
                        id="mobile2"
                        name="mobile2"
                        value={formData.mobile2}
                        onChange={handleChange}
                        placeholder="Enter mobile"
                    />
                </div>
                <div className="mb-3">
    <label htmlFor="emirate" className="form-label">Emirate:</label>
    <select
        className="form-control"
        id="emirate"
        name="emirate"
        value={formData.emirate}
        onChange={handleChange}
    >
        <option value="">Select Emirate</option>
        <option value="Dubai">Dubai</option>
        <option value="Abu Dhabi">Abu Dhabi</option>
        <option value="Sharjah">Sharjah</option>
        <option value="Ajman">Ajman</option>
        <option value="Ras Al Khaimah">Ras Al Khaimah</option>
        <option value="Umm Al Quwain">Umm Al Quwain</option>
        <option value="Fujairah">Fujairah</option>
    </select>
</div>

<div className="mb-3">
    <label htmlFor="service_type" className="form-label">Service Type:</label>
    <MultiSelect
                              options={options}
                              value={formData.service_type}
                              onChange={handleServiceType}
                              labelledBy={"Select"}
                            />
</div>



                <div className="mb-3">
                    <label htmlFor="url" className="form-label">Website URL:</label>
                    <input
                        type="text"
                        className="form-control"
                        id="website_url"
                        name="website_url"
                        value={formData.website_url}
                        onChange={handleChange}
                        placeholder="Enter Website URL"
                    />
                </div>

                <div className="mb-3">
                    <label htmlFor="logo" className="form-label">Logo:</label>
                    <input
                        type="file"
                        className="form-control"
                        id="logo"
                        accept="image/*"
                        onChange={handleFileChange}
                    />
                </div>

                <button type="submit" className="btn btn-primary">Submit</button>
            </form>
        </div>
    );
};

export default UpdateServiceProvider;
