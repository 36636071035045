import React, { Component } from "react";

//import swal from 'sweetalert';
import Swal from "sweetalert2";

import { Link } from "react-router-dom";
import "antd/dist/antd.css";
import { Table, Avatar } from "antd";
import { itemRender, onShowSizeChange } from "../../../paginationfunction";
import "../../../antdstyle.css";
//import toast, { Toaster } from 'react-hot-toast';
import i18next from "i18next";
import Moment from "react-moment";
import { ToastContainer, toast } from "react-toastify";
import { Grid } from "react-loader-spinner";

import { baseurlImg } from "../../../../Components/BaseUrl";
import API from "../../../../utils/API";
import { connect } from "react-redux";

class AgencyAgents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      agencies: [],
      isLoading: false,
    };
  }

  componentDidMount() {
    const page = 1;
    const perPage = 10;
    this.fetchData(page, perPage);
  }

  fetchData = (page, perPage) => {
    this.setState({ isLoading: true });

    API.get(`/agency-agents?page=${page}&perPage=${perPage}`)
      .then(async (response) => {
        await response.data.agencies;
        this.setState({ isLoading: false, agencies: response.data.agencies });
      })
      .catch((e) => console.log("error"));
  };

  handelDelete = (agent, e) => {
    e.preventDefault();
    if (!agent || agent < 1) {
      return;
    } else {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          API.delete("/agents/" + agent)
            .then((response) => {
              Swal.fire("Deleted!", "Your file has been deleted.", "success");
              this.fetchData();
              //this.setState({categories:response.data.categories})
            })
            .catch((err) => console.log(err));
        }
      });
    }
  };

  render() {
    const {lang} = this.props

    const columns = [
      {
        title: lang == "en" ? "Company Name" : "اسم الشركة",
        render: (text, record) =>
          lang == "en" ? record.company_name_en : record.company_name_ar,
      },
      {
        title: lang == "en" ? "Logo" : "الشعار",
        render: (text, record) => (
          <img
            style={{ height: "70px", width: "70px" }}
            src={baseurlImg + "/uploads/profiles/" + record.logo}
          />
        ),
      },
      {
        title: lang == "en" ? "Agent Name" : "اسم الوسيط",
        render: (text, record) =>
          lang == "en" ? record.name_en : record.name_ar,
      },
      {
        title: lang == "en" ? "Mobile" : "الجوال",
        dataIndex: "mobile",
        sorter: (a, b) => a.mobile.length - b.mobile.length,
      },
      {
        title: lang == "en" ? " Email" : "البريد الالكتروني",
        dataIndex: "email",
        sorter: (a, b) => a.email.length - b.email.length,
      },
      {
        title: lang == "en" ? "Profile " : "الملف",
        render: (text, record) => (
          <>
            {record.profile != "" ||
            record.profile != null ||
            !record.profile ? (
              <Avatar
                size="large"
                style={{ backgroundColor: "#87d068", marginRight: "1rem" }}
                icon={
                  <img
                    style={{ height: "35px", width: "35px" }}
                    src={
                      baseurlImg + "/uploads/profiles/" + record.profile
                    }
                  />
                }
              />
            ) : (
              <Avatar
                size="large"
                style={{ backgroundColor: "#87d068", marginRight: "1rem" }}
                icon={
                  <img
                    style={{ height: "35px", width: "35px" }}
                    src={baseurlImg + "/uploads/profiles/no_avatar.png"}
                  />
                }
              />
            )}
          </>
        ),
      },

      {
        title: lang == "en" ? "Actions" : "العمليات",
        render: (text, record) => (
          <div className="ms-auto">
            <Link
              className="btn btn-sm btn-primary shadow m-2"
              to={"admin-edit-agent/" + record.id}
            >
              <i className="fa fa-edit"></i>
            </Link>
            <a
              className="btn btn-danger btn-sm shadow  m-2"
              onClick={this.handelDelete.bind(this, record.id)}
            >
              <i className="fa fa-trash"></i>
            </a>
          </div>
        ),
      },
    ];

    const handleTableChange = (pagination) => {
      this.fetchData(pagination.current, pagination.pageSize);
    };

    return (
      <div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">
                    {lang == "en" ? "Companies / Agents" : "الشركة / الوسيط"}
                  </h4>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
             
                      <Table
                        className="table-striped"
                        loading={this.state.isLoading}
                        onChange={handleTableChange}
                        pagination={{
                          pageSizeOptions: ["10", "20", "50", "100"],
                          total:
                            this.state?.agencies?.total > 0 &&
                            this.state.agencies.total,
                          pageSize: this.state.agencies.per_page,
                          current: this.state.agencies.current_page,
                          showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                          showSizeChanger: true,
                          onShowSizeChange: onShowSizeChange,
                          itemRender: itemRender,
                        }}
                        style={{ overflowX: "auto", textAlign: "right" }}
                        columns={columns}
                    
                        dataSource={
                          this.state?.agencies?.data
                            ? this.state?.agencies?.data
                            : []
                        }
                        rowKey={(record) => record}
                      />
                 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  lang: state.auth.lang, 
});
export default connect(mapStateToProps)(AgencyAgents);

