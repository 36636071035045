import React, { Component } from "react";

import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import { Link } from "react-router-dom";
import "antd/dist/antd.css";
import { Table,DatePicker,Select } from "antd";
import { itemRender, onShowSizeChange } from "../../../paginationfunction";
import moment from "moment";

import "../../../antdstyle.css";
import { Grid } from "react-loader-spinner";
import './Leads.css'
import API from "../../../../utils/API";
import { connect } from "react-redux";

const { RangePicker } = DatePicker;

function RelativeTime({ date }) {
  if (!date) {
    return <span></span>; // Return empty if date is null or undefined
  }
  return <span>{moment(date).fromNow()}</span>;
}
class Leads extends Component {
  constructor(props) {
    super(props);
    this.state = {
      leads: [],
      companies:[],
      company:"",
      dateRange:null
    };
  }

  componentDidMount() {
    const page = 1;
    const perPage = 10;
    const company = this.state.company
    const dateRange = this.state.dateRange
    this.fetchData(page, perPage,company,dateRange);

    API.get('/listagency')
    .then(async (response) => {
      await response.data.leads;

      this.setState({ companies: response.data.agencies });
    })
    .catch((e) => console.log("error"));
  }

  fetchData = (page, perPage,company,dateRange) => {
    this.setState({ isLoading: true });
    API.get(`/leads?page=${page}&perPage=${perPage}&company=${company}&date=${dateRange}`)
      .then(async (response) => {
        await response.data.leads;

        this.setState({ isLoading: false, leads: response.data.leads });
      })
      .catch((e) => console.log("error"));
  };

  render() {
    const current = new Date();
    const date = `${current.getDate()}/${
      current.getMonth() + 1
    }/${current.getFullYear()}`;
    const {lang} = this.props
    const columns = [
      {
        title: lang == "en" ? "Company Name" : "اسم الشركة",
        render: (text, record) => (
          <>{lang == "en" ? record.name_en : record.name_ar}</>
        ),
      },

      {
        title: "Clicks",
        render: (text, record) => (
          <>
            {record.viewed > 0 ? (
              <Link to={"/clicks/" + record.id}>
                <span class="badge bg-primary">
                  {record.viewed}
                </span>
              </Link>
            ) : (
              " - "
            )}
          </>
        ),
      },
      {
        title: "Last click",
        render: (text, record) => (
          <RelativeTime date={record.last_viewed} />
          // <span >{record.last_viewing}</span>
        ),
      },
      {
        title: "Calls",
        render: (text, record) => (
          <>
            {record.call > 0 ? (
              <Link to={"/calls/" + record.id}>
                <span class="badge bg-danger text-black">
                  {record.call}
                </span>
              </Link>
            ) : (
              " - "
            )}
          </>
        ),
      },
      {
        title: "Last call",
        render: (text, record) => (
          <RelativeTime date={record.last_call} />
          // <span >{record.last_call}</span>
        ),
      },
      {
        title: "Whatsapp",
        render: (text, record) => (
          <>
            {record.whatsapp > 0 ? (
              <Link to={"/whatsapp/" + record.id}>
                <span class="badge bg-success text-black">
                  {record.whatsapp}
                </span>
              </Link>
            ) : (
              " - "
            )}
          </>
        ),
      },
      {
        title: "Last message",
        render: (text, record) => (
          <RelativeTime date={record.last_whatsapp} />
          // <span >{record.last_whatsapp}</span>
        ),
      },
    ];
    const handleTableChange = (pagination) => {
  
      const company = this.state.company
      const dateRange = this.state.dateRange
      this.fetchData(pagination.current, pagination.pageSize,company,dateRange);
    };

    const handleCompany = (value) =>{
     this.setState({company:value?value:""})
     const page = 1;
     const perPage = 10;
     const company = value?value:""
     const dateRange = this.state.dateRange
     this.fetchData(page, perPage,company,dateRange);
    }

    const handleRangeDate = (date, dateString) => {
      this.setState({dateRange:date?dateString:""})
      const page = 1;
      const perPage = 10;
      const company = this.state.company
      const dateRange = date?dateString:""
      this.fetchData(page, perPage,company,dateRange);

      

    }

    return (
      <div>
        <div className="container-fluid">
        
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">Leads</h4>
                  </div>
       
                  <div className="card-body">
                  <div style={{display:'flex',flexDirection:'row',marginBottom:'10px',justifyContent:'space-between'}}>
                    <div>
                      <label style={{padding:'10px'}}>Company</label>
                      <Select
                       className="custom-select"
                       dropdownStyle={{
                        maxHeight: '200px', // Ensures sufficient height for dropdown
                        overflow: 'auto',   // Adds scrolling for large lists
                      }}
                        showSearch
                        allowClear
                        placeholder=""
                        optionFilterProp="label"
                        onChange={handleCompany}
                        // onSearch={onSearch}
                        style={{
                          width: 400 // Increase height
                        }}
                 
                       options={this.state.companies.map(option => ({
                      value: option.id,  // Use 'id' as value
                      label: option.name_en, // Use 'name' as label
                    }))}
                                        />
                    </div>
                  
                    <div>
                    <label style={{padding:'10px'}}>Date </label>
                    <RangePicker
                    onChange={handleRangeDate}
                      className="custom-range-picker"
                      dropdownClassName="custom-dropdown"
                    />

                    </div>
                  </div>
                    <div className="table-responsive">
                     
                        <Table
                          className="table-striped"
                          loading={this.state.isLoading}
                          onChange={handleTableChange}
                          pagination={{
                            pageSizeOptions: ["10", "20", "50", "100"],
                            total:
                              this.state?.leads?.total > 0 &&
                              this.state.leads.total,
                            pageSize: this.state.leads.per_page,
                            current: this.state.leads.current_page,
                            showTotal: (total, range) =>
                              `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                            showSizeChanger: true,
                            onShowSizeChange: onShowSizeChange,
                            itemRender: itemRender,
                          }}
                          style={{ overflowX: "auto", textAlign: "right" }}
                          columns={columns}
                          dataSource={
                            this.state?.leads?.data
                              ? this.state?.leads?.data
                              : []
                          }
                          rowKey={(record) => record}
                        />
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  lang: state.auth.lang, 
});
export default connect(mapStateToProps)(Leads);
