import React, { Component } from "react";

import cookie from "js-cookie";

import API from "../utils/API";

import { ToastContainer, toast } from "react-toastify";
import { connect } from "react-redux";

import "react-toastify/dist/ReactToastify.css";

class Changepassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    };
  }

  handleOldpassword = (e) => {
    e.preventDefault();
    this.setState({ oldPassword: e.target.value });
  };
  handleNewpassword = (e) => {
    e.preventDefault();
    this.setState({ newPassword: e.target.value });
  };
  handleConfirmpassword = (e) => {
    e.preventDefault();
    this.setState({ confirmPassword: e.target.value });
  };
  handleSubmit = (e) => {
    const data = {
      oldPassword: this.state.oldPassword,
      newPassword: this.state.newPassword,
      confirmPassword: this.state.confirmPassword,
    };
    API
      .post("/changePassword", data)
      .then((response) => {
        if (response.data.success) {
          toast.success(response.data.msg);
          this.setState({
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
          });
        } else {
          toast.error(response.data.msg);
          this.setState({
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
        toast.error("Something wrong.", error);
      });
  };
  handleLogout = () => {
    //   e.preventDefault();

    cookie.remove("token");
    cookie.remove("role");
    cookie.remove("name");
    cookie.remove("user_id");
    cookie.remove("name_ar");
    cookie.remove("profile");
    cookie.remove("email");
    this.props.logout();
  };
  render() {
    const {lang} = this.props
    return (
      <div className="container-fluid">
        <ToastContainer />
        <div className="card">
          <div className="card-header">
            <h1>{lang == "en" ? "Change Password" : "تغيير كلمة السر"}</h1>
          </div>
          <div className="card-body">
            <div className="mb-3">
              <label className="form-label">
                {" "}
                {lang == "en" ? "Old Password" : "كلمة السر القديمة"}
              </label>
              <input
                type="password"
                class="form-control"
                value={this.state.oldPassword}
                onChange={this.handleOldpassword}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">
                {lang == "en" ? "New Password" : "كلمة السر الجديدة"}
              </label>
              <input
                type="password"
                className="form-control"
                value={this.state.newPassword}
                onChange={this.handleNewpassword}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">
                {lang == "en" ? "Confirm Password" : "تأكيد كلمة السر"}
              </label>
              <input
                type="password"
                className="form-control"
                value={this.state.confirmPassword}
                onChange={this.handleConfirmpassword}
              />
            </div>
            <div className="mb-3">
              <button
                type="button"
                className="btn btn-outline-primary"
                onClick={this.handleSubmit}
              >
                {lang == "en" ? "Save Changes" : "حفظ التغيرات"}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// const mapDispatchToProps = dispatch =>{
//     return {
//         logout: () => dispatch({type: "SET_LOGOUT"})
//     }
//   }
const mapStateToProps = (state) => ({
  lang: state.auth.lang, 
});
export default connect(mapStateToProps)(Changepassword);
// export default connect(null,mapDispatchToProps)(Changepassword)
