import React, { Component } from 'react'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, PieChart, Pie, Cell, Tooltip, Legend } from "recharts";
import {Breadcrumb,Alert} from 'antd'
import { ArrowRightOutlined,ArrowLeftOutlined} from '@ant-design/icons';
import API from '../../utils/API';
import {Spin } from 'antd'
import ReactECharts from "echarts-for-react";
import { connect } from "react-redux";
import {Link} from 'react-router-dom'
let loopData = []
const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042","#8A2BE2","#A52A2A","#D2691E","#2F4F4F"];
const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const width= window.screen.width
class AgentView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      properties: [],
      properties_chart:[],
      rents: 0,
      sales: 0,
      isLoading: true,
      clickpermonth: {},
      allmonths: {},
      whatsapppermonth: {},
      callpermonth: {},
         isRejected:false,
      rejectedCount:""
    }
  }
  componentDidMount() {
    const { lang } = this.props;
  
    this.setState({ isLoading: true })

    API.post("/dashboard")
      .then(response => {
        this.setState({
          rents: response.data.rents,
          sales: response.data.sales, isLoading: false,
          viewed:response.data.viewed,
          whatsapp:response.data.whatsapp,calls:response.data.calls,
          properties:response.data.properties,
          allmonths: response.data.allmonths,
          whatsapppermonth: response.data.whatsapppermonth,
          callpermonth: response.data.callpermonth,
          clickpermonth: response.data.clickpermonth,
          isRejected:response.data.isRejected,
          rejectedCount:response.data.rejectedCount
        })
        
        let i ;
          let data = lang=="en"?response.data.properties_en:response.data.properties_ar
          for(i=0; i < data.length; i++){
           var loopObject = {}
               loopObject["name"] = data[i].name
               loopObject["for_rent"] = data[i].for_rent.length> 0? data[i].for_rent[0].property_count :0
               loopObject["for_sale"] = data[i].for_sale.length> 0? data[i].for_sale[0].property_count :0
               loopData.push(loopObject)
                 }
               this.setState({properties_chart:loopData})
               loopData = []

      })
      .catch(error => this.setState({ isLoading: false }))
  }
  render() {
    const { lang } = this.props;
    const Stackedoption = {
      tooltip: {
        trigger: "axis",
      },
      legend: {
        data: ["Clicks", "Calls", "Whatsapp"],
    
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      xAxis: {
        type: "category",
        boundaryGap: false,
        data: this.state.allmonths,
      },
      yAxis: {
        type: "value",
      },
      series: [
        {
          name: "Clicks",
          type: "line",
         // stack: "Total",
          data: this.state.clickpermonth,
        },
        {
          name: "Calls",
          type: "line",
        //  stack: "Total",
          data: this.state.callpermonth,
        },
        {
          name: "Whatsapp",
          type: "line",
         // stack: "Total",
          data: this.state.whatsapppermonth,
        },
      ],
    };

    return (

      <div>
      <div className="container-fluid" style={{backgroundColor:'#edf2f6'}} >
      {this.state.isRejected?
        <Link  style={{ fontSize: "16px", fontWeight: "bold", color: "#ff4d4f" }} to="/rejected">
                    <Alert
                    message={lang =="en"? "Rejected Listings":"عقارات مرفوضة"}
                    description={
              
                      <div style={{ fontSize: "16px", fontWeight: "bold", color: "#ff4d4f" }}>
                      {this.state.rejectedCount === 1 ? (
                        <>
                         {lang =="en"? "There is":"هنالك"}{" "}
                          <Link
                            to="/rejected"
                            style={{ fontSize: "16px", fontWeight: "bold", color: "#ff4d4f" }}
                          >
                           (  {this.state.rejectedCount}  )
                          </Link>{" "}
                          {lang =="en"? "rejected item. Click  to view it":"عقار مرفوض اضغط للعرض"} .
                        </>
                      ) : (
                        <>
                         {lang =="en"? "There are":"هنالك"} {" "}
                          <Link
                            to="/rejected"
                            style={{ fontSize: "16px", fontWeight: "bold", color: "#ff4d4f" }}
                          >
                           (  {this.state.rejectedCount}   )
                          </Link>{" "}
                          {lang =="en"? "rejected items. Click  to view it":"عقار مرفوض اضغط للعرض"}
                         
                        </>
                      )}
                    </div>
                    }
                    type="warning"
                    showIcon
                    closable
                  /></Link>:null}
      <Breadcrumb
          style={{
            margin: '16px 0',
          }}
        >
     
          <Breadcrumb.Item>{lang=="en"?"Dashboard":"لوحة البيانات"}</Breadcrumb.Item>
          
        </Breadcrumb>
        
          <div className="row mb-4">
              <div className="" >
                  <div className="col-md-12">
                      <div className="">
                          <div class="">
                          {this.state.isLoading? <div style={{justifyContent:'center',display:'flex',alignItems:'center',height:'500px',verticalAlign:'middle'}}> <Spin size="large" /></div>:
                       <div>
                 <div className="row">
            <div className="col-xl-6 col-sm-6">
              <div className="card align-items-center material-yellow">
                <div className="card-body d-flex align-items-center justify-content-between">
                  <div className="card-data me-2 text-center">
                    <h5>{lang == "en" ? "Properties for Sale" : "عقارات للبيع"}</h5>
                    <h2 className="fs-40 font-w600">{this.state.sales}</h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-sm-6">
              <div className="card align-items-center material-blue">
                <div className="card-body d-flex align-items-center justify-content-between">
                  <div className="card-data me-2 text-center">
                    <h5>{lang == "en" ? "Properties for Rent" : "عقارات للايجار"}</h5>
                    <h2 className="fs-40 font-w600">{this.state.rents}</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className='col-md-5'>
            <div className="card" id="responsive-map">
                    <div className="card-header border-0"></div>
                    <div className='card-body'>
                    {this.state.properties.length > 0 ?             <PieChart width={300} height={300}>
             <Tooltip />
             <Legend />
         <Pie
           data={this.state.properties}
           cx={150}
           cy={150}
           labelLine={false}
          label={renderCustomizedLabel}
           outerRadius={80}
           fill="#8884d8"
          dataKey="value"
         >
          {this.state.properties.map((entry, index) => (
            <Cell key={index}  fill={COLORS[index % COLORS.length]} />
          ))}
         </Pie>
       </PieChart>:<>
       {lang=="en"?"No data":"لاتوجد بيانات"}
       </>}
              </div>
              </div>
              </div>
              <div className='col-md-7'>
              <div className="card" id="responsive-map">
                    <div className="card-header border-0"></div>
                    <div className='card-body'>
                    <p class=" d-flex  fs-16 text-black font-w500">{lang=="en"?"Properties Clicks":"مشاهدة العقارات"}
                     &nbsp;&nbsp;
											<span class="ms-auto text-dark fs-14 font-w400">{this.state.viewed}</span>
 										</p>
										<div class="progress mb-4" style={{height:'18px'}}>
											<div class="progress-bar bg-primary progress-animated" style={{width:this.state.viewed  +'%', height:'18px'}} role="progressbar">
												<span class="sr-only"></span>
 											</div>
 										</div>
                     <div className='mt-1'/>
                     <p class=" d-flex  fs-16 text-black font-w500">{lang=="en"?"Call Leads":"حالات الاتصال"}
                     &nbsp;&nbsp;
											<span class="ms-auto text-dark fs-14 font-w400">{this.state.calls}</span>
 										</p>
										<div class="progress mb-4" style={{height:'18px'}}>
											<div class="progress-bar bg-primary progress-animated" style={{width:this.state.calls +'%', height:'18px'}} role="progressbar">
												<span class="sr-only"></span>
 											</div>
 										</div>
                     <div className='mt-1'/>
                     <p class=" d-flex  fs-16 text-black font-w500">{lang=="en"?"Whatsapp Leads":" رسائل واتساب"}
                     &nbsp;&nbsp;
											<span class="ms-auto text-dark fs-14 font-w400">{this.state.whatsapp}</span>
 										</p>
										<div class="progress mb-4" style={{height:'18px'}}>
											<div class="progress-bar bg-primary progress-animated" style={{width:this.state.whatsapp +'%', height:'18px'}} role="progressbar">
												<span class="sr-only"></span>
 											</div>
 										</div>
              </div>
              </div>
              </div>

          </div>

          <div className="row">
          
                <div className="col-md-6">
                  <div className="card" id="responsive-map">
                    <div className="card-header border-0">
                      <h4 className="fs-20"> {lang == "en" ? "Propeties chart" : " مخطط العقارات"}</h4>
                    </div>
                    <div className="card-body">   
                   
        <BarChart
          width={300}
          height={400}
          data={this.state.properties_chart}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="for_rent" barSize={25} fill="#8884d8" />
          <Bar dataKey="for_sale"  label="sssssss"  barSize={25} fill="#82ca9d" />
           </BarChart>
           
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                            <div className="card" id="responsive-map">
                            <div className="card-header border-0">
                                  <h4 className="fs-20">
                                    {" "}
                                    {lang == "en"
                                      ? "Leads"
                                      : "العملاء المحتملون"}
                                  </h4>
                                </div>
                                <div className="card-body">
                            <ReactECharts option={Stackedoption} />
                            </div>
                            </div>
                          </div>
              
          </div>


                       </div>
                                      }
           
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>

    )
  }
}

const mapStateToProps = (state) => ({
  lang: state.auth.lang, 
});
export default connect(mapStateToProps)(AgentView);

