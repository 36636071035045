import {
  LaptopOutlined,
  NotificationOutlined,
  UserOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  DashboardFilled,
  UnorderedListOutlined,
  UsergroupDeleteOutlined,
  DownOutlined,
  LogoutOutlined,
  KeyOutlined,FundOutlined,StockOutlined,
  PlusCircleOutlined,
  WechatOutlined,
  SettingOutlined
} from "@ant-design/icons";
import Pusher from 'pusher-js'

import i18next from "i18next";

import { Layout, Menu, Avatar, Dropdown, Space, Button, Drawer,Tabs, List,Carousel,Image,Skeleton  } from "antd";
import React, { useState, useEffect } from "react";
import AddProperty from "../../pages/Property/AddProperty";
import { store } from "../../store";
import Swal from "sweetalert2";

import { Link, useHistory, Redirect } from "react-router-dom";

import Categories from "../../pages/Admin/Settings/Categories/Categories";
import EditCategory from "../../pages/Admin/Settings/Categories/EditCategory";
import Dashboard from "../../Components/dashbaord/Dashboard";
import PrivateRoute from "../../Components/protectRoutes/PrivateRoute";
import AddNewUser from "../../pages/Agency/Usermanagement/AddNewUser";
import NewUser from "../../pages/Admin/Settings/Users/NewUser";
import Employees from "../../pages/Admin/Settings/Users/Employees";
import Agents from "../../pages/Agency/Usermanagement/Agents";
import EditEmployee from "../../pages/Admin/Settings/Users/EditEmployee";
import EditAgent from "../../pages/Agency/Usermanagement/EditAgent";
import Profile from "../../pages/auth/Profile";
import Managelisting from "../../pages/Agency/Listingmanagement/Managelisting";
import NewAgency from "../../pages/Admin/Settings/Agencies/NewAgency";
import ListAgencies from "../../pages/Admin/Settings/Agencies/ListAgencies";
import EditAgency from "../../pages/Admin/Settings/Agencies/EditAgency";
import ShowAgency from "../../pages/Admin/Settings/Agencies/ShowAgency";
import PropertyType from "../../pages/Admin/Settings/Type/PropertyType";
import EditPropertyType from "../../pages/Admin/Settings/Type/Edit";
import "./sidebar.css";
import "./Bye.css";

import EditProperty from "../../pages/Property/EditProperty";
import Listblogs from "../../pages/Blogs/Listblogs";
import Newblog from "../../pages/Blogs/Newblog";
import AgencyAgents from "../../pages/Admin/Settings/AgencyModule/AgencyAgents";
import AgencyProperties from "../../pages/Admin/Settings/AgencyModule/AgencyProperties";
import AgencyAgentsAndProperties from "../../pages/Admin/Settings/AgencyModule/AgencyAgentsAndProperties";
import ShowAgencyDetails from "../../pages/Admin/Settings/AgencyModule/ShowAgencyDetails";
import UsageList from "../../pages/Agency/Listingmanagement/UsageList";
import AgentManagelisting from "../../pages/Agent/Listing/AgentManagelisting";
import Editblog from "../../pages/Blogs/Editblog";
import Listpages from "../../pages/Blogs/Pages/Listpages";
import Newpage from "../../pages/Blogs/Pages/Newpage";
import Editpage from "../../pages/Blogs/Pages/Editpage";
import Changepassword from "../../Components/Changepassword";
import AgentProperty from "../../pages/Property/AgentProperty";
import EditAgentProperty from "../../pages/Property/EditAgentProperty";
import AdminManagelist from "../../pages/Admin/Settings/Listing/AdminManagelist";
import SeoManagelisting from "../../pages/Admin/Settings/Seo/SeoManagelisting";
import AdminEditList from "../../pages/Admin/Settings/Listing/AdminEditList";
import SeoEditList from "../../pages/Admin/Settings/Seo/SeoEditList";
import AdminEditAgent from "../../pages/Admin/Settings/AgencyModule/AdminEditAgent";
import Pendinglisting from "../../pages/Agency/Listingmanagement/Pendinglisting";
import Publishlisting from "../../pages/Agency/Listingmanagement/Publishlisting";
import Rejectedlisting from "../../pages/Agency/Listingmanagement/Rejectedlisting";
import EditRejectedProperty from "../../pages/Property/EditRejectedProperty";
import AgentRejectedlisting from "../../pages/Agent/Listing/AgentRejectedlisting";
import AgentPublishlisting from "../../pages/Agent/Listing/AgentPublishlisting";
import AgentPendinglisting from "../../pages/Agent/Listing/AgentPendinglisting";
import EditRejectedAgentProperty from "../../pages/Property/EditRejectedAgentProperty";
import EditPublishProperty from "../../pages/Property/EditPublishProperty";
import EditPublishAgent from "../../pages/Property/EditPublishAgent";
import Draftlisting from "../../pages/Agency/Listingmanagement/Draftlisting";
import AgentDraftlisting from "../../pages/Agent/Listing/AgentDraftlisting";
import AdminDraft from "../../pages/Admin/Settings/Listing/AdminDraft";
import Leads from "../../pages/Admin/Settings/AgencyModule/Leads";
import Adminpending from "../../pages/Admin/Settings/Listing/Adminpending";

import Whatsapp from "../../pages/Admin/Settings/AgencyModule/Whatsapp";
import Click from "../../pages/Admin/Settings/AgencyModule/Click";
import Call from "../../pages/Admin/Settings/AgencyModule/Call";
import { useDispatch, useSelector } from "react-redux";
import { baseurlImg, baseurlImg2 } from "../BaseUrl";
import AgencyLeads from "../../pages/Agency/Listingmanagement/AgencyLeads";
import AgentLeads from "../../pages/Agent/Listing/AgentLeads";
import { MenuOutlined } from "@material-ui/icons";
import UseViewport from "../viewport/viewPort";
import AdminRejected from "../../pages/Admin/Settings/Listing/AdminRejected";
import AgentPrivate from "../protectRoutes/AgentPrivate";
import AgencyPrivate from "../protectRoutes/AgencyPrivate";
import AdminPrivate from "../protectRoutes/AdminPrivate";
import SeoPrivate from "../protectRoutes/SeoPrivate";
import Bye from "./Bye";
import AgencyLeadsReport from "../../pages/Agency/Listingmanagement/AgencyLeadsReport";
import Realstates from "../../pages/Admin/Settings/realestatesguide/Realestates";
import API from "../../utils/API";
import ReplyInput from "./ReplyInput";
import MessageInput from "./MessageInput";
import 'antd/dist/antd.css';
import ServiceProviders from "../../pages/Admin/Settings/services/ServiceProviders";

const { Header, Content, Sider, Footer } = Layout;
const { TabPane } = Tabs;
const width = window.screen.width;
const arrowStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '30px',
  height: '30px',
  background: '#1890ff', // Use a visible background color
  color: '#fff', // Make the arrow symbol visible
  borderRadius: '50%', // Circular arrow buttons
  cursor: 'pointer',
  fontSize: '16px',
  boxShadow: '0px 4px 6px rgba(0,0,0,0.1)',
};






const items2 = [UserOutlined, LaptopOutlined, NotificationOutlined].map(
  (icon, index) => {
    const key = String(index + 1);
    return {
      key: `sub${key}`,
      icon: React.createElement(icon),
      label: `subnav ${key}`,
      children: new Array(4).fill(null).map((_, j) => {
        const subKey = index * 4 + j + 1;
        return {
          key: subKey,
          label: `option${subKey}`,
        };
      }),
    };
  }
);

const SidebarComponent = () => {
  const { width } = UseViewport();
  const dispatch = useDispatch();
  const history = useHistory();
  const [openKeys, setOpenKeys] = useState([]);
  const [showChat,setShowChat] =useState(false)
  const [showChatMessages,setShowChatMessages] =useState(false)
  const [selectedUser, setSelectedUser] = useState(null);
  const [users,setUsers] = useState([])
  const [chat,setChat] = useState('')
  const [loading,setLoading] =useState(false)
  const [allMessages,setAllMessages] = useState([])

  const [collapsed, setCollapsed] = useState(false);
  const [visible, setVisible] = useState(false);
  const [placement, setPlacement] = useState("top");
  const [going, setGoing] = useState(false);
  const userdata = store.getState((state) => state);
  let authenticated = userdata.auth.loggedIn;
  let role = userdata.auth.user.role;
 const user = useSelector(state => state.auth.user)
  const [unreadMessages, setUnreadMessages] = useState({});

  const [dubai,setDubai] = useState([])
  const [abudhabi,setAbudhabi] = useState([])
  const [sharjah,setSharjah] = useState([])
  const [ajman,setAjman] = useState([])
  const [rasalkhaima,setRasalkhaima] = useState([])
  const [umalqween,setUmalqween] = useState([])
  const [fujairah,setFujairah] = useState([])
  const [activeTab, setActiveTab] = useState('1');
  const [count,setCount] = useState(0)
  const [imageForChat,setImageForChat] = useState(null)

  const [openReplies, setOpenReplies] = useState({}); // Track open state of replies

 
  const [dubaiMessages,setDubaiMessages] = useState([])
  const [abudhabiMessages,setAbudhabiMessages] = useState([])
  const [sharjahMessages,setSharjahMessages] = useState([])
  const [ajmanMessages,setAjmanMessages] = useState([])
  const [umalqweenMessages,setUmalqweenMessages] = useState([])
  const [rasalkhaimaMessages,setRasalkhaimaMessages] = useState([])
  const [fujairahMessages,setFujairahMessages] = useState([])

  const [currentUser,setCurrentUser] =useState({});

  const [userMessages,setUserMessages] = useState([])
  
  const lang = useSelector(state => state.auth.lang)
const [isRejected,setIsRejected] =useState(true)
  
  const changeToArabic =(e) =>{
    e.preventDefault();
    dispatch({ type: "SET_LANG", payload: { lang: "ar" } });
    // const oldlang = localStorage.getItem("lang");
    // if (oldlang != "ar") {
    //   localStorage.setItem("lang", "ar");
    //   window.location.reload();
    // }
  }
  const changeToEnglish =(e)  =>{
    e.preventDefault();
    dispatch({ type: "SET_LANG", payload: { lang: "en" } });
    // const oldlang = localStorage.getItem("lang");
    // if (oldlang != "en") {
    //   localStorage.setItem("lang", "en");
    //   window.location.reload();
    // }
    
  }

  const menu1 = (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <a className="dropdown-item" onClick={changeToEnglish}>
              <span className="flag-icon flag-icon-gb"> </span>{" "}
              {width > 720 ? "English" : ""}
            </a>
          ),
        },
        {
          key: "2",
          label: (
            <a className="dropdown-item" onClick={changeToArabic}>
              <span className="flag-icon flag-icon-ae"> </span>{" "}
              {width > 720 ? "العربية" : ""}
            </a>
          ),
        },
      ]}
    />
  );
  
 

  const toggleReplies = (messageIndex) => {
    setOpenReplies((prev) => ({
      ...prev,
      [messageIndex]: !prev[messageIndex], // Toggle open/close state
    }));
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  }

  

  const handleChatCurrentUser = (sender) =>{
    setCurrentUser(sender)
    setUserMessages([])
    setLoading(true)
    const currentSelectedUserId = sender.id
      API.get(`/getUserMessages?currentSelectedUserId=${currentSelectedUserId}`)
  .then((res) =>{
   if(res.data.success){

    setLoading(false)
     setUserMessages(res.data.messages)
     // setUsers(res.data.users)
     // console.log("users",res.data.users);
     
   }
  }
  ).catch((err) =>{
    console.log("err",err);
    
  })
    setIsModalOpen(true)
  }


  const handleCloseMessages = () =>{
    setShowChatMessages(false)
  }
  const handleTabChange = (key) => {
  fetchMessages(key)
    setActiveTab(key); // Update the active tab state
  };





  const fetchMessages = (key) =>{
    setLoading(true)
    API.get(`/getMessages?emirate_id=${key}`)
    .then((res) =>{
     if(res.data.success){
      setLoading(false)
      if(key ==1){
        setDubaiMessages(res.data.messages)
      }else   
      if(key ==2){
        setAbudhabiMessages(res.data.messages)
      }else
      if(key ==3){
        setSharjahMessages(res.data.messages)
      }else
      if(key ==4){
        setAjmanMessages(res.data.messages)
      }else
      if(key ==5){
        setRasalkhaimaMessages(res.data.messages)
      }else
      if(key ==6){
        setUmalqweenMessages(res.data.messages)
      }else
      if(key ==7){
        setFujairahMessages(res.data.messages)
      }
     
     
       
     }
    }
    ).catch((err) =>{
      setLoading(false)
      console.log("err",err);
      
    })
  }
  useEffect(() =>{
    fetchMessages(user.emirate_id)
    setActiveTab(String(user.emirate_id))
  },[user])

  

  const selectedUserId = selectedUser?.id
  const currentUserId = user?.id


  useEffect(() => {
    const pusher = new Pusher('29ac8aff739e47e43ed1', {
      cluster: 'ap2'
    });
    const channel = pusher.subscribe('new_chat');
    channel.bind('message', (newMessage) =>{
  

    
      

  
  // setImageForChat(newMessage.imageName)
if(newMessage.group_id ==1 && (user.emirate_id == 1 || user.id == newMessage.sender_id)){
  setDubaiMessages((prevMessages) => [ newMessage, ...prevMessages]);  
}else
if(newMessage.group_id ==2 && (user.emirate_id == 2 || user.id == newMessage.sender_id)){
  setAbudhabiMessages((prevMessages) => [ newMessage, ...prevMessages]);    
}else
if(newMessage.group_id ==3 && (user.emirate_id == 3 || user.id == newMessage.sender_id)){
  setSharjahMessages((prevMessages) => [ newMessage, ...prevMessages]);    
}else
if(newMessage.group_id ==4 && (user.emirate_id == 4 || user.id == newMessage.sender_id)){
  setAjmanMessages((prevMessages) => [ newMessage, ...prevMessages]);  
}else
if(newMessage.group_id ==5 && (user.emirate_id == 5 || user.id == newMessage.sender_id)){
  setRasalkhaimaMessages((prevMessages) => [ newMessage, ...prevMessages]);   
}else
if(newMessage.group_id ==6 && (user.emirate_id == 6 || user.id == newMessage.sender_id)){
  setUmalqweenMessages((prevMessages) => [ newMessage, ...prevMessages]);   
}else
if(newMessage.group_id ==7 && (user.emirate_id == 7 || user.id == newMessage.sender_id)){
  setFujairahMessages((prevMessages) => [ newMessage, ...prevMessages]);    
}
  
  
    
    });
   return () => {
      channel.unbind_all();
      channel.unsubscribe();
    }
  }, [dubaiMessages,abudhabiMessages,sharjahMessages,ajmanMessages,rasalkhaimaMessages,umalqweenMessages,fujairahMessages])

  //groups reply messages

  useEffect(() => {
    const pusher = new Pusher('29ac8aff739e47e43ed1', {
      cluster: 'ap2'
    });
    const channel = pusher.subscribe('new_reply');
    channel.bind('message', (newMessage) =>{
  
  
  // setImageForChat(newMessage.imageName)
  if(newMessage.emirate_id ==1 && (user.emirate_id == 1 || user.id == newMessage.replier_id)) {
    setDubaiMessages((prevMessages) =>
      prevMessages.map((msg) => {
        if (msg.id == newMessage.message_id) {
          return {
            ...msg,
            replies: [ newMessage,...(msg.replies || [])],
          };
        }
        return msg;
      })
    );
  }else
  if(newMessage.emirate_id ==2 && (user.emirate_id == 2 || user.id == newMessage.replier_id)) {
    setAbudhabiMessages((prevMessages) =>
      prevMessages.map((msg) => {
        if (msg.id == newMessage.message_id) {
          return {
            ...msg,
            replies: [ newMessage,...(msg.replies || [])],
          };
        }
        return msg;
      })
    );
  }else
  if(newMessage.emirate_id ==3 && (user.emirate_id == 3 || user.id == newMessage.replier_id)) {
    setSharjahMessages((prevMessages) =>
      prevMessages.map((msg) => {
        if (msg.id == newMessage.message_id) {
          return {
            ...msg,
            replies: [ newMessage,...(msg.replies || [])],
          };
        }
        return msg;
      })
    );
  }else
  if(newMessage.emirate_id ==4 && (user.emirate_id == 4 || user.id == newMessage.replier_id)) {
    setAjmanMessages((prevMessages) =>
      prevMessages.map((msg) => {
        if (msg.id == newMessage.message_id) {
          return {
            ...msg,
            replies: [ newMessage,...(msg.replies || [])],
          };
        }
        return msg;
      })
    );
  }else
  if(newMessage.emirate_id ==5 && (user.emirate_id == 5 || user.id == newMessage.replier_id)) {
    setRasalkhaimaMessages((prevMessages) =>
      prevMessages.map((msg) => {
        if (msg.id == newMessage.message_id) {
          return {
            ...msg,
            replies: [ newMessage,...(msg.replies || [])],
          };
        }
        return msg;
      })
    );
  }else
  if(newMessage.emirate_id ==6 && (user.emirate_id == 6 || user.id == newMessage.replier_id)) {
 
    
    
    setUmalqweenMessages((prevMessages) =>
      prevMessages.map((msg) => {
        if (msg.id == newMessage.message_id) {
          return {
            ...msg,
            replies: [ newMessage,...(msg.replies || [])],
          };
        }
        return msg;
      })
    );
  }else
if(newMessage.emirate_id ==7 && (user.emirate_id == 7 || user.id == newMessage.replier_id)) {

  console.log("redux",user.id);
  console.log("msg",newMessage.replier_id);
  setFujairahMessages((prevMessages) =>
    prevMessages.map((msg) => {
      if (msg.id == newMessage.message_id) {
        return {
          ...msg,
          replies: [ newMessage,...(msg.replies || [])],
        };
      }
      return msg;
    })
  );
}

  
  
    
    });
   return () => {
      channel.unbind_all();
      channel.unsubscribe();
    }
  }, [dubaiMessages,abudhabiMessages,sharjahMessages,ajmanMessages,rasalkhaimaMessages,umalqweenMessages,fujairahMessages])



  useEffect(() => {
    const pusher = new Pusher('29ac8aff739e47e43ed1', {
      cluster: 'ap2'
    });
    const channel = pusher.subscribe('new_user_chat');
    channel.bind('message', (newMessage) =>{
  

if(currentUser.id == newMessage.reciever_id || currentUser.id == newMessage.sender_id ){

  setUserMessages((prevMessages) => [ newMessage,...prevMessages]);  

}


  
  
    
    });
   return () => {
      channel.unbind_all();
      channel.unsubscribe();
    }
  }, [userMessages,currentUser])








const handleReply = async (message,files,audioURL,message_id) =>{

  
  const formData = new FormData();
  formData.append('message_id', message_id);
  formData.append('emirate_id', activeTab);
  formData.append('comment', message);
  if (audioURL) {
    const audioBlob = await fetch(audioURL).then(res => res.blob());
    const audioFile = new File([audioBlob], 'audio.webm', { type: 'audio/webm' });
   
    
    formData.append('audio', audioFile); // Append audio file
  }

  // Append each file to the FormData object
  Array.from(files).forEach((file, index) => {
    formData.append(`images[${index}]`, file); // Use `images[]` to indicate array of images
  });

API.post("/replyMessage",formData)
.then((res) =>{
  console.log("res");
  
}).catch((err) =>{
  console.log("err",err);
  
})
    
    
}

const handleMessage = async  (message,files,audioURL) =>{

 
  const formData = new FormData();
  formData.append('emirate_id', activeTab);
  formData.append('message', message);

  if (audioURL) {
    const audioBlob = await fetch(audioURL).then(res => res.blob());
    const audioFile = new File([audioBlob], 'audio.webm', { type: 'audio/webm' });
  
    
    formData.append('audio', audioFile); // Append audio file
  }

  
  // Append each file to the FormData object
  Array.from(files).forEach((file, index) => {
    formData.append(`images[${index}]`, file); // Use `images[]` to indicate array of images
  });

API.post("/postMessage",formData)
.then((res) =>{
  console.log("res");
  
}).catch((err) =>{
  console.log("err",err);
  
})
  
}

const handleCurrentUserMessage = async (message,files,audioURL) =>{


  const formData = new FormData();
  formData.append('reciever_id', currentUser.id);
  formData.append('message', message);
  if (audioURL) {
    const audioBlob = await fetch(audioURL).then(res => res.blob());
    const audioFile = new File([audioBlob], 'audio.webm', { type: 'audio/webm' });
  
    
    formData.append('audio', audioFile); // Append audio file
  }

  // Append each file to the FormData object
  Array.from(files).forEach((file, index) => {
    formData.append(`images[${index}]`, file); // Use `images[]` to indicate array of images
  });

API.post("/postUserMessage",formData)
.then((res) =>{
  console.log("res");
  
}).catch((err) =>{
  console.log("err",err);
  
})

  
  
}

  const handleChat = ()=>{
    const data={receiver_id:selectedUser.id,message:chat}
   API.post("/chat",data)
   .then((res) =>{
    if(res){
      setChat('')
    }
      
   }).catch((err) =>{
    console.log(err,"err");
    
   })
    
  }

  const handleMouseEnter = (key) => {
    setOpenKeys([key]);
  };

  const handleUserClick = (user) => {
    setSelectedUser(user);

    setDubai((prevAjman) =>
      prevAjman.map((olduser) =>
        olduser.id === user.id
              ? { ...olduser, chats: [] } // Set chats to an empty array
              : olduser
      )
  );

  setAbudhabi((prevAjman) =>
    prevAjman.map((olduser) =>
      olduser.id === user.id
            ? { ...olduser, chats: [] } // Set chats to an empty array
            : olduser
    )
);

setSharjah((prevAjman) =>
  prevAjman.map((olduser) =>
    olduser.id === user.id
          ? { ...olduser, chats: [] } // Set chats to an empty array
          : olduser
  )
);

setAjman((prevAjman) =>
  prevAjman.map((olduser) =>
    olduser.id === user.id
          ? { ...olduser, chats: [] } // Set chats to an empty array
          : olduser
  )
);

setRasalkhaima((prevAjman) =>
  prevAjman.map((olduser) =>
    olduser.id === user.id
          ? { ...olduser, chats: [] } // Set chats to an empty array
          : olduser
  )
);

setUmalqween((prevAjman) =>
  prevAjman.map((olduser) =>
    olduser.id === user.id
          ? { ...olduser, chats: [] } // Set chats to an empty array
          : olduser
  )
);

setFujairah((prevAjman) =>
  prevAjman.map((olduser) =>
    olduser.id === user.id
          ? { ...olduser, chats: [] } // Set chats to an empty array
          : olduser
  )
);

    setShowChat(true);
    setShowChatMessages(true)
    setLoading(true)
    API.get("/allmessages/"+user.id)
    .then((res) => {
      setLoading(false)
      if(res.data.success){
        setAllMessages(res.data.allmessages)
      }

    
    }).catch((err) =>{
      console.log(err);
      
    })
    
    // setShowChat(true);  // Open the drawer when a user is clicked
    // setShowChatMessages(true)
  };


  const allUsers = [
    { key: 1, name: 'John Doe', age: 32, role: 'Admin' },
    { key: 2, name: 'Jane Smith', age: 28, role: 'User' },
    // Add more user data here
  ];

  const newUsers = [
    { key: 1, name: 'Alice Brown', age: 22, role: 'User' },
    { key: 2, name: 'Bob White', age: 25, role: 'User' },
    // Add more new user data here
  ];

  // List item render
  const renderUserItem = (user) => (
    <List.Item key={user.key}>
      <div>
        <strong>{user.name}</strong>
        <p>Age: {user.age}</p>
        <p>Role: {user.role}</p>
        <Button>Details</Button>
      </div>
    </List.Item>
  );

  const handleMouseLeave = () => {
    setOpenKeys([]);
  };
  const menu = (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <Link to="/change-password">
              {lang == "en" ? "Change Password" : "تغير كلمة السر"}
            </Link>
          ),
          icon: <KeyOutlined />,
        },
        {
          key: "2",
          label: (
            <Link to="/profile">
              {lang == "en" ? "My Profile" : "  الملف الشخصي"}
            </Link>
          ),
          icon: <UserOutlined />,
        },
        {
          key: "3",
          danger: true,
          label: (
            <Link onClick={handleLogout}>
              {lang == "en" ? "Sign Out" : "  تسجيل الخروج"}
            </Link>
          ),
          icon: <LogoutOutlined />,
        },
      ]}
    />
  );





  function handleLogout(e) {
    e.preventDefault();
   
    // Swal.fire({
    //   title: lang == "en" ? "Are you sure?" : "هل انت متأكد ؟",
    //   icon: "question",
    //   showCancelButton: true,
    //   confirmButtonColor: "#3085d6",
    //   cancelButtonColor: "#d33",
    //   cancelButtonText: lang == "en" ? "Cancel" : "الغاء",
    //   confirmButtonText: lang == "en" ? "Yes , Sign out " : "نعم ، خروج",
    // }).then((result) => {
    //   if (result.isConfirmed) {
    if(role==1){
      dispatch({ type: "SET_LOGOUT" });
      history.push("/");
    }else{
    let timerInterval;
    Swal.fire({
      title: lang == "en" ? "Sign Out" : "تسجيل الخروج",
      html:
        lang == "en"
          ? '<p class="animate-charcter">Thank you for choosing findproperties</p><br/>Your session will end in <b></b> seconds.'
          : '<p class="animate-charcter">شكراً لاختياركم فايند بروبرتيز</p><br/>ستغلق الجلسة خلال  <b></b> ثواني',
      timer: 10000,
      timerProgressBar: true,
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: lang == "en" ? "Cancel" : "الغاء",
      confirmButtonText: lang == "en" ? "Yes , Sign out " : "نعم ، خروج",

      //cancelButtonColor: '#d33',
      didOpen: () => {
        //Swal.showLoading();
        const b = Swal.getHtmlContainer().querySelector("b");
        timerInterval = setInterval(() => {
          b.textContent = (Swal.getTimerLeft() / 1000).toFixed(0);
        }, 100);
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch({ type: "SET_LOGOUT" });
        history.push("/");
      }
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
        dispatch({ type: "SET_LOGOUT" });
        history.push("/");
      }
    });
  }
    // dispatch({ type: "SET_LOGOUT" });
    // history.push("/");
    //}
    //});
    // dispatch({ type: "SET_LOGOUT" });
    // history.push("/");
    // setGoing(true);
    // setTimeout(() => {
    //setGoing(false);

    //}, 3000);
  }

  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  // useEffect(() => {
  //   if (window.screen.width > 1000) {
  //     setShowHide(true);
  //   }
  // }, []);

  const items1 = ["1", "2", "3"].map((key) => ({
    key,
    label: React.createElement(
      collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
      {
        className: "trigger",
        onClick: () => setCollapsed(!collapsed),
      }
    ),
  }));

 const handleStartChat=() =>{
  
  
  if(user?.emirate_id){
    setShowChat(true)
    setCount(0)
  }else{
    alert("please update your profile by selecting one emirate")
  }

 }


  const messages = [
    { type: 1, text: 'Hello, how are you?' },
    { type: 2, text: 'I am fine, thanks!' },
    { type: 1, text: 'What about you?' },
    { type: 2, text: 'I am doing great!' },
    { type: 1, text: 'Good to hear!' },
  ];
  
  const NavItems = () => (
    <Menu
    theme="dark"
    mode="inline"
    defaultSelectedKeys={['1']}
    openKeys={openKeys}
    onOpenChange={(keys) => setOpenKeys(keys)}
    items={
      [
      {
        key: 'dashboard',
        icon: <DashboardFilled onClick={() => { setVisible(false); history.push('/dashboard'); }} />,
        label: (
          <Link   style={{ color: 'inherit', textDecoration: 'none' }} to="/dashboard" onClick={() => setVisible(false)}>
            {lang === 'en' ?'Dashboard':'الرئيسية'}
          </Link>
        ),
        children:[]
        
      },
      role===1 &&{
        key: 'employees',
        icon: <UsergroupDeleteOutlined />,
        label:  <>{lang === 'en' ?'Employees':'الموظفين'} </>,
        children: [
          {
            key: 'emp',
            label: (
              <Link to="/employees" onClick={() => setVisible(false)}>
                   {lang == "en" ? " Employees" : "  الموظفين"}
              </Link>
            ),
          },
          {
            key: 'addemp',
            label: (
              <Link to="/addemp" onClick={() => setVisible(false)}>
               {lang == "en" ? "Add Employee" : "اضافة موظف"}
              </Link>
            ),
          },
         
        ], 
      },
      role === 1 && {
        key: 'manageList',
        icon: <UnorderedListOutlined />,
        label: lang === 'en' ? 'Manage List' : 'ادارة العقارات',
        children: [
          {
            key: 'adminPending',
            label: (
              <Link to="/adminpending" onClick={() => setVisible(false)}>
                {lang === 'en' ? 'Pending' : 'عقارات جديدة'}
              </Link>
            ),
          },
          {
            key: 'adminRejected',
            label: (
              <Link to="/adminrejected" onClick={() => setVisible(false)}>
                {lang === 'en' ? 'Rejected' : 'عقارات مرفوضة'}
              </Link>
            ),
          },
          {
            key: 'adminPublished',
            label: (
              <Link to="/adminlisting" onClick={() => setVisible(false)}>
                {lang === 'en' ? 'Published' : 'تم النشر'}
              </Link>
            ),
          },
          {
            key: 'adminDraft',
            label: (
              <Link to="/admindraft" onClick={() => setVisible(false)}>
                {lang === 'en' ? 'Drafted/Inactive' : 'عقارات محذوفة'}
              </Link>
            ),
          },
        ],
      },
      role === 1 && {
        key: 'agencies',
        icon: <UsergroupDeleteOutlined />,
        label: <>{lang === 'en' ?'Agencies':'الوكلاء'}</>,
        children: [
          {
            key: 'manageAgency',
            label: (
              <Link to="/agencies" onClick={() => setVisible(false)}>
              
                {lang === 'en' ?'Agencies':'الوكلاء'}
              </Link>
            ),
          },
          {
            key: 'agencyAgents',
            label: (
              <Link to="/company-agents" onClick={() => setVisible(false)}>
      
                {lang === 'en' ?'Agencies - Users':'الوكلاء'}
              </Link>
            ),
          },
          {
            key: 'companyProperties',
            label: (
              <Link to="/company-properties" onClick={() => setVisible(false)}>
                {lang === 'en' ? 'Agency Properties' : 'عقارات الوكالة'}
              </Link>
            ),
          },
          {
            key: 'agentsProperties',
            label: (
              <Link to="/company-agents-properties" onClick={() => setVisible(false)}>
                {lang === 'en' ? 'Agents & Properties' : 'الوكلاء والعقارات'}
              </Link>
            ),
          },
        ],
      },

      role ===1 &&{
        key: 'realestates',
        icon: <StockOutlined onClick={() => { setVisible(false); history.push('/realestatesguide'); }} />,
        label:  <Link   style={{ color: 'inherit', textDecoration: 'none' }} to={`/realestatesguide`}  onClick={() => setVisible(false)}> {lang == "en" ? "r_estates guide" : " دليل العقارات"}</Link>,
        children:[]
    
      },
      role ===1 &&{
        key: 'provider',
        icon: <SettingOutlined onClick={() => { setVisible(false); history.push('/service-providers'); }} />,
        label:  <Link   style={{ color: 'inherit', textDecoration: 'none' }} to={`/service-providers`}  onClick={() => setVisible(false)}> {lang == "en" ? " Service Providers" : "  مزودي الخدمات"}</Link>,
        children:[]
    
      },
      role === 1 && {
        key: 'blogs',
        icon: <UnorderedListOutlined />,
        label: lang === 'en' ? 'Blogs and Pages' : ' المقالات والصفحات',
        children: [
          {
            key: 'blogs',
            label: (
              <Link to="/blogs" onClick={() => setVisible(false)}>
                 {lang == "en" ? "List Blogs " : " المقالات"}
              </Link>
            ),
          },
          {
            key: 'newblog',
            label: (
              <Link to="/newblog" onClick={() => setVisible(false)}>
                 {lang == "en" ? " New Blog" : "اضافة موضوع "}
              </Link>
            ),
          },
          {
            key: 'pages',
            label: (
              <Link to="/pages" onClick={() => setVisible(false)}>
                 {lang == "en" ? "List Pages" : " كل الصفحات"}
              </Link>
            ),
          },
          {
            key: 'newpage',
            label: (
              <Link to="/newpage" onClick={() => setVisible(false)}>
                 {lang == "en" ? " New Page" : " انشاء صفحة"}
              </Link>
            ),
          },
        ],
      },

      role ===1 &&{
        key: 'leads',
        icon: <StockOutlined onClick={() => { setVisible(false); history.push('/leads'); }} />,
        label:  <Link   style={{ color: 'inherit', textDecoration: 'none' }} to={`/leads`}  onClick={() => setVisible(false)}> {lang == "en" ? "Leads" : " العملاء المحتملون"}</Link>,
        children:[]
    
      },
      role===2 &&{
        key: 'manageList',
        icon: <UnorderedListOutlined />,
        label: lang === 'en' ? 'Manage List' : 'ادارة العقارات',
        children: [
          {
            key: 'adminPending',
            label: (
              <Link to="/pending" onClick={() => setVisible(false)}>
                {lang === 'en' ? 'Pending' : 'عقارات جديدة'}
              </Link>
            ),
          },
          {
            key: 'adminRejected',
            label: (
              <Link to="/rejected" onClick={() => setVisible(false)}>
                {lang === 'en' ? 'Rejected' : 'عقارات مرفوضة'}
              </Link>
            ),
          },
          {
            key: 'adminPublished',
            label: (
              <Link to="/publish" onClick={() => setVisible(false)}>
                {lang === 'en' ? 'Published' : 'تم النشر'}
              </Link>
            ),
          },
          {
            key: 'adminDraft',
            label: (
              <Link to="/draft" onClick={() => setVisible(false)}>
                {lang === 'en' ? 'Drafted/Inactive' : 'عقارات محذوفة'}
              </Link>
            ),
          },
        ],
      },
      role ===2 &&{
        key: 'quota',
        icon: <FundOutlined onClick={() => { setVisible(false); history.push('/usage-quota'); }} />,
        label:  <Link   style={{ color: 'inherit', textDecoration: 'none' }} to={`/usage-quota`}  onClick={() => setVisible(false)}>   {lang === 'en' ? 'Quota Usage' : " الحزمة المستخدمة"}</Link>,
        children:[]
    
      },
      role===2 &&{
        key: 'users',
        icon: <UsergroupDeleteOutlined />,
        label: lang == "en" ? "User Management" : "ادارة المستخدمين",
        children: [
          {
            key: 'adminPending',
            label: (
              <Link to="/agents" onClick={() => setVisible(false)}>
             
                {lang === 'en' ? 'Agents' : "وكلاء"}
              </Link>
            ),
          },
          {
            key: 'adminRejected',
            label: (
              <Link to="/addnewuser" onClick={() => setVisible(false)}>
             
               {lang === 'en' ? 'Add Agent' : "اضافة وكيل"}
              </Link>
            ),
          },
         
        ], 
      },
      role ===2 &&{
        key: 'leads',
        icon: <StockOutlined onClick={() => { setVisible(false); history.push('/all-leads'); }} />,
        label:  <Link   style={{ color: 'inherit', textDecoration: 'none' }} to={`/all-leads`}  onClick={() => setVisible(false)}> {lang == "en" ? "Leads" : " العملاء المحتملون"}</Link>,
        children:[]
    
      },

      role ===2 &&{
        key: 'newproperty',
        // icon: collapsed? <PlusCircleOutlined />:null,
        label: collapsed?<Link to={`/addproperty`}  onClick={() => setVisible(false)}><PlusCircleOutlined  /></Link>:      <Button
                      size="large"
                      shape="round"
                      type="primary"
                      style={{backgroundColor:'white',color:'black'}}
                    
                    >
         <Link to={`/addproperty`}  onClick={() => setVisible(false)}> {lang === 'en' ? 'Add Property' : "اضافة عقار"}</Link>
         </Button>
    
      },

      // agent links
      role===3 &&{
        key: 'manageList',
        icon: <UnorderedListOutlined />,
        label: lang === 'en' ? 'Manage List' : 'ادارة العقارات',
        children: [
          {
            key: 'adminPending',
            label: (
              <Link to="/pending-property" onClick={() => setVisible(false)}>
                {lang === 'en' ? 'Pending' : 'عقارات جديدة'}
              </Link>
            ),
          },
          {
            key: 'adminRejected',
            label: (
              <Link to="/rejected-property" onClick={() => setVisible(false)}>
                {lang === 'en' ? 'Rejected' : 'عقارات مرفوضة'}
              </Link>
            ),
          },
          {
            key: 'adminPublished',
            label: (
              <Link to="/publish-property" onClick={() => setVisible(false)}>
                {lang === 'en' ? 'Published' : 'تم النشر'}
              </Link>
            ),
          },
          {
            key: 'adminDraft',
            label: (
              <Link to="/draft-property" onClick={() => setVisible(false)}>
                {lang === 'en' ? 'Drafted/Inactive' : 'عقارات محذوفة'}
              </Link>
            ),
          },
        ],
      },

      role ===3 &&{
        key: 'leads',
        icon: <StockOutlined onClick={() => { setVisible(false); history.push('/allleads'); }} />,
        label:  <Link   style={{ color: 'inherit', textDecoration: 'none' }} to={`/allleads`}  onClick={() => setVisible(false)}> {lang == "en" ? "Leads" : " العملاء المحتملون"}</Link>,
        children:[]
    
      },
      role ===3 &&{
        key: 'newproperty',
        // icon: collapsed? <PlusCircleOutlined />:null,
        label: collapsed?<Link to={`/add-property`}  onClick={() => setVisible(false)}><PlusCircleOutlined  /></Link>:      <Button
                      size="large"
                      shape="round"
                      type="primary"
                      style={{backgroundColor:'white',color:'black'}}
                    
                    >
         <Link to={`/add-property`}  onClick={() => setVisible(false)}>{lang === 'en' ?'Add Property':'اضافة عقار'}</Link>
         </Button>
    
      },
      
    ]
  }
  />
    // <Menu
    //   width={200}
    //   style={{ height: "100vh" }}
    //   theme="dark"
    //   mode="inline"
    //   defaultSelectedKeys={["1"]}
    // >
    //   {role == 1 ? (
    //     <div style={{ marginLeft: "10px", marginRight: "10px" }}>
    //       <Menu.Item key="dashboard" icon={<DashboardFilled />}>
    //         <Link to={`/dashboard`}  onClick={() => setVisible(false)}>{i18next.t("dashboard")}</Link>
    //       </Menu.Item>
    //          <Menu.Item    icon={<UsergroupDeleteOutlined />}>
    //           <Link to={`/employees`}  onClick={() => setVisible(false)}>
    //             {lang == "en" ? " Employees" : "  الموظفين"}
    //           </Link>
    //         </Menu.Item>

    //       {/* <Menu.SubMenu
    //         title={i18next.t("employees")}
    //         icon={<UsergroupDeleteOutlined />}
    //       >
    //         <Menu.Item>
    //           <Link to={`/employees`}  onClick={() => setVisible(false)}>
    //             {lang == "en" ? "All Employees" : " بيانات الموظفين"}
    //           </Link>
    //         </Menu.Item>
    //         <Menu.Item>
    //           <Link to={`/addemp`}  onClick={() => setVisible(false)}>
    //             {lang == "en" ? "Add Employee" : "اضافة موظف"}
    //           </Link>
    //         </Menu.Item>
    //       </Menu.SubMenu> */}

    //       <Menu.SubMenu
    //         title={lang == "en" ? "Manage List" : "ادارة العقارات"}
    //         icon={<UnorderedListOutlined />}
    //       >
    //         <Menu.Item>
    //           {" "}
    //           <Link to="/adminpending"  onClick={() => setVisible(false)}>
    //             {lang == "en" ? "Pending " : "عقارات جديدة"}
    //           </Link>
    //         </Menu.Item>
    //         <Menu.Item>
    //           {" "}
    //           <Link to="/adminrejected"  onClick={() => setVisible(false)}>
    //             {lang == "en" ? "Rejected" : "عقارات مرفوضة"}
    //           </Link>
    //         </Menu.Item>

    //         <Menu.Item>
    //           {" "}
    //           <Link to="/adminlisting"  onClick={() => setVisible(false)}>
    //             {lang == "en" ? "Published" : "تم النشر"}
    //           </Link>
    //         </Menu.Item>
    //         <Menu.Item>
    //           {" "}
    //           <Link to="/admindraft"  onClick={() => setVisible(false)}>
    //             {lang == "en" ? "Drafted/ Inactive" : "عقارات محذوفة"}
    //           </Link>
    //         </Menu.Item>
    //       </Menu.SubMenu>

    //       <Menu.SubMenu
    //         title={i18next.t("agencies")}
    //         icon={<UsergroupDeleteOutlined />}
    //       >
    //         <Menu.Item>
    //           {" "}
    //           <Link to="/agencies"  onClick={() => setVisible(false)}>{i18next.t("manageagency")}</Link>
    //         </Menu.Item>

    //         <Menu.Item>
    //           {" "}
    //           <Link to="/company-agents"  onClick={() => setVisible(false)}>{i18next.t("agencyagents")}</Link>
    //         </Menu.Item>
    //         <Menu.Item>
    //           {" "}
    //           <Link to="/company-properties"  onClick={() => setVisible(false)}>Agency Properties</Link>
    //         </Menu.Item>
    //         <Menu.Item>
    //           {" "}
    //           <Link to="/company-agents-properties"  onClick={() => setVisible(false)}>Agents & Properties</Link>
    //         </Menu.Item>
    //       </Menu.SubMenu>

    //       <Menu.SubMenu
    //         title={lang == "en" ? "Blogs & Pages" : "المقالات والصفحات"}
    //         icon={<UsergroupDeleteOutlined />}
    //       >
    //         <Menu.Item>
    //           {" "}
    //           <Link to="/blogs"  onClick={() => setVisible(false)}>
    //             {lang == "en" ? "List Blogs " : " المقالات"}
    //           </Link>
    //         </Menu.Item>

    //         <Menu.Item>
    //           {" "}
    //           <Link to="/newblog"  onClick={() => setVisible(false)}>
    //             {lang == "en" ? " New Blog" : "اضافة موضوع "}
    //           </Link>
    //         </Menu.Item>

    //         <Menu.Item>
    //           {" "}
    //           <Link to="/pages"  onClick={() => setVisible(false)}>
    //             {lang == "en" ? "List Pages" : " كل الصفحات"}
    //           </Link>
    //         </Menu.Item>

    //         <Menu.Item>
    //           {" "}
    //           <Link to="/newpage"  onClick={() => setVisible(false)}>
    //             {lang == "en" ? " New Page" : " انشاء صفحة"}
    //           </Link>
    //         </Menu.Item>
    //       </Menu.SubMenu>

    //       <Menu.Item key="leads" icon={<PhoneOutlined />}>
    //         <Link to={`/leads`}  onClick={() => setVisible(false)}>
    //           {lang == "en" ? "Leads" : "حالات الاتصال"}{" "}
    //         </Link>
    //       </Menu.Item>
    //       <Menu.Item key="leads-report" icon={<FilePdfOutlined />}>
    //         <Link to={`/leads-report`}  onClick={() => setVisible(false)}>
    //          Leads To PDF
    //         </Link>
    //       </Menu.Item>

          
    //     </div>
    //   ) : (
    //     <div>
    //       {role == 2 ? (
    //         <div style={{ marginLeft: "10px", marginRight: "5px" }}>
    //           <Menu.Item key="dashboard" icon={<DashboardFilled />}>
    //             {" "}
    //             <Link to={`/dashboard`} onClick={() => setVisible(false)}>
    //               {i18next.t("dashboard")}
    //             </Link>
    //           </Menu.Item>

    //           {/* <Menu.SubMenu
    //             key="list"
    //             title={i18next.t("listing")}
    //             icon={<UnorderedListOutlined />}
    //           > */}
    //             <Menu.Item key="pending">
    //               <Link to={`/pending`} onClick={() => setVisible(false)}>
    //                 {lang == "en" ? "Pending" : "في الإنتظار"}
    //               </Link>
    //             </Menu.Item>

    //             <Menu.Item key="publish">
    //               <Link to={`/publish`} onClick={() => setVisible(false)}>
    //                 {lang == "en" ? "Publish" : " تم النشر"}
    //               </Link>
    //             </Menu.Item>
    //             <Menu.Item key="reject">
    //               <Link to={`/rejected`} onClick={() => setVisible(false)}>
    //                 {lang == "en" ? "Rejected" : " تم الرفض"}{" "}
    //               </Link>
    //             </Menu.Item>
    //             <Menu.Item key="draft">
    //               <Link to={`/draft`} onClick={() => setVisible(false)}>
    //                 {lang == "en" ? "Drafts/ Inactive" : "  غير نشط / مسودة"}
    //               </Link>
    //             </Menu.Item>
    //             <Menu.Item key="quota">
    //               <Link to={`/usage-quota`}  onClick={() => setVisible(false)}>{i18next.t("quotausage")}</Link>
    //             </Menu.Item>
    //           {/* </Menu.SubMenu> */}

    //           <Menu.SubMenu
    //             key="usermanagement"
    //             title={lang == "en" ? "User Management" : "ادارة المستخدمين"}
    //             icon={<UsergroupDeleteOutlined />}
    //           >
    //             <Menu.Item key="agent">
    //               <Link to="/agents" onClick={() => setVisible(false)}>
    //                 {i18next.t("manageuser")}
    //               </Link>
    //             </Menu.Item>

    //             <Menu.Item key="adduser">
    //               <Link to="/addnewuser" onClick={() => setVisible(false)}>
    //                 {i18next.t("adduser")}
    //               </Link>
    //             </Menu.Item>
    //           </Menu.SubMenu>
    //           <Menu.Item key="all-leads" icon={<PhoneOutlined />}>
    //             <Link to={`/all-leads`} onClick={() => setVisible(false)}>
    //               {lang == "en" ? "Leads" : "حالات الاتصال"}{" "}
    //             </Link>
    //           </Menu.Item>

    //           <Menu.Item key="addproperty" style={{ marginTop: "100px" }}>
    //             <Button
    //               size="large"
    //               shape="round"
    //               type="primary"
    //               icon={<PlusOutlined />}
    //             >
    //               <Link
    //                 to="/addproperty"
    //                 style={{ color: "white" }}
    //                 onClick={() => setVisible(false)}
    //               >
    //                 {i18next.t("addproperty")}
    //               </Link>
    //             </Button>
    //           </Menu.Item>
    //         </div>
    //       ) : (
    //         <div>
    //           {role == 3 ? (
    //             <div style={{ marginLeft: "10px", marginRight: "10px" }}>
    //               <Menu.Item key="dashboard" icon={<DashboardFilled />}>
    //                 <Link to={`/dashboard`} onClick={() => setVisible(false)}>
    //                   {i18next.t("dashboard")}
    //                 </Link>
    //               </Menu.Item>
    //               <Menu.SubMenu
    //                 title={i18next.t("listing")}
    //                 icon={<UnorderedListOutlined />}
    //               >
    //                 <Menu.Item>
    //                   {" "}
    //                   <Link
    //                     to={`/pending-property`}
    //                     onClick={() => setVisible(false)}
    //                   >
    //                     {lang == "en" ? "Pending" : "في الإنتظار"}
    //                   </Link>
    //                 </Menu.Item>
    //                 <Menu.Item>
    //                   {" "}
    //                   <Link
    //                     to={`/publish-property`}
    //                     onClick={() => setVisible(false)}
    //                   >
    //                     {lang == "en" ? "Publish" : " تم النشر"}
    //                   </Link>
    //                 </Menu.Item>
    //                 <Menu.Item>
    //                   {" "}
    //                   <Link
    //                     to={`/rejected-property`}
    //                     onClick={() => setVisible(false)}
    //                   >
    //                     {lang == "en" ? "Rejected" : " تم الرفض"}
    //                   </Link>
    //                 </Menu.Item>
    //                 <Menu.Item>
    //                   {" "}
    //                   <Link
    //                     to={`/draft-property`}
    //                     onClick={() => setVisible(false)}
    //                   >
    //                     {lang == "en"
    //                       ? "Drafts/ Inactive"
    //                       : "  غير نشط / مسودة"}
    //                   </Link>
    //                 </Menu.Item>
    //               </Menu.SubMenu>
    //               <Menu.Item key="allleads" icon={<PhoneOutlined />}>
    //                 <Link to={`/allleads`} onClick={() => setVisible(false)}>
    //                   {lang == "en" ? "Leads" : "حالات الاتصال"}{" "}
    //                 </Link>
    //               </Menu.Item>
    //               <Menu.Item key="add-property" style={{ marginTop: "100px" }}>
    //                 <Button
    //                   size="large"
    //                   shape="round"
    //                   type="primary"
    //                   icon={<PlusOutlined />}
    //                 >
    //                   <Link
    //                     to="/add-property"
    //                     onClick={() => setVisible(false)}
    //                     style={{ color: "white" }}
    //                   >
    //                     {i18next.t("addproperty")}
    //                   </Link>
    //                 </Button>
    //               </Menu.Item>
    //             </div>
    //           ) : (
    //             <div style={{ marginLeft: "10px", marginRight: "10px" }}>
    //               <Menu.Item key="dashboard" icon={<DashboardFilled />}>
    //                 <Link to={`/dashboard`}  onClick={() => setVisible(false)}>{i18next.t("dashboard")}</Link>
    //               </Menu.Item>

    //               <Menu.Item>
    //                 {" "}
    //                 <Link to={`/blogs`}  onClick={() => setVisible(false)}>
    //                   {lang == "en" ? "Blogs" : "المقالات"}
    //                 </Link>
    //               </Menu.Item>
    //               <Menu.Item>
    //                 {" "}
    //                 <Link to={`/newblog`}  onClick={() => setVisible(false)}>
    //                   {lang == "en" ? "New Blog" : " مقال جديد "}
    //                 </Link>
    //               </Menu.Item>
    //               {/* <Menu.Item>
    //                 {" "}
    //                 <Link to={`/pages`}>
    //                   {lang == "en" ? "Pages" : "  الصفحات"}
    //                 </Link>
    //               </Menu.Item>
    //               <Menu.Item>
    //                 {" "}
    //                 <Link to={`/newpage`}>
    //                   {lang == "en" ? "New Page" : "صفحة جديدة"}
    //                 </Link>
    //               </Menu.Item> */}
    //             </div>
    //           )}
    //         </div>
    //       )}
    //     </div>
    //   )}
    // </Menu>
  );

  return (
    <>
      {authenticated ? (
        <>
          {going ? (
            <Bye />
          ) : (
            <Layout dir={lang == "en" ? "ltr" : "rtl"} style={{ minHeight: '100vh',backgroundColor: "white" }}>
              <Header
                style={{ width: "100vw", backgroundColor: "white" }}
                className=""
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>
                    {width > 720 ? (
                      <img
                        src="/logo.png"
                        style={{ paddingLeft: "0px", paddingRight: "10px" }}
                        alt="Logo"
                      />
                    ) : (
                      <></>
                    )}

                    {width > 720
                      ? React.createElement(
                          collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                          {
                            className: "trigger",
                            onClick: () => setCollapsed(!collapsed),
                          }
                        )
                      : React.createElement(
                          collapsed ? MenuOutlined : MenuOutlined,
                          {
                            className: "trigger",
                            onClick: () => setVisible(true),
                          }
                        )}
                  </div>
         

                  <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                  
                  <div style={{paddingLeft:'20px',paddingRight:'20px'}}>
                    <Dropdown overlay={menu1}>
                      <a onClick={(e) => e.preventDefault()}>
                        <Space>
                          {lang == "en" ? (
                            <>
                              <span className="flag-icon flag-icon-gb"> </span>{" "}
                              {width > 720 ? "English" : ""}
                            </>
                          ) : (
                            <>
                              <span className="flag-icon flag-icon-ae"> </span>{" "}
                              {width > 720 ? "العربية" : ""}
                            </>
                          )}
                          {width > 720 ? <DownOutlined /> : null}
                        </Space>
                      </a>
                    </Dropdown>
                  </div>
                  <div>
                    {role ==3?
                  <div style={{ position: 'relative', display: 'inline-block' }}>
                     {/* <Modal title="Basic Modal" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <p>Some contents...</p>
        <p>Some contents...</p>
        <p>Some contents...</p>
      </Modal> */}

<Drawer width={1000} title={currentUser.name} onClose={handleCancel} open={isModalOpen}>
  {loading?<Skeleton
    avatar
    active={true}
    paragraph={{
      rows: 4,
      width:300
    }}
  />:<>
  {userMessages.length < 1 ?   <div
     style={{
       display: "flex",
       flexDirection: "column",
       alignItems: "center",
       justifyContent: "center",
       height: "70vh",
       textAlign: "center",
       color: "#6c757d",
     }}
   >
     <svg
       xmlns="http://www.w3.org/2000/svg"
       width="64"
       height="64"
       fill="currentColor"
       viewBox="0 0 16 16"
       style={{ marginBottom: "16px" }}
     >
       <path d="M4.5 11a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7Zm-3-1a2.5 2.5 0 0 1-2.5-2.5V5A2.5 2.5 0 0 1 1.5 2.5h11A2.5 2.5 0 0 1 15.5 5v2.5A2.5 2.5 0 0 1 13 10.5h-2.5L6.5 14v-3.5H1.5ZM6 6.5a.5.5 0 1 0-1 0 .5.5 0 0 0 1 0Zm3 0a.5.5 0 1 0-1 0 .5.5 0 0 0 1 0Zm3 0a.5.5 0 1 0-1 0 .5.5 0 0 0 1 0Z" />
     </svg>
     <h2 style={{ margin: 0, fontSize: "1.5rem" }}>No Messages Yet</h2>
     <p style={{ margin: "8px 0 0", fontSize: "1rem" }}>
       Start a conversation to see messages here.
     </p>
   </div>:""}

  {userMessages.map((msg, index) => {
  const isSender = msg.sender_id === user.id; // Check if the message is from the logged-in user

  return (
    <div
    style={{
      display: 'flex',
      justifyContent: isSender ? 'flex-start' : 'flex-end', // Align right for sender, left for receiver
      width: '100%', // Take full width of the parent container
      paddingBottom: '5px',
      marginBottom: '5px', // Add space between each message
    }}
    key={index}
  >
    <div
      style={{
        padding: '12px 16px', // Increased padding for larger bubbles
        borderRadius: '10px',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: isSender ?  '#d0e7ff': '#f1f1f1' , // Different background color for sender and receiver
        color: '#000',
        width: '90%', // Set a larger width for the message bubble (now 80% of the container)
        maxWidth: '900px', // Optional: You can cap the width to 600px for larger screen sizes
        alignItems: 'flex-start', // Align text inside the bubble
        wordWrap: 'break-word', // Prevent long text from overflowing
      }}
    >
        {/* Profile Image and Sender Name */}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '8px',
            marginLeft: isSender ? '8px' : '0', // For sender, move the image and name to the right
            marginRight: isSender ? '0' : '8px',// For receiver, move the image and name to the left
            direction:isSender ? 'rtl' : 'ltr'
          }}
        >
          {isSender ? (
            <img
              src={baseurlImg + '/uploads/profiles/' + msg.sender.profile}
              alt={msg.sender.name}
              style={{
                width: '30px',
                height: '30px',
                borderRadius: '50%',
                marginLeft: '8px', // Place image to the left for sender
              }}
            />
          ) : (
            <img
              src={baseurlImg + '/uploads/profiles/' + msg.sender.profile}
              alt={msg.sender.name}
              style={{
                width: '30px',
                height: '30px',
                borderRadius: '50%',
                marginRight: '8px',
                 // Place image to the right for receiver
              }}
            />
          )}

          <span style={{ fontWeight: 'bold',padding:'10px' }}>{msg.sender.name}</span>
        </div>

        {/* Message Content */}
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {msg.message}
          {msg?.audioFilename? <div style={{ marginLeft: '10px' }}>
          <audio controls>
            <source src={baseurlImg2 + '/uploads/audios/'+msg?.audioFilename} type="audio/webm" />
        
          </audio>
        </div>:null}
          {/* Image Content */}
          <div>
          {msg?.imageNames?.length > 0 && (
  <div style={{ width: '100%', maxWidth: '300px', margin: '0 auto', paddingTop: '10px' }}>
    <Carousel  prevArrow={<div style={arrowStyle}>{'<'}</div>} // Custom previous arrow
      nextArrow={<div style={arrowStyle}>{'>'}</div>} arrows  dotPosition="bottom">
      {msg.imageNames.map((img, indexImage) => (
        <div key={indexImage}>
          <Image
            src={baseurlImg2 + '/uploads/properties/' + img}
            alt={`Image ${indexImage}`}
            style={{
              width: '100%', // Full width of the parent container
              height: '200px', // Set a consistent height
              objectFit: 'cover', // Ensure the image fits without distortion
              borderRadius: '8px', // Optional: Rounded corners
            }}
          />
        </div>
      ))}
    </Carousel>
  </div>
)}
          </div>
        </div>

        {/* Message Timestamp */}
        <span
          style={{
            marginTop: '4px',
            fontSize: '12px',
            color: '#888',
            textAlign: isSender ? 'right' : 'left', // Timestamp aligned based on sender or receiver
          }}
        >
          {msg.date}
        </span>
      </div>
    </div>
  );
})}

</>}
<div
    style={{
      position: 'fixed',
      bottom: 0,
      width: '100%',
      backgroundColor: '#fff',
      padding: '10px',
      marginTop:'10px',
   
      zIndex: 1000,
    }}
  >
    <MessageInput
      onSend={(message, files,audioURL) => {
        handleCurrentUserMessage(message, files,audioURL);
      }}
      onAttach={(file) => console.log('File attached:', file)}
    />
  </div>
      </Drawer>
      
  {/* <WechatOutlined 
    onClick={handleStartChat} 
    style={{ 
      fontSize: '30px', 
      backgroundColor: 'white', 
      color: '#0078d7' 
    }} 
  />
  <div 
    style={{ 
      position: 'absolute', 
      top: '0px', 
      right: '-5px', 
      backgroundColor: 'red', 
      color: 'white', 
      fontSize: '10px', 
      width: '15px', 
      height: '15px', 
      borderRadius: '50%', 
      display: 'flex', 
      alignItems: 'center', 
      justifyContent: 'center', 
      fontWeight: 'bold' 
    }}
  >
    {count} 
  </div> */}
</div>:null}

                    </div>
                  <div style={{paddingLeft:'20px',paddingRight:'20px'}}>
                    <Dropdown overlay={menu}>
                      <a onClick={(e) => e.preventDefault()}>
                        <Space>
                          <h4>{width > 720 ? user.name : null}</h4>
                          <Avatar
                            size="large"
                            style={{
                              backgroundColor: "#87d068",
                              marginRight: "1rem",
                            }}
                            icon={
                              <img
                                src={
                                  baseurlImg +
                                  "/uploads/profiles/" +
                                  user.profile
                                }
                                style={{
                                  width: "40px",
                                  height: "40px",
                                  borderRadius: "10px",
                                }}
                              />
                            }
                          />
                        </Space>
                      </a>
                    </Dropdown>
                    </div>
                  </div>
                </div>
                <Drawer width={1000} title="Broker Messaging" onClose={() =>setShowChat(false)} open={showChat}>
                <Tabs  onChange={handleTabChange}   activeKey={activeTab}  type="card">

                <TabPane tab="Dubai" key="1">
                {loading?<Skeleton
    avatar
    active={true}
    paragraph={{
      rows: 4,
      width:300
    }}
  />:
        <>
        {dubaiMessages.length < 1 ?   <div
     style={{
       display: "flex",
       flexDirection: "column",
       alignItems: "center",
       justifyContent: "center",
       height: "70vh",
       textAlign: "center",
       color: "#6c757d",
     }}
   >
     <svg
       xmlns="http://www.w3.org/2000/svg"
       width="64"
       height="64"
       fill="currentColor"
       viewBox="0 0 16 16"
       style={{ marginBottom: "16px" }}
     >
       <path d="M4.5 11a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7Zm-3-1a2.5 2.5 0 0 1-2.5-2.5V5A2.5 2.5 0 0 1 1.5 2.5h11A2.5 2.5 0 0 1 15.5 5v2.5A2.5 2.5 0 0 1 13 10.5h-2.5L6.5 14v-3.5H1.5ZM6 6.5a.5.5 0 1 0-1 0 .5.5 0 0 0 1 0Zm3 0a.5.5 0 1 0-1 0 .5.5 0 0 0 1 0Zm3 0a.5.5 0 1 0-1 0 .5.5 0 0 0 1 0Z" />
     </svg>
     <h2 style={{ margin: 0, fontSize: "1.5rem" }}>No Messages Yet</h2>
     <p style={{ margin: "8px 0 0", fontSize: "1rem" }}>
       Start a conversation to see messages here.
     </p>
   </div>:""}
        {dubaiMessages.map((msg, index) => {

        const isRepliesOpen = openReplies[index] || false;
  const isSender = msg.sender_id === user.id; // Check if the message is from the logged-in user

        return (
          <div style={{ paddingBottom: '5px' }} key={index}>
            <div
      style={{
        display: 'flex',
        justifyContent: isSender ? 'flex-start' : 'flex-end', // Align right for sender, left for receiver
        width: '100%', // Take full width of the parent container
        paddingBottom: '5px',
        marginBottom: '5px', // Add space between each message
      }}
      key={index}
    >
      <div
        style={{
          padding: '12px 16px', // Increased padding for larger bubbles
          borderRadius: '10px',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: isSender ?  '#d0e7ff': '#f1f1f1' , // Different background color for sender and receiver
          color: '#000',
          width: '90%', // Set a larger width for the message bubble (now 80% of the container)
          maxWidth: '900px', // Optional: You can cap the width to 600px for larger screen sizes
          alignItems: 'flex-start', // Align text inside the bubble
          wordWrap: 'break-word', // Prevent long text from overflowing
        }}
      >
              {/* Sender Name and Profile Image */}
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                {msg.sender && msg.sender.profile ? (
                  <img
                    src={baseurlImg + '/uploads/profiles/' + msg.sender.profile}
                    alt={msg.sender.name}
                    style={{
                      width: '30px',
                      height: '30px',
                      borderRadius: '50%',
                      marginRight: '8px',
                    }}
                  />
                ) : (
                  <div
                    style={{
                      width: '30px',
                      height: '30px',
                      borderRadius: '50%',
                      backgroundColor: '#ccc',
                      marginRight: '8px',
                    }}
                  />
                )}
                
                {msg.sender.id != user.id?
                <a onClick={() =>handleChatCurrentUser(msg.sender)} style={{ fontWeight: 'bold' }}>

                  {msg.sender?.name}
                  </a>:msg.sender?.name}
              </div>

              {/* Message content */}
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {msg.message}
                {msg?.audioFilename? <div style={{ marginLeft: '10px' }}>
          <audio controls>
            <source src={baseurlImg2 + '/uploads/audios/'+msg?.audioFilename} type="audio/webm" />
        
          </audio>
        </div>:null}
                <div>
          {msg?.imageNames?.length > 0 && (
  <div style={{ width: '100%', maxWidth: '300px', margin: '0 auto', paddingTop: '10px' }}>
    <Carousel  prevArrow={<div style={arrowStyle}>{'<'}</div>} // Custom previous arrow
      nextArrow={<div style={arrowStyle}>{'>'}</div>} arrows  dotPosition="bottom">
      {msg.imageNames.map((img, indexImage) => (
        <div key={indexImage}>
          <Image
            src={baseurlImg2 + '/uploads/properties/' + img}
            alt={`Image ${indexImage}`}
            style={{
              width: '100%', // Full width of the parent container
              height: '200px', // Set a consistent height
              objectFit: 'cover', // Ensure the image fits without distortion
              borderRadius: '8px', // Optional: Rounded corners
            }}
          />
        </div>
      ))}
    </Carousel>
  </div>
)}
          </div>
              </div>

              {/* Message Timestamp (date) */}
              <span
                style={{
                  marginTop: '4px',
                  fontSize: '12px',
                  color: '#888',
                }}
              >
                {msg.date}
              </span>
            </div>
            </div>

            {/* Replies Section */}
            {msg.replies.length > 0 && (
              <>
                <div
                  style={{
                    marginTop: '5px',
                    marginLeft: '40px',
                    cursor: 'pointer',
                    color: '#007bff',
                    display: 'flex',
                    justifyContent: isSender ? 'flex-end' : 'flex-start',
                    textDecoration: 'underline',
                  }}
                  onClick={() => toggleReplies(index)}
                >
                  {isRepliesOpen
                    ? `Hide Replies (${msg.replies.length})`
                    : `Show Replies (${msg.replies.length})`}
                </div>

                {isRepliesOpen && (
                  <div style={{ marginLeft: '40px' }}>
                    {msg.replies.map((reply, indexReply) => (
                      <div
                        key={indexReply}
                        style={{
                          marginBottom: '10px',
                          display: 'flex',
                          flexDirection:'column',
                          justifyContent: isSender ? 'flex-end' : 'flex-start',
                          marginLeft: '40px',
                          padding: '8px 12px',
                          backgroundColor: '#e1f7d5', // Lighter green for replies
                          color: '#000',
                          borderRadius: '10px',
                          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                        }}
                      >
                        {/* Replier Name and Profile */}
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                          {reply.replier && reply.replier.profile ? (
                            <img
                              src={baseurlImg + '/uploads/profiles/' + reply.replier.profile}
                              alt={reply.replier.name}
                              style={{
                                width: '30px',
                                height: '30px',
                                borderRadius: '50%',
                                marginRight: '8px',
                              }}
                            />
                          ) : (
                            <div
                              style={{
                                width: '30px',
                                height: '30px',
                                borderRadius: '50%',
                                backgroundColor: '#ccc',
                                marginRight: '8px',
                              }}
                            />
                          )}
                       
                    
                      {reply.replier.id != user.id?
                <a onClick={() =>handleChatCurrentUser(reply.replier)} style={{ fontWeight: 'bold' }}>

                   {reply.replier?.name}
                  </a>: reply.replier?.name}
                        </div>

                        {/* Reply Content */}
                        <div style={{display:'flex',flexDirection:'column'}}>
                        <p>{reply.comment}</p>
                        {reply?.audioFilename? <div style={{ marginLeft: '10px' }}>
          <audio controls>
            <source src={baseurlImg2 + '/uploads/audios/'+reply?.audioFilename} type="audio/webm" />
        
          </audio>
        </div>:null}
                        <div >
          {reply?.imageNames?.length > 0 && (
  <div style={{ width: '100%', maxWidth: '300px', margin: '0 auto', paddingTop: '10px' }}>
    <Carousel  prevArrow={<div style={arrowStyle}>{'<'}</div>} // Custom previous arrow
      nextArrow={<div style={arrowStyle}>{'>'}</div>} arrows  dotPosition="bottom">
      {reply.imageNames.map((img, indexImage) => (
        <div key={indexImage}>
          <Image
            src={baseurlImg2 + '/uploads/properties/' + img}
            alt={`Image ${indexImage}`}
            style={{
              width: '100%', // Full width of the parent container
              height: '200px', // Set a consistent height
              objectFit: 'cover', // Ensure the image fits without distortion
              borderRadius: '8px', // Optional: Rounded corners
            }}
          />
        </div>
      ))}
    </Carousel>
  </div>
)}
          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </>
            )}

            <div  style={{
        display: 'flex',
        justifyContent: isSender ? 'flex-end' : 'flex-start', // Align right for sender, left for receiver
        width: '100%', // Take full width of the parent container
        paddingBottom: '5px',
        marginBottom: '50px', // Add space between each message
      }}>
            <ReplyInput
          index={0}
          onSend={(message, files,audioURL) => {handleReply(message,files,audioURL,msg.id)}}
          onAttach={(file) => console.log('File attached:', file)}
            />
            </div>
          </div>
        );
      })}
      </>}
   
      </TabPane>
      <TabPane tab="Abu dhabi" key="2">
      {loading?<Skeleton
    avatar
    active={true}
    paragraph={{
      rows: 4,
      width:300
    }}
  />:
        <>
        {abudhabiMessages.length < 1 ?
     <div
     style={{
       display: "flex",
       flexDirection: "column",
       alignItems: "center",
       justifyContent: "center",
       height: "70vh",
       textAlign: "center",
       color: "#6c757d",
     }}
   >
     <svg
       xmlns="http://www.w3.org/2000/svg"
       width="64"
       height="64"
       fill="currentColor"
       viewBox="0 0 16 16"
       style={{ marginBottom: "16px" }}
     >
       <path d="M4.5 11a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7Zm-3-1a2.5 2.5 0 0 1-2.5-2.5V5A2.5 2.5 0 0 1 1.5 2.5h11A2.5 2.5 0 0 1 15.5 5v2.5A2.5 2.5 0 0 1 13 10.5h-2.5L6.5 14v-3.5H1.5ZM6 6.5a.5.5 0 1 0-1 0 .5.5 0 0 0 1 0Zm3 0a.5.5 0 1 0-1 0 .5.5 0 0 0 1 0Zm3 0a.5.5 0 1 0-1 0 .5.5 0 0 0 1 0Z" />
     </svg>
     <h2 style={{ margin: 0, fontSize: "1.5rem" }}>No Messages Yet</h2>
     <p style={{ margin: "8px 0 0", fontSize: "1rem" }}>
       Start a conversation to see messages here.
     </p>
   </div>
      :""}
      {abudhabiMessages.map((msg, index) => {

        const isRepliesOpen = openReplies[index] || false;
  const isSender = msg.sender_id === user.id; // Check if the message is from the logged-in user

        return (
          <div style={{ paddingBottom: '5px' }} key={index}>
               <div
      style={{
        display: 'flex',
        justifyContent: isSender ? 'flex-start' : 'flex-end', // Align right for sender, left for receiver
        width: '100%', // Take full width of the parent container
        paddingBottom: '5px',
        marginBottom: '5px', // Add space between each message
      }}
      key={index}
    >
      <div
        style={{
          padding: '12px 16px', // Increased padding for larger bubbles
          borderRadius: '10px',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: isSender ?  '#d0e7ff': '#f1f1f1' , // Different background color for sender and receiver
          color: '#000',
          width: '90%', // Set a larger width for the message bubble (now 80% of the container)
          maxWidth: '900px', // Optional: You can cap the width to 600px for larger screen sizes
          alignItems: 'flex-start', // Align text inside the bubble
          wordWrap: 'break-word', // Prevent long text from overflowing
        }}
      >
              {/* Sender Name and Profile Image */}
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                {msg.sender && msg.sender.profile ? (
                  <img
                    src={baseurlImg + '/uploads/profiles/' + msg.sender.profile}
                    alt={msg.sender.name}
                    style={{
                      width: '30px',
                      height: '30px',
                      borderRadius: '50%',
                      marginRight: '8px',
                    }}
                  />
                ) : (
                  <div
                    style={{
                      width: '30px',
                      height: '30px',
                      borderRadius: '50%',
                      backgroundColor: '#ccc',
                      marginRight: '8px',
                    }}
                  />
                )}
                    {msg.sender.id != user.id?
                <a onClick={() =>handleChatCurrentUser(msg.sender)} style={{ fontWeight: 'bold' }}>

                   {msg.sender?.name}
                  </a>: msg.sender?.name}


             
              </div>

              {/* Message content */}
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {msg.message}
                {msg?.audioFilename? <div style={{ marginLeft: '10px' }}>
          <audio controls>
            <source src={baseurlImg2 + '/uploads/audios/'+msg?.audioFilename} type="audio/webm" />
        
          </audio>
        </div>:null}
                <div>
          {msg?.imageNames?.length > 0 && (
  <div style={{ width: '100%', maxWidth: '300px', margin: '0 auto', paddingTop: '10px' }}>
    <Carousel  prevArrow={<div style={arrowStyle}>{'<'}</div>} // Custom previous arrow
      nextArrow={<div style={arrowStyle}>{'>'}</div>} arrows  dotPosition="bottom">
      {msg.imageNames.map((img, indexImage) => (
        <div key={indexImage}>
          <Image
            src={baseurlImg2 + '/uploads/properties/' + img}
            alt={`Image ${indexImage}`}
            style={{
              width: '100%', // Full width of the parent container
              height: '200px', // Set a consistent height
              objectFit: 'cover', // Ensure the image fits without distortion
              borderRadius: '8px', // Optional: Rounded corners
            }}
          />
        </div>
      ))}
    </Carousel>
  </div>
)}
          </div>
              </div>

              {/* Message Timestamp (date) */}
              <span
                style={{
                  marginTop: '4px',
                  fontSize: '12px',
                  color: '#888',
                }}
              >
                {msg.date}
              </span>
            </div>
            </div>

            {/* Replies Section */}
            {msg.replies.length > 0 && (
              <>
                <div
                  style={{
                    marginTop: '5px',
                    marginLeft: '40px',
                    cursor: 'pointer',
                    color: '#007bff',
                    display: 'flex',
                    justifyContent: isSender ? 'flex-end' : 'flex-start',
                    textDecoration: 'underline',
                  }}
                  onClick={() => toggleReplies(index)}
                >
                  {isRepliesOpen
                    ? `Hide Replies (${msg.replies.length})`
                    : `Show Replies (${msg.replies.length})`}
                </div>

                {isRepliesOpen && (
                  <div style={{ marginLeft: '40px' }}>
                    {msg.replies.map((reply, indexReply) => (
                      <div
                        key={indexReply}
                        style={{
                          marginBottom: '10px',
                          display: 'flex',
                          flexDirection:'column',
                          justifyContent: isSender ? 'flex-end' : 'flex-start',
                          marginLeft: '40px',
                          padding: '8px 12px',
                          backgroundColor: '#e1f7d5', // Lighter green for replies
                          color: '#000',
                          borderRadius: '10px',
                          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                        }}
                      >
                        {/* Replier Name and Profile */}
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                          {reply.replier && reply.replier.profile ? (
                            <img
                              src={baseurlImg + '/uploads/profiles/' + reply.replier.profile}
                              alt={reply.replier.name}
                              style={{
                                width: '30px',
                                height: '30px',
                                borderRadius: '50%',
                                marginRight: '8px',
                              }}
                            />
                          ) : (
                            <div
                              style={{
                                width: '30px',
                                height: '30px',
                                borderRadius: '50%',
                                backgroundColor: '#ccc',
                                marginRight: '8px',
                              }}
                            />
                          )}
                       
                       {reply.replier.id != user.id?
                <a onClick={() =>handleChatCurrentUser(reply.replier)} style={{ fontWeight: 'bold' }}>

                   {reply.replier?.name}
                  </a>: reply.replier?.name}
                           
                        </div>

                        {/* Reply Content */}
                        <div style={{display:'flex',flexDirection:'column'}}>
                        <p>{reply.comment}</p>
                        {reply?.audioFilename? <div style={{ marginLeft: '10px' }}>
          <audio controls>
            <source src={baseurlImg2 + '/uploads/audios/'+reply?.audioFilename} type="audio/webm" />
        
          </audio>
        </div>:null}
                        <div >
          {reply?.imageNames?.length > 0 && (
  <div style={{ width: '100%', maxWidth: '300px', margin: '0 auto', paddingTop: '10px' }}>
    <Carousel  prevArrow={<div style={arrowStyle}>{'<'}</div>} // Custom previous arrow
      nextArrow={<div style={arrowStyle}>{'>'}</div>} arrows  dotPosition="bottom">
      {reply.imageNames.map((img, indexImage) => (
        <div key={indexImage}>
          <Image
            src={baseurlImg2 + '/uploads/properties/' + img}
            alt={`Image ${indexImage}`}
            style={{
              width: '100%', // Full width of the parent container
              height: '200px', // Set a consistent height
              objectFit: 'cover', // Ensure the image fits without distortion
              borderRadius: '8px', // Optional: Rounded corners
            }}
          />
        </div>
      ))}
    </Carousel>
  </div>
)}
          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </>
            )}

            <div  style={{
        display: 'flex',
        justifyContent: isSender ? 'flex-end' : 'flex-start', // Align right for sender, left for receiver
        width: '100%', // Take full width of the parent container
        paddingBottom: '5px',
        marginBottom: '50px', // Add space between each message
      }}>
            <ReplyInput
          index={0}
          onSend={(message, files,audioURL) => {handleReply(message,files,audioURL,msg.id)}}
          onAttach={(file) => console.log('File attached:', file)}
            />
            </div>
          </div>
        );
      })}
      </>}
      
     
      </TabPane>
      <TabPane tab="Sharjah" key="3">
      {loading?<Skeleton
    avatar
    active={true}
    paragraph={{
      rows: 4,
      width:300
    }}
  />:
        <>
      {sharjahMessages.length < 1 ?   <div
     style={{
       display: "flex",
       flexDirection: "column",
       alignItems: "center",
       justifyContent: "center",
       height: "70vh",
       textAlign: "center",
       color: "#6c757d",
     }}
   >
     <svg
       xmlns="http://www.w3.org/2000/svg"
       width="64"
       height="64"
       fill="currentColor"
       viewBox="0 0 16 16"
       style={{ marginBottom: "16px" }}
     >
       <path d="M4.5 11a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7Zm-3-1a2.5 2.5 0 0 1-2.5-2.5V5A2.5 2.5 0 0 1 1.5 2.5h11A2.5 2.5 0 0 1 15.5 5v2.5A2.5 2.5 0 0 1 13 10.5h-2.5L6.5 14v-3.5H1.5ZM6 6.5a.5.5 0 1 0-1 0 .5.5 0 0 0 1 0Zm3 0a.5.5 0 1 0-1 0 .5.5 0 0 0 1 0Zm3 0a.5.5 0 1 0-1 0 .5.5 0 0 0 1 0Z" />
     </svg>
     <h2 style={{ margin: 0, fontSize: "1.5rem" }}>No Messages Yet</h2>
     <p style={{ margin: "8px 0 0", fontSize: "1rem" }}>
       Start a conversation to see messages here.
     </p>
   </div>:""}

      {sharjahMessages.map((msg, index) => {

        const isRepliesOpen = openReplies[index] || false;
  const isSender = msg.sender_id === user.id; // Check if the message is from the logged-in user

        return (
          <div style={{ paddingBottom: '5px' }} key={index}>
               <div
      style={{
        display: 'flex',
        justifyContent: isSender ? 'flex-start' : 'flex-end', // Align right for sender, left for receiver
        width: '100%', // Take full width of the parent container
        paddingBottom: '5px',
        marginBottom: '5px', // Add space between each message
      }}
      key={index}
    >
      <div
        style={{
          padding: '12px 16px', // Increased padding for larger bubbles
          borderRadius: '10px',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: isSender ?  '#d0e7ff': '#f1f1f1' , // Different background color for sender and receiver
          color: '#000',
          width: '90%', // Set a larger width for the message bubble (now 80% of the container)
          maxWidth: '900px', // Optional: You can cap the width to 600px for larger screen sizes
          alignItems: 'flex-start', // Align text inside the bubble
          wordWrap: 'break-word', // Prevent long text from overflowing
        }}
      >
              {/* Sender Name and Profile Image */}
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                {msg.sender && msg.sender.profile ? (
                  <img
                    src={baseurlImg + '/uploads/profiles/' + msg.sender.profile}
                    alt={msg.sender.name}
                    style={{
                      width: '30px',
                      height: '30px',
                      borderRadius: '50%',
                      marginRight: '8px',
                    }}
                  />
                ) : (
                  <div
                    style={{
                      width: '30px',
                      height: '30px',
                      borderRadius: '50%',
                      backgroundColor: '#ccc',
                      marginRight: '8px',
                    }}
                  />
                )}
                  {msg.sender.id != user.id?
                <a onClick={() =>handleChatCurrentUser(msg.sender)} style={{ fontWeight: 'bold' }}>

                   {msg.sender?.name}
                  </a>: msg.sender?.name}
              </div>

              {/* Message content */}
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {msg.message}
                {msg?.audioFilename? <div style={{ marginLeft: '10px' }}>
          <audio controls>
            <source src={baseurlImg2 + '/uploads/audios/'+msg?.audioFilename} type="audio/webm" />
        
          </audio>
        </div>:null}
                <div>
                    {msg?.imageNames?.length > 0 && (
            <div style={{ width: '100%', maxWidth: '300px', margin: '0 auto', paddingTop: '10px' }}>
              <Carousel  prevArrow={<div style={arrowStyle}>{'<'}</div>} // Custom previous arrow
                nextArrow={<div style={arrowStyle}>{'>'}</div>} arrows  dotPosition="bottom">
                {msg.imageNames.map((img, indexImage) => (
                  <div key={indexImage}>
                    <Image
                      src={baseurlImg2 + '/uploads/properties/' + img}
                      alt={`Image ${indexImage}`}
                      style={{
                        width: '100%', // Full width of the parent container
                        height: '200px', // Set a consistent height
                        objectFit: 'cover', // Ensure the image fits without distortion
                        borderRadius: '8px', // Optional: Rounded corners
                      }}
                    />
                  </div>
                ))}
              </Carousel>
            </div>
          )}
                    </div>
              </div>

              {/* Message Timestamp (date) */}
              <span
                style={{
                  marginTop: '4px',
                  fontSize: '12px',
                  color: '#888',
                }}
              >
                {msg.date}
              </span>
            </div>
            </div>

            {/* Replies Section */}
            {msg.replies.length > 0 && (
              <>
                <div
                  style={{
                    marginTop: '5px',
                    marginLeft: '40px',
                    cursor: 'pointer',
                    color: '#007bff',
                    display: 'flex',
                    justifyContent: isSender ? 'flex-end' : 'flex-start',
                    textDecoration: 'underline',
                  }}
                  onClick={() => toggleReplies(index)}
                >
                  {isRepliesOpen
                    ? `Hide Replies (${msg.replies.length})`
                    : `Show Replies (${msg.replies.length})`}
                </div>

                {isRepliesOpen && (
                  <div style={{ marginLeft: '40px' }}>
                    {msg.replies.map((reply, indexReply) => (
                      <div
                        key={indexReply}
                        style={{
                          marginBottom: '10px',
                          display: 'flex',
                          flexDirection:'column',
                          justifyContent: isSender ? 'flex-end' : 'flex-start',
                          marginLeft: '40px',
                          padding: '8px 12px',
                          backgroundColor: '#e1f7d5', // Lighter green for replies
                          color: '#000',
                          borderRadius: '10px',
                          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                        }}
                      >
                        {/* Replier Name and Profile */}
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                          {reply.replier && reply.replier.profile ? (
                            <img
                              src={baseurlImg + '/uploads/profiles/' + reply.replier.profile}
                              alt={reply.replier.name}
                              style={{
                                width: '30px',
                                height: '30px',
                                borderRadius: '50%',
                                marginRight: '8px',
                              }}
                            />
                          ) : (
                            <div
                              style={{
                                width: '30px',
                                height: '30px',
                                borderRadius: '50%',
                                backgroundColor: '#ccc',
                                marginRight: '8px',
                              }}
                            />
                          )}
                       
                       {reply.replier.id != user.id?
                <a onClick={() =>handleChatCurrentUser(reply.replier)} style={{ fontWeight: 'bold' }}>

                   {reply.replier?.name}
                  </a>: reply.replier?.name}
                           
                        </div>

                        {/* Reply Content */}
                        <div style={{display:'flex',flexDirection:'column'}}>
                        <p>{reply.comment}</p>
                        {reply?.audioFilename? <div style={{ marginLeft: '10px' }}>
          <audio controls>
            <source src={baseurlImg2 + '/uploads/audios/'+reply?.audioFilename} type="audio/webm" />
        
          </audio>
        </div>:null}
                        <div >
          {reply?.imageNames?.length > 0 && (
  <div style={{ width: '100%', maxWidth: '300px', margin: '0 auto', paddingTop: '10px' }}>
    <Carousel  prevArrow={<div style={arrowStyle}>{'<'}</div>} // Custom previous arrow
      nextArrow={<div style={arrowStyle}>{'>'}</div>} arrows  dotPosition="bottom">
      {reply.imageNames.map((img, indexImage) => (
        <div key={indexImage}>
          <Image
            src={baseurlImg2 + '/uploads/properties/' + img}
            alt={`Image ${indexImage}`}
            style={{
              width: '100%', // Full width of the parent container
              height: '200px', // Set a consistent height
              objectFit: 'cover', // Ensure the image fits without distortion
              borderRadius: '8px', // Optional: Rounded corners
            }}
          />
        </div>
      ))}
    </Carousel>
  </div>
)}
          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </>
            )}

            <div  style={{
        display: 'flex',
        justifyContent: isSender ? 'flex-end' : 'flex-start', // Align right for sender, left for receiver
        width: '100%', // Take full width of the parent container
        paddingBottom: '5px',
        marginBottom: '50px', // Add space between each message
      }}>
            <ReplyInput
          index={0}
          onSend={(message, files,audioURL) => {handleReply(message,files,audioURL,msg.id)}}
          onAttach={(file) => console.log('File attached:', file)}
            />
            </div>
          </div>
        );
      })}
      </>}
 
      </TabPane>
      <TabPane tab="Ajman" key="4">
        {loading?<Skeleton
    avatar
    active={true}
    paragraph={{
      rows: 4,
      width:300
    }}
  />:
        <>
      {ajmanMessages.length < 1 ?   <div
     style={{
       display: "flex",
       flexDirection: "column",
       alignItems: "center",
       justifyContent: "center",
       height: "70vh",
       textAlign: "center",
       color: "#6c757d",
     }}
   >
     <svg
       xmlns="http://www.w3.org/2000/svg"
       width="64"
       height="64"
       fill="currentColor"
       viewBox="0 0 16 16"
       style={{ marginBottom: "16px" }}
     >
       <path d="M4.5 11a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7Zm-3-1a2.5 2.5 0 0 1-2.5-2.5V5A2.5 2.5 0 0 1 1.5 2.5h11A2.5 2.5 0 0 1 15.5 5v2.5A2.5 2.5 0 0 1 13 10.5h-2.5L6.5 14v-3.5H1.5ZM6 6.5a.5.5 0 1 0-1 0 .5.5 0 0 0 1 0Zm3 0a.5.5 0 1 0-1 0 .5.5 0 0 0 1 0Zm3 0a.5.5 0 1 0-1 0 .5.5 0 0 0 1 0Z" />
     </svg>
     <h2 style={{ margin: 0, fontSize: "1.5rem" }}>No Messages Yet</h2>
     <p style={{ margin: "8px 0 0", fontSize: "1rem" }}>
       Start a conversation to see messages here.
     </p>
   </div>:""}
      {ajmanMessages.map((msg, index) => {

        const isRepliesOpen = openReplies[index] || false;
  const isSender = msg.sender_id === user.id; // Check if the message is from the logged-in user

        return (
          <div style={{ paddingBottom: '5px' }} key={index}>
            <div
      style={{
        display: 'flex',
        justifyContent: isSender ? 'flex-start' : 'flex-end', // Align right for sender, left for receiver
        width: '100%', // Take full width of the parent container
        paddingBottom: '5px',
        marginBottom: '5px', // Add space between each message
      }}
      key={index}
    >
      <div
        style={{
          padding: '12px 16px', // Increased padding for larger bubbles
          borderRadius: '10px',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: isSender ?  '#d0e7ff': '#f1f1f1' , // Different background color for sender and receiver
          color: '#000',
          width: '90%', // Set a larger width for the message bubble (now 80% of the container)
          maxWidth: '900px', // Optional: You can cap the width to 600px for larger screen sizes
          alignItems: 'flex-start', // Align text inside the bubble
          wordWrap: 'break-word', // Prevent long text from overflowing
        }}
      >
              {/* Sender Name and Profile Image */}
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                {msg.sender && msg.sender.profile ? (
                  <img
                    src={baseurlImg + '/uploads/profiles/' + msg.sender.profile}
                    alt={msg.sender.name}
                    style={{
                      width: '30px',
                      height: '30px',
                      borderRadius: '50%',
                      marginRight: '8px',
                    }}
                  />
                ) : (
                  <div
                    style={{
                      width: '30px',
                      height: '30px',
                      borderRadius: '50%',
                      backgroundColor: '#ccc',
                      marginRight: '8px',
                    }}
                  />
                )}
                 {msg.sender.id != user.id?
                <a onClick={() =>handleChatCurrentUser(msg.sender)} style={{ fontWeight: 'bold' }}>

                   {msg.sender?.name}
                  </a>: msg.sender?.name}
              </div>

              {/* Message content */}
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {msg.message}
                {msg?.audioFilename? <div style={{ marginLeft: '10px' }}>
          <audio controls>
            <source src={baseurlImg2 + '/uploads/audios/'+msg?.audioFilename} type="audio/webm" />
        
          </audio>
        </div>:null}
        <div>
          {msg?.imageNames?.length > 0 && (
  <div style={{ width: '100%', maxWidth: '300px', margin: '0 auto', paddingTop: '10px' }}>
    <Carousel  prevArrow={<div style={arrowStyle}>{'<'}</div>} // Custom previous arrow
      nextArrow={<div style={arrowStyle}>{'>'}</div>} arrows  dotPosition="bottom">
      {msg.imageNames.map((img, indexImage) => (
        <div key={indexImage}>
          <Image
            src={baseurlImg2 + '/uploads/properties/' + img}
            alt={`Image ${indexImage}`}
            style={{
              width: '100%', // Full width of the parent container
              height: '200px', // Set a consistent height
              objectFit: 'cover', // Ensure the image fits without distortion
              borderRadius: '8px', // Optional: Rounded corners
            }}
          />
        </div>
      ))}
    </Carousel>
  </div>
)}
          </div>
              </div>

              {/* Message Timestamp (date) */}
              <span
                style={{
                  marginTop: '4px',
                  fontSize: '12px',
                  color: '#888',
                }}
              >
                {msg.date}
              </span>
            </div>
            </div>

            {/* Replies Section */}
            {msg.replies.length > 0 && (
              <>
                <div
                  style={{
                    marginTop: '5px',
                    marginLeft: '50px',
                    cursor: 'pointer',
                    color: '#007bff',
                    display: 'flex',
                    justifyContent: isSender ? 'flex-end' : 'flex-start',
                    textDecoration: 'underline',
                  }}
                  onClick={() => toggleReplies(index)}
                >
                  {isRepliesOpen
                    ? `Hide Replies (${msg.replies.length})`
                    : `Show Replies (${msg.replies.length})`}
                </div>

                {isRepliesOpen && (
                  <div style={{ marginLeft: '40px' }}>
                    {msg.replies.map((reply, indexReply) => (
                      <div
                        key={indexReply}
                        style={{
                          marginBottom: '10px',
                          display: 'flex',
                          flexDirection:'column',
                          justifyContent: isSender ? 'flex-end' : 'flex-start',
                          marginLeft: '80px',
                          padding: '8px 12px',
                          backgroundColor: '#e1f7d5', // Lighter green for replies
                          color: '#000',
                          borderRadius: '10px',
                          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                        }}
                      >
                        {/* Replier Name and Profile */}
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                          {reply.replier && reply.replier.profile ? (
                            <img
                              src={baseurlImg + '/uploads/profiles/' + reply.replier.profile}
                              alt={reply.replier.name}
                              style={{
                                width: '30px',
                                height: '30px',
                                borderRadius: '50%',
                                marginRight: '8px',
                              }}
                            />
                          ) : (
                            <div
                              style={{
                                width: '30px',
                                height: '30px',
                                borderRadius: '50%',
                                backgroundColor: '#ccc',
                                marginRight: '8px',
                              }}
                            />
                          )}
                       
                       {reply.replier.id != user.id?
                <a onClick={() =>handleChatCurrentUser(reply.replier)} style={{ fontWeight: 'bold' }}>

                   {reply.replier?.name}
                  </a>: reply.replier?.name}
                           
                        </div>

                        {/* Reply Content */}
                        <div style={{display:'flex',flexDirection:'column'}}>
                        <p>{reply.comment}</p>
                        {reply?.audioFilename? <div style={{ marginLeft: '10px' }}>
          <audio controls>
            <source src={baseurlImg2 + '/uploads/audios/'+reply?.audioFilename} type="audio/webm" />
        
          </audio>
        </div>:null}
                        <div >
          {reply?.imageNames?.length > 0 && (
  <div style={{ width: '100%', maxWidth: '300px', margin: '0 auto', paddingTop: '10px' }}>
    <Carousel  prevArrow={<div style={arrowStyle}>{'<'}</div>} // Custom previous arrow
      nextArrow={<div style={arrowStyle}>{'>'}</div>} arrows  dotPosition="bottom">
      {reply.imageNames.map((img, indexImage) => (
        <div key={indexImage}>
          <Image
            src={baseurlImg2 + '/uploads/properties/' + img}
            alt={`Image ${indexImage}`}
            style={{
              width: '100%', // Full width of the parent container
              height: '200px', // Set a consistent height
              objectFit: 'cover', // Ensure the image fits without distortion
              borderRadius: '8px', // Optional: Rounded corners
            }}
          />
        </div>
      ))}
    </Carousel>
  </div>
)}
          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </>
            )}

            <div  style={{
        display: 'flex',
        justifyContent: isSender ? 'flex-end' : 'flex-start', // Align right for sender, left for receiver
        width: '100%', // Take full width of the parent container
        paddingBottom: '5px',
        marginBottom: '50px', // Add space between each message
      }}>
            <ReplyInput
          index={0}
          onSend={(message, files,audioURL) => {handleReply(message,files,audioURL,msg.id)}}
          onAttach={(file) => console.log('File attached:', file)}
            />
            </div>
          </div>
        );
      })}
      </>}
  
      </TabPane>
      <TabPane tab="Rasalhaima" key="5">
      {loading?<Skeleton
    avatar
    active={true}
    paragraph={{
      rows: 4,
      width:300
    }}
  />:
        <>
      {rasalkhaimaMessages.length < 1 ?   <div
     style={{
       display: "flex",
       flexDirection: "column",
       alignItems: "center",
       justifyContent: "center",
       height: "70vh",
       textAlign: "center",
       color: "#6c757d",
     }}
   >
     <svg
       xmlns="http://www.w3.org/2000/svg"
       width="64"
       height="64"
       fill="currentColor"
       viewBox="0 0 16 16"
       style={{ marginBottom: "16px" }}
     >
       <path d="M4.5 11a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7Zm-3-1a2.5 2.5 0 0 1-2.5-2.5V5A2.5 2.5 0 0 1 1.5 2.5h11A2.5 2.5 0 0 1 15.5 5v2.5A2.5 2.5 0 0 1 13 10.5h-2.5L6.5 14v-3.5H1.5ZM6 6.5a.5.5 0 1 0-1 0 .5.5 0 0 0 1 0Zm3 0a.5.5 0 1 0-1 0 .5.5 0 0 0 1 0Zm3 0a.5.5 0 1 0-1 0 .5.5 0 0 0 1 0Z" />
     </svg>
     <h2 style={{ margin: 0, fontSize: "1.5rem" }}>No Messages Yet</h2>
     <p style={{ margin: "8px 0 0", fontSize: "1rem" }}>
       Start a conversation to see messages here.
     </p>
   </div>:""}

      {rasalkhaimaMessages.map((msg, index) => {

        const isRepliesOpen = openReplies[index] || false;
  const isSender = msg.sender_id === user.id; // Check if the message is from the logged-in user

        return (
          <div style={{ paddingBottom: '5px' }} key={index}>
             <div
      style={{
        display: 'flex',
        justifyContent: isSender ? 'flex-start' : 'flex-end', // Align right for sender, left for receiver
        width: '100%', // Take full width of the parent container
        paddingBottom: '5px',
        marginBottom: '5px', // Add space between each message
      }}
      key={index}
    >
      <div
        style={{
          padding: '12px 16px', // Increased padding for larger bubbles
          borderRadius: '10px',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: isSender ?  '#d0e7ff': '#f1f1f1' , // Different background color for sender and receiver
          color: '#000',
          width: '90%', // Set a larger width for the message bubble (now 80% of the container)
          maxWidth: '900px', // Optional: You can cap the width to 600px for larger screen sizes
          alignItems: 'flex-start', // Align text inside the bubble
          wordWrap: 'break-word', // Prevent long text from overflowing
        }}
      >
              {/* Sender Name and Profile Image */}
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                {msg.sender && msg.sender.profile ? (
                  <img
                    src={baseurlImg + '/uploads/profiles/' + msg.sender.profile}
                    alt={msg.sender.name}
                    style={{
                      width: '30px',
                      height: '30px',
                      borderRadius: '50%',
                      marginRight: '8px',
                    }}
                  />
                ) : (
                  <div
                    style={{
                      width: '30px',
                      height: '30px',
                      borderRadius: '50%',
                      backgroundColor: '#ccc',
                      marginRight: '8px',
                    }}
                  />
                )}
              {msg.sender.id != user.id?
                <a onClick={() =>handleChatCurrentUser(msg.sender)} style={{ fontWeight: 'bold' }}>

                   {msg.sender?.name}
                  </a>: msg.sender?.name}
              </div>

              {/* Message content */}
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {msg.message}
                {msg?.audioFilename? <div style={{ marginLeft: '10px' }}>
          <audio controls>
            <source src={baseurlImg2 + '/uploads/audios/'+msg?.audioFilename} type="audio/webm" />
        
          </audio>
        </div>:null}
        <div>
          {msg?.imageNames?.length > 0 && (
  <div style={{ width: '100%', maxWidth: '300px', margin: '0 auto', paddingTop: '10px' }}>
    <Carousel  prevArrow={<div style={arrowStyle}>{'<'}</div>} // Custom previous arrow
      nextArrow={<div style={arrowStyle}>{'>'}</div>} arrows  dotPosition="bottom">
      {msg.imageNames.map((img, indexImage) => (
        <div key={indexImage}>
          <Image
            src={baseurlImg2 + '/uploads/properties/' + img}
            alt={`Image ${indexImage}`}
            style={{
              width: '100%', // Full width of the parent container
              height: '200px', // Set a consistent height
              objectFit: 'cover', // Ensure the image fits without distortion
              borderRadius: '8px', // Optional: Rounded corners
            }}
          />
        </div>
      ))}
    </Carousel>
  </div>
)}
          </div>
              </div>
       

              {/* Message Timestamp (date) */}
              <span
                style={{
                  marginTop: '4px',
                  fontSize: '12px',
                  color: '#888',
                }}
              >
                {msg.date}
              </span>
            </div>
            </div>

            {/* Replies Section */}
            {msg.replies.length > 0 && (
              <>
                <div
                  style={{
                    marginTop: '5px',
                    marginLeft: '40px',
                    cursor: 'pointer',
                    color: '#007bff',
                    display: 'flex',
                    justifyContent: isSender ? 'flex-end' : 'flex-start',
                    textDecoration: 'underline',
                  }}
                  onClick={() => toggleReplies(index)}
                >
                  {isRepliesOpen
                    ? `Hide Replies (${msg.replies.length})`
                    : `Show Replies (${msg.replies.length})`}
                </div>

                {isRepliesOpen && (
                  <div style={{ marginLeft: '40px' }}>
                    {msg.replies.map((reply, indexReply) => (
                      <div
                        key={indexReply}
                        style={{
                          marginBottom: '10px',
                          display: 'flex',
                          flexDirection:'column',
                          justifyContent: isSender ? 'flex-end' : 'flex-start',
                          marginLeft: '40px',
                          padding: '8px 12px',
                          backgroundColor: '#e1f7d5', // Lighter green for replies
                          color: '#000',
                          borderRadius: '10px',
                          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                        }}
                      >
                        {/* Replier Name and Profile */}
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                          {reply.replier && reply.replier.profile ? (
                            <img
                              src={baseurlImg + '/uploads/profiles/' + reply.replier.profile}
                              alt={reply.replier.name}
                              style={{
                                width: '30px',
                                height: '30px',
                                borderRadius: '50%',
                                marginRight: '8px',
                              }}
                            />
                          ) : (
                            <div
                              style={{
                                width: '30px',
                                height: '30px',
                                borderRadius: '50%',
                                backgroundColor: '#ccc',
                                marginRight: '8px',
                              }}
                            />
                          )}
                       
                       {reply.replier.id != user.id?
                <a onClick={() =>handleChatCurrentUser(reply.replier)} style={{ fontWeight: 'bold' }}>

                   {reply.replier?.name}
                  </a>: reply.replier?.name}
                           
                        </div>

                        {/* Reply Content */}
                        <div style={{display:'flex',flexDirection:'column'}}>
                        <p>{reply.comment}</p>
                        {reply?.audioFilename? <div style={{ marginLeft: '10px' }}>
          <audio controls>
            <source src={baseurlImg2 + '/uploads/audios/'+reply?.audioFilename} type="audio/webm" />
        
          </audio>
        </div>:null}
                        <div >
          {reply?.imageNames?.length > 0 && (
  <div style={{ width: '100%', maxWidth: '300px', margin: '0 auto', paddingTop: '10px' }}>
    <Carousel  prevArrow={<div style={arrowStyle}>{'<'}</div>} // Custom previous arrow
      nextArrow={<div style={arrowStyle}>{'>'}</div>} arrows  dotPosition="bottom">
      {reply.imageNames.map((img, indexImage) => (
        <div key={indexImage}>
          <Image
            src={baseurlImg2 + '/uploads/properties/' + img}
            alt={`Image ${indexImage}`}
            style={{
              width: '100%', // Full width of the parent container
              height: '200px', // Set a consistent height
              objectFit: 'cover', // Ensure the image fits without distortion
              borderRadius: '8px', // Optional: Rounded corners
            }}
          />
        </div>
      ))}
    </Carousel>
  </div>
)}
          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </>
            )}

            <div  style={{
        display: 'flex',
        justifyContent: isSender ? 'flex-end' : 'flex-start', // Align right for sender, left for receiver
        width: '100%', // Take full width of the parent container
        paddingBottom: '5px',
        marginBottom: '50px', // Add space between each message
      }}>
            <ReplyInput
          index={0}
          onSend={(message, files,audioURL) => {handleReply(message,files,audioURL,msg.id)}}
          onAttach={(file) => console.log('File attached:', file)}
            />
            </div>
          </div>
        );
      })}
      </>}
 
      </TabPane>
      <TabPane tab="Umalqween" key="6">
      {loading?<Skeleton
    avatar
    active={true}
    paragraph={{
      rows: 4,
      width:300
    }}
  />:
        <>
      {umalqweenMessages.length < 1 ?   <div
     style={{
       display: "flex",
       flexDirection: "column",
       alignItems: "center",
       justifyContent: "center",
       height: "70vh",
       textAlign: "center",
       color: "#6c757d",
     }}
   >
     <svg
       xmlns="http://www.w3.org/2000/svg"
       width="64"
       height="64"
       fill="currentColor"
       viewBox="0 0 16 16"
       style={{ marginBottom: "16px" }}
     >
       <path d="M4.5 11a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7Zm-3-1a2.5 2.5 0 0 1-2.5-2.5V5A2.5 2.5 0 0 1 1.5 2.5h11A2.5 2.5 0 0 1 15.5 5v2.5A2.5 2.5 0 0 1 13 10.5h-2.5L6.5 14v-3.5H1.5ZM6 6.5a.5.5 0 1 0-1 0 .5.5 0 0 0 1 0Zm3 0a.5.5 0 1 0-1 0 .5.5 0 0 0 1 0Zm3 0a.5.5 0 1 0-1 0 .5.5 0 0 0 1 0Z" />
     </svg>
     <h2 style={{ margin: 0, fontSize: "1.5rem" }}>No Messages Yet</h2>
     <p style={{ margin: "8px 0 0", fontSize: "1rem" }}>
       Start a conversation to see messages here.
     </p>
   </div>:""}
      {umalqweenMessages.map((msg, index) => {

        const isRepliesOpen = openReplies[index] || false;
  const isSender = msg.sender_id === user.id; // Check if the message is from the logged-in user

        return (
          <div style={{ paddingBottom: '5px' }} key={index}>
                   <div
      style={{
        display: 'flex',
        justifyContent: isSender ? 'flex-start' : 'flex-end', // Align right for sender, left for receiver
        width: '100%', // Take full width of the parent container
        paddingBottom: '5px',
        marginBottom: '5px', // Add space between each message
      }}
      key={index}
    >
      <div
        style={{
          padding: '12px 16px', // Increased padding for larger bubbles
          borderRadius: '10px',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: isSender ?  '#d0e7ff': '#f1f1f1' , // Different background color for sender and receiver
          color: '#000',
          width: '90%', // Set a larger width for the message bubble (now 80% of the container)
          maxWidth: '900px', // Optional: You can cap the width to 600px for larger screen sizes
          alignItems: 'flex-start', // Align text inside the bubble
          wordWrap: 'break-word', // Prevent long text from overflowing
        }}
      >
              {/* Sender Name and Profile Image */}
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                {msg.sender && msg.sender.profile ? (
                  <img
                    src={baseurlImg + '/uploads/profiles/' + msg.sender.profile}
                    alt={msg.sender.name}
                    style={{
                      width: '30px',
                      height: '30px',
                      borderRadius: '50%',
                      marginRight: '8px',
                    }}
                  />
                ) : (
                  <div
                    style={{
                      width: '30px',
                      height: '30px',
                      borderRadius: '50%',
                      backgroundColor: '#ccc',
                      marginRight: '8px',
                    }}
                  />
                )}
              {msg.sender.id != user.id?
                <a onClick={() =>handleChatCurrentUser(msg.sender)} style={{ fontWeight: 'bold' }}>

                   {msg.sender?.name}
                  </a>: msg.sender?.name}
              </div>

              {/* Message content */}
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {msg.message}
                {msg?.audioFilename? <div style={{ marginLeft: '10px' }}>
          <audio controls>
            <source src={baseurlImg2 + '/uploads/audios/'+msg?.audioFilename} type="audio/webm" />
        
          </audio>
        </div>:null}
        <div>
          {msg?.imageNames?.length > 0 && (
  <div style={{ width: '100%', maxWidth: '300px', margin: '0 auto', paddingTop: '10px' }}>
    <Carousel  prevArrow={<div style={arrowStyle}>{'<'}</div>} // Custom previous arrow
      nextArrow={<div style={arrowStyle}>{'>'}</div>} arrows  dotPosition="bottom">
      {msg.imageNames.map((img, indexImage) => (
        <div key={indexImage}>
          <Image
            src={baseurlImg2 + '/uploads/properties/' + img}
            alt={`Image ${indexImage}`}
            style={{
              width: '100%', // Full width of the parent container
              height: '200px', // Set a consistent height
              objectFit: 'cover', // Ensure the image fits without distortion
              borderRadius: '8px', // Optional: Rounded corners
            }}
          />
        </div>
      ))}
    </Carousel>
  </div>
)}
          </div>
              </div>

              {/* Message Timestamp (date) */}
              <span
                style={{
                  marginTop: '4px',
                  fontSize: '12px',
                  color: '#888',
                }}
              >
                {msg.date}
              </span>
            </div>
            </div>

            {/* Replies Section */}
            {msg.replies.length > 0 && (
              <>
                <div
                  style={{
                    marginTop: '5px',
                    marginLeft: '40px',
                    cursor: 'pointer',
                    color: '#007bff',
                    display: 'flex',
                    justifyContent: isSender ? 'flex-end' : 'flex-start',
                    textDecoration: 'underline',
                  }}
                  onClick={() => toggleReplies(index)}
                >
                  {isRepliesOpen
                    ? `Hide Replies (${msg.replies.length})`
                    : `Show Replies (${msg.replies.length})`}
                </div>

                {isRepliesOpen && (
                  <div style={{ marginLeft: '40px' }}>
                    {msg.replies.map((reply, indexReply) => (
                      <div
                        key={indexReply}
                        style={{
                          marginBottom: '10px',
                          display: 'flex',
                          flexDirection:'column',
                          justifyContent: isSender ? 'flex-end' : 'flex-start',
                          marginLeft: '40px',
                          padding: '8px 12px',
                          backgroundColor: '#e1f7d5', // Lighter green for replies
                          color: '#000',
                          borderRadius: '10px',
                          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                        }}
                      >
                        {/* Replier Name and Profile */}
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                          {reply.replier && reply.replier.profile ? (
                            <img
                              src={baseurlImg + '/uploads/profiles/' + reply.replier.profile}
                              alt={reply.replier.name}
                              style={{
                                width: '30px',
                                height: '30px',
                                borderRadius: '50%',
                                marginRight: '8px',
                              }}
                            />
                          ) : (
                            <div
                              style={{
                                width: '30px',
                                height: '30px',
                                borderRadius: '50%',
                                backgroundColor: '#ccc',
                                marginRight: '8px',
                              }}
                            />
                          )}
                       
                       {reply.replier.id != user.id?
                <a onClick={() =>handleChatCurrentUser(reply.replier)} style={{ fontWeight: 'bold' }}>

                   {reply.replier?.name}
                  </a>: reply.replier?.name}
                           
                        </div>

                        {/* Reply Content */}
                        <div style={{display:'flex',flexDirection:'column'}}>
                        <p>{reply.comment}</p>
                        {reply?.audioFilename? <div style={{ marginLeft: '10px' }}>
          <audio controls>
            <source src={baseurlImg2 + '/uploads/audios/'+reply?.audioFilename} type="audio/webm" />
        
          </audio>
        </div>:null}
                        <div >
          {reply?.imageNames?.length > 0 && (
  <div style={{ width: '100%', maxWidth: '300px', margin: '0 auto', paddingTop: '10px' }}>
    <Carousel  prevArrow={<div style={arrowStyle}>{'<'}</div>} // Custom previous arrow
      nextArrow={<div style={arrowStyle}>{'>'}</div>} arrows  dotPosition="bottom">
      {reply.imageNames.map((img, indexImage) => (
        <div key={indexImage}>
          <Image
            src={baseurlImg2 + '/uploads/properties/' + img}
            alt={`Image ${indexImage}`}
            style={{
              width: '100%', // Full width of the parent container
              height: '200px', // Set a consistent height
              objectFit: 'cover', // Ensure the image fits without distortion
              borderRadius: '8px', // Optional: Rounded corners
            }}
          />
        </div>
      ))}
    </Carousel>
  </div>
)}
          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </>
            )}

            <div  style={{
        display: 'flex',
        justifyContent: isSender ? 'flex-end' : 'flex-start', // Align right for sender, left for receiver
        width: '100%', // Take full width of the parent container
        paddingBottom: '5px',
        marginBottom: '50px', // Add space between each message
      }}>
            <ReplyInput
          index={0}
          onSend={(message, files,audioURL) => {handleReply(message,files,audioURL,msg.id)}}
          onAttach={(file) => console.log('File attached:', file)}
            />
            </div>
          </div>
        );
      })}
      </>}
 
      </TabPane>
      <TabPane tab="Fujairah" key="7">
      {loading?<Skeleton
    avatar
    active={true}
    paragraph={{
      rows: 4,
      width:300
    }}
  />:
        <>
      {fujairahMessages.length < 1 ?   <div
     style={{
       display: "flex",
       flexDirection: "column",
       alignItems: "center",
       justifyContent: "center",
       height: "70vh",
       textAlign: "center",
       color: "#6c757d",
     }}
   >
     <svg
       xmlns="http://www.w3.org/2000/svg"
       width="64"
       height="64"
       fill="currentColor"
       viewBox="0 0 16 16"
       style={{ marginBottom: "16px" }}
     >
       <path d="M4.5 11a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7Zm-3-1a2.5 2.5 0 0 1-2.5-2.5V5A2.5 2.5 0 0 1 1.5 2.5h11A2.5 2.5 0 0 1 15.5 5v2.5A2.5 2.5 0 0 1 13 10.5h-2.5L6.5 14v-3.5H1.5ZM6 6.5a.5.5 0 1 0-1 0 .5.5 0 0 0 1 0Zm3 0a.5.5 0 1 0-1 0 .5.5 0 0 0 1 0Zm3 0a.5.5 0 1 0-1 0 .5.5 0 0 0 1 0Z" />
     </svg>
     <h2 style={{ margin: 0, fontSize: "1.5rem" }}>No Messages Yet</h2>
     <p style={{ margin: "8px 0 0", fontSize: "1rem" }}>
       Start a conversation to see messages here.
     </p>
   </div>:""}
      {fujairahMessages.map((msg, index) => {

        const isRepliesOpen = openReplies[index] || false;
  const isSender = msg.sender_id === user.id; // Check if the message is from the logged-in user

        return (
          <div style={{ paddingBottom: '5px' }} key={index}>
              <div
      style={{
        display: 'flex',
        justifyContent: isSender ? 'flex-start' : 'flex-end', // Align right for sender, left for receiver
        width: '100%', // Take full width of the parent container
        paddingBottom: '5px',
        marginBottom: '5px', // Add space between each message
      }}
      key={index}
    >
      <div
        style={{
          padding: '12px 16px', // Increased padding for larger bubbles
          borderRadius: '10px',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: isSender ?  '#d0e7ff': '#f1f1f1' , // Different background color for sender and receiver
          color: '#000',
          width: '90%', // Set a larger width for the message bubble (now 80% of the container)
          maxWidth: '900px', // Optional: You can cap the width to 600px for larger screen sizes
          alignItems: 'flex-start', // Align text inside the bubble
          wordWrap: 'break-word', // Prevent long text from overflowing
        }}
      >
              {/* Sender Name and Profile Image */}
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                {msg.sender && msg.sender.profile ? (
                  <img
                    src={baseurlImg + '/uploads/profiles/' + msg.sender.profile}
                    alt={msg.sender.name}
                    style={{
                      width: '30px',
                      height: '30px',
                      borderRadius: '50%',
                      marginRight: '8px',
                    }}
                  />
                ) : (
                  <div
                    style={{
                      width: '30px',
                      height: '30px',
                      borderRadius: '50%',
                      backgroundColor: '#ccc',
                      marginRight: '8px',
                    }}
                  />
                )}
               {msg.sender.id != user.id?
                <a onClick={() =>handleChatCurrentUser(msg.sender)} style={{ fontWeight: 'bold' }}>

                   {msg.sender?.name}
                  </a>: msg.sender?.name}
              </div>

              {/* Message content */}
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {msg.message}
                {msg?.audioFilename? <div style={{ marginLeft: '10px' }}>
          <audio controls>
            <source src={baseurlImg2 + '/uploads/audios/'+msg?.audioFilename} type="audio/webm" />
        
          </audio>
        </div>:null}
        <div>
          {msg?.imageNames?.length > 0 && (
  <div style={{ width: '100%', maxWidth: '300px', margin: '0 auto', paddingTop: '10px' }}>
    <Carousel  prevArrow={<div style={arrowStyle}>{'<'}</div>} // Custom previous arrow
      nextArrow={<div style={arrowStyle}>{'>'}</div>} arrows  dotPosition="bottom">
      {msg.imageNames.map((img, indexImage) => (
        <div key={indexImage}>
          <Image
            src={baseurlImg2 + '/uploads/properties/' + img}
            alt={`Image ${indexImage}`}
            style={{
              width: '100%', // Full width of the parent container
              height: '200px', // Set a consistent height
              objectFit: 'cover', // Ensure the image fits without distortion
              borderRadius: '8px', // Optional: Rounded corners
            }}
          />
        </div>
      ))}
    </Carousel>
  </div>
)}
          </div>
              </div>

              {/* Message Timestamp (date) */}
              <span
                style={{
                  marginTop: '4px',
                  fontSize: '12px',
                  color: '#888',
                }}
              >
                {msg.date}
              </span>
            </div>
            </div>

            {/* Replies Section */}
            {msg.replies.length > 0 && (
              <>
                <div
                  style={{
                    marginTop: '5px',
                    marginLeft: '40px',
                    cursor: 'pointer',
                    color: '#007bff',
                    display: 'flex',
                    justifyContent: isSender ? 'flex-end' : 'flex-start',
                    textDecoration: 'underline',
                  }}
                  onClick={() => toggleReplies(index)}
                >
                  {isRepliesOpen
                    ? `Hide Replies (${msg.replies.length})`
                    : `Show Replies (${msg.replies.length})`}
                </div>

                {isRepliesOpen && (
                  <div style={{ marginLeft: '40px' }}>
                    {msg.replies.map((reply, indexReply) => (
                      <div
                        key={indexReply}
                        style={{
                          marginBottom: '10px',
                          display: 'flex',
                          flexDirection:'column',
                          justifyContent: isSender ? 'flex-end' : 'flex-start',
                          marginLeft: '40px',
                          padding: '8px 12px',
                          backgroundColor: '#e1f7d5', // Lighter green for replies
                          color: '#000',
                          borderRadius: '10px',
                          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                        }}
                      >
                        {/* Replier Name and Profile */}
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                          {reply.replier && reply.replier.profile ? (
                            <img
                              src={baseurlImg + '/uploads/profiles/' + reply.replier.profile}
                              alt={reply.replier.name}
                              style={{
                                width: '30px',
                                height: '30px',
                                borderRadius: '50%',
                                marginRight: '8px',
                              }}
                            />
                          ) : (
                            <div
                              style={{
                                width: '30px',
                                height: '30px',
                                borderRadius: '50%',
                                backgroundColor: '#ccc',
                                marginRight: '8px',
                              }}
                            />
                          )}
                       
                       {reply.replier.id != user.id?
                <a onClick={() =>handleChatCurrentUser(reply.replier)} style={{ fontWeight: 'bold' }}>

                   {reply.replier?.name}
                  </a>: reply.replier?.name}
                           
                        </div>

                        {/* Reply Content */}
                        <div style={{display:'flex',flexDirection:'column'}}>
                        <p>{reply.comment}</p>
                        {reply?.audioFilename? <div style={{ marginLeft: '10px' }}>
          <audio controls>
            <source src={baseurlImg2 + '/uploads/audios/'+reply?.audioFilename} type="audio/webm" />
        
          </audio>
        </div>:null}
                        <div >
          {reply?.imageNames?.length > 0 && (
  <div style={{ width: '100%', maxWidth: '300px', margin: '0 auto', paddingTop: '10px' }}>
    <Carousel  prevArrow={<div style={arrowStyle}>{'<'}</div>} // Custom previous arrow
      nextArrow={<div style={arrowStyle}>{'>'}</div>} arrows  dotPosition="bottom">
      {reply.imageNames.map((img, indexImage) => (
        <div key={indexImage}>
          <Image
            src={baseurlImg2 + '/uploads/properties/' + img}
            alt={`Image ${indexImage}`}
            style={{
              width: '100%', // Full width of the parent container
              height: '200px', // Set a consistent height
              objectFit: 'cover', // Ensure the image fits without distortion
              borderRadius: '8px', // Optional: Rounded corners
            }}
          />
        </div>
      ))}
    </Carousel>
  </div>
)}
          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </>
            )}

            <div  style={{
        display: 'flex',
        justifyContent: isSender ? 'flex-end' : 'flex-start', // Align right for sender, left for receiver
        width: '100%', // Take full width of the parent container
        paddingBottom: '5px',
        marginBottom: '50px', // Add space between each message
      }}>
            <ReplyInput
          index={0}
          onSend={(message, files,audioURL) => {handleReply(message,files,audioURL,msg.id)}}
          onAttach={(file) => console.log('File attached:', file)}
            />
            </div>
          </div>
        );
      })}
      </>}

      
      </TabPane>
              
 
    </Tabs>
    <div
    style={{
      position: 'fixed',
      bottom: 0,
      width: '100%',
      backgroundColor: '#fff',
      padding: '10px',
      marginTop:'10px',
   
      zIndex: 1000,
    }}
  >
    <MessageInput
      onSend={(message, files,audioURL) => {
        handleMessage(message, files,audioURL);
      }}
      onAttach={(file) => console.log('File attached:', file)}
    />
  </div>
      </Drawer>
 
                <Menu
                  theme="light"
                  mode="horizontal"
                  defaultSelectedKeys={["2"]}
                  items={[]}
                />
                
              </Header>

              <Layout>
                {width > 720 ? (
                  <Sider trigger={null} collapsible collapsed={collapsed}>
                    <NavItems />
                  </Sider>
                ) : (
                  <Drawer
                    style={{
                      padding: "0px !important",
                      marginTop: "200px !important",
                    }}
                    closable={true}
                    onClose={onClose}
                    visible={visible}
                    className="mobile-sidebar"
                    placement={"left"}
                    key={"left"}
                  >
                    <NavItems />
                  </Drawer>
                )}

                <Layout
                  style={{
                    padding: "0 24px 24px",
                  }}
                >
                  <Content
                    className="site-layout-background"
                    style={{
                      padding: 24,
                      margin: 0,
                      minHeight: 280,
                    }}
                  >
              

                    <PrivateRoute
                      exact
                      path="/dashboard"
                      component={Dashboard}
                    />
                    <PrivateRoute exact path="/profile" component={Profile} />
                    <PrivateRoute
                      exact
                      path="/change-password"
                      component={Changepassword}
                    />
                    {/* admin routes */}
                    <AdminPrivate
                      exact
                      path="/categories"
                      // roles={[1]}
                      component={Categories}
                    />
                    <AdminPrivate
                      exact
                      path="/edit-categories/:id"
                      //roles={[1]}
                      component={EditCategory}
                    />

                    <AdminPrivate
                      exact
                      path="/propertytypes"
                      component={PropertyType}
                    />

                      <AdminPrivate
                      exact
                      path="/realestatesguide"
                      component={Realstates}
                    />

                    
                  <AdminPrivate
                      exact
                      path="/service-providers"
                      component={ServiceProviders}
                    />
                    <AdminPrivate
                      exact
                      path="/edit-propertytype/:id"
                      component={EditPropertyType}
                    />
                    <AdminPrivate exact path="/addemp" component={NewUser} />
                    <AdminPrivate
                      exact
                      path="/employees"
                      component={Employees}
                    />
                    <AdminPrivate
                      exact
                      path="/edit-employee/:id"
                      component={EditEmployee}
                    />
                    <AdminPrivate
                      exact
                      path="/agencies"
                      component={ListAgencies}
                    />
                    <AdminPrivate
                      exact
                      path="/add-agency"
                      component={NewAgency}
                    />
                    <AdminPrivate
                      exact
                      path="/company-agents"
                      component={AgencyAgents}
                    />
                    <AdminPrivate
                      exact
                      path="/company-properties"
                      component={AgencyProperties}
                    />
                    <AdminPrivate
                      exact
                      path="/company-agents-properties"
                      component={AgencyAgentsAndProperties}
                    />
                    <AdminPrivate
                      exact
                      path="/show-agency-details/:id"
                      component={ShowAgencyDetails}
                    />
                    <AdminPrivate exact path="/leads" component={Leads} />

                    <AdminPrivate exact path="/calls/:id" component={Call} />

                    <AdminPrivate exact path="/clicks/:id" component={Click} />

                    <AdminPrivate
                      exact
                      path="/whatsapp/:id"
                      component={Whatsapp}
                    />

                    <AdminPrivate
                      exact
                      path="/leads-report"
                      component={AgencyLeadsReport}
                    />
                    {/* agencies route */}
                    <AgencyPrivate exact path="/agents" component={Agents} />
                    <AgencyPrivate
                      exact
                      path="/addnewuser"
                      component={AddNewUser}
                    />
                    <AgencyPrivate
                      exact
                      path="/edit-agent/:id"
                      component={EditAgent}
                    />

                    <AdminPrivate
                      exact
                      path="/admin-edit-agent/:id"
                      component={AdminEditAgent}
                    />

                    <PrivateRoute
                      exact
                      path="/managelistings"
                      component={Managelisting}
                    />
                    <AgencyPrivate
                      exact
                      path="/usage-quota"
                      component={UsageList}
                    />
                    <AdminPrivate
                      exact
                      path="/edit-agency/:id"
                      component={EditAgency}
                    />
                    <AgencyPrivate
                      path="/show-agency/:id"
                      component={ShowAgency}
                    />
                    <AgencyPrivate
                      exact
                      path="/addProperty"
                      component={AddProperty}
                    />
                    <AgencyPrivate
                      exact
                      path="/editProperty/:id"
                      component={EditProperty}
                    />
                    <AgentPrivate
                      exact
                      path="/add-Property"
                      component={AgentProperty}
                    />
                    <AgentPrivate
                      exact
                      path="/editagentProperty/:id"
                      component={EditAgentProperty}
                    />

                    <PrivateRoute exact path="/blogs" component={Listblogs} />
                    <PrivateRoute exact path="/newblog" component={Newblog} />
                    <PrivateRoute
                      exact
                      path="/edit-blog/:id"
                      component={Editblog}
                    />
                    <AgentPrivate
                      exact
                      path="/agentmanagelistings"
                      component={AgentManagelisting}
                    />
                    <PrivateRoute exact path="/pages" component={Listpages} />
                    <PrivateRoute exact path="/newpage" component={Newpage} />
                    <PrivateRoute
                      exact
                      path="/edit-page/:id"
                      component={Editpage}
                    />
                    <AdminPrivate
                      exact
                      path="/adminlisting"
                      component={AdminManagelist}
                    />
                    <AdminPrivate
                      exact
                      path="/admindraft"
                      component={AdminDraft}
                    />
                    <AdminPrivate
                      exact
                      path="/adminpending"
                      component={Adminpending}
                    />
                    <AdminPrivate
                      exact
                      path="/adminrejected"
                      component={AdminRejected}
                    />
                    <AdminPrivate
                      exact
                      path="/admineditlist/:id"
                      component={AdminEditList}
                    />
                    <SeoPrivate
                      exact
                      path="/seolisting"
                      component={SeoManagelisting}
                    />
                    <SeoPrivate
                      exact
                      path="/seoeditlist/:id"
                      component={SeoEditList}
                    />
                    <AgencyPrivate
                      exact
                      path="/pending"
                      component={Pendinglisting}
                    />
                    <AgencyPrivate
                      exact
                      path="/publish"
                      component={Publishlisting}
                    />
                    <AgencyPrivate
                      exact
                      path="/edit-Prop/:id"
                      component={EditPublishProperty}
                    />
                    <AgencyPrivate
                      exact
                      path="/rejected"
                      component={Rejectedlisting}
                    />
                    <AgencyPrivate
                      exact
                      path="/edit-Property/:id"
                      component={EditRejectedProperty}
                    />
                    <AgencyPrivate
                      exact
                      path="/draft"
                      component={Draftlisting}
                    />

                    <AgencyPrivate
                      exact
                      path="/all-leads"
                      component={AgencyLeads}
                    />

                 

                    <AgentPrivate
                      exact
                      path="/pending-property"
                      component={AgentPendinglisting}
                    />
                    <AgentPrivate
                      exact
                      path="/publish-property"
                      component={AgentPublishlisting}
                    />
                    <AgentPrivate
                      exact
                      path="/editprop/:id"
                      component={EditPublishAgent}
                    />
                    <AgentPrivate
                      exact
                      path="/rejected-property"
                      component={AgentRejectedlisting}
                    />
                    <AgentPrivate
                      exact
                      path="/edit-agentProperty/:id"
                      component={EditRejectedAgentProperty}
                    />
                    <AgentPrivate
                      exact
                      path="/draft-property"
                      component={AgentDraftlisting}
                    />
                    <AgentPrivate
                      exact
                      path="/allleads"
                      component={AgentLeads}
                    />

                    {/* <PrivateRoute exact path="*">
                <Notfound />
              </PrivateRoute> */}
                  </Content>
                </Layout>
              </Layout>
            </Layout>
          )}
        </>
      ) : (
        <Redirect to="/" />
      )}
    </>
  );
};

export default SidebarComponent;
