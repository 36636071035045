import React, { Component } from "react";

import i18next from "i18next";
import Swal from "sweetalert2";

import Moment from "react-moment";
import { Link } from "react-router-dom";

import "antd/dist/antd.css";
import { Table, Avatar ,Breadcrumb} from "antd";
import { ArrowRightOutlined, ArrowLeftOutlined } from "@ant-design/icons";

import { itemRender, onShowSizeChange } from "../../../paginationfunction";
import "../../../antdstyle.css";
import { connect } from "react-redux";


import {  baseurlImg } from "../../../../Components/BaseUrl";
import API from "../../../../utils/API";

class ListAgencies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      agencies: [],
    };
  }

  componentDidMount() {
    const page = 1;
    const perPage = 10;
    const data = {searchData:""}
    this.fetchData(page, perPage,data);
  }

  fetchData = (page, perPage,data) => {
  
    this.setState({ isLoading: true });
    API.get(`/agencies?page=${page}&perPage=${perPage}`,{params:data})
      .then(async (response) => {
       
        await response.data.agencies;
        this.setState({ isLoading: false, agencies: response.data.agencies });
      })
      .catch((e) => console.log("error"));
  };

  handelDelete = (agency, e) => {
    e.preventDefault();

    if (!agency || agency < 1) {
      return;
    } else {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          API.delete("/agency/" + agency)
            .then((response) => {
              Swal.fire("Deleted!", "Your file has been deleted.", "success");
              const page = 1;
              const perPage = 10;
              const data = {searchData:""}
              this.fetchData(page, perPage,data);
            })
            .catch((err) => console.log(err));
        }
      });
    }
  };

  handleSearch = (e) =>{
    e.preventDefault()
    const page = 1;
    const perPage = 10;
    const data = {searchData : e.target.value}

     
    this.fetchData(page, perPage,data);
  }

  render() {
    const { lang } = this.props;

    const current = new Date();
    const date = `${current.getDate()}/${
      current.getMonth() + 1
    }/${current.getFullYear()}`;

    const columns = [
      {
        title: lang=="en"? " Name":"الاسم",
        dataIndex: "name_en",
        sorter: (a, b) => a.name_en.length - b.name_en.length,
      },
      {
        title: lang=="en"? " Email":"البريد الالكتروني" ,
        dataIndex: "email",
        sorter: (a, b) => a.email.length - b.email.length,
      },
      {
        title: lang=="en"? " Mobile":"الجوال" ,
        dataIndex: "mobile",
       // sorter: (a, b) => a.mobile.length - b.mobile.length,
      },
      {
        title: lang=="en"? " Package":"الحزمة" ,
        dataIndex: "totalpackage",
        sorter: (a, b) => a.totalpackage.length - b.totalpackage.length,
      },
      {
        title: lang == "en" ? "Profile" : "  الملف",
        render: (text, record) => (
          <>
            {record.profile != "" ||
            record.profile != null ||
            !record.profile ? (
              <Avatar
                size="large"
                style={{ backgroundColor: "#87d068", marginRight: "1rem" }}
                icon={
                  <img
                    style={{ height: "35px", width: "35px" }}
                    src={baseurlImg + "/uploads/profiles/" + record.logo}
                  />
                }
              />
            ) : (
              <Avatar
                size="large"
                style={{ backgroundColor: "#87d068", marginRight: "1rem" }}
                icon={
                  <img
                    style={{ height: "35px", width: "35px" }}
                    src={baseurlImg + "/uploads/profiles/no_avatar.png"}
                  />
                }
              />
            )}
          </>
        ),
      },
      {
        title: "Trade licence",
        dataIndex: "tradelicense",
        //sorter: (a, b) => a.tradelicense.length - b.tradelicense.length,
      },
      {
        title: "Expire date",
        render: (text, record) => (
          <Moment format="YYYY/MM/DD">{record.expire_date}</Moment>
        ),
      },

      {
        title: lang == "en" ? "Status" : "الحالة",
        render: (text, record) => (
          <>
            {record.active == 1 ? (
              <span className="badge light badge-success">
                {lang == "en" ? "Active" : "نشط"}
              </span>
            ) : (
              <span className="badge light badge-danger">
                {lang == "en" ? "No active" : "غير نشط"}
              </span>
            )}
          </>
        ),
      },

      {
        title: lang == "en" ? "Actions" : "العمليات",
        render: (text, record) => (
          <div className="ms-auto">
            <Link
              className="btn btn-sm btn-primary shadow  "
              to={"edit-agency/" + record.id}
            >
              <i className="fa fa-edit"></i>
            </Link>
            <a
              className="btn btn-sm btn-danger shadow  "
              onClick={this.handelDelete.bind(this, record.id)}
            >
              <i className="fa fa-trash"></i>
            </a>
          </div>
        ),
      },
    ];
    const handleTableChange = (pagination) => {
      const data = {searchData:""}
      this.fetchData(pagination.current, pagination.pageSize,data);
    };

    return (
      <div>
        <div className="container-fluid">
     

          <div class="card" style={{margin:'3px'}}>
  <div class="card-header justify-content-between">
  <Breadcrumb
            style={{
              margin: "16px 0",
            }}
          >

            <Breadcrumb.Item>
              {lang == "en" ? <ArrowRightOutlined /> : <ArrowLeftOutlined />}
            </Breadcrumb.Item>
            
            <Breadcrumb.Item>
              {lang == "en" ? "All companies" : "الشركات"}
            </Breadcrumb.Item>
          </Breadcrumb>
          <div class="col-md-5">
  
  <input type="email" class="form-control" onChange={this.handleSearch} placeholder="Search by Name , email or mobile" />
</div>

          <Link to="/add-agency" className="btn btn-primary mb-2">
                +  {lang == "en" ? "New company" : "شركة جديدة"}

              </Link>{" "}
  </div>

</div>
        

          <div className="row">
            <div className="col-12">
              <div className="card">
            
                <div className="card-body">
                  <div className="table-responsive">
          
                      <Table
                        className="table-striped"
                        loading={this.state.isLoading}
                        onChange={handleTableChange}
                        pagination={{
                          pageSizeOptions: ["10", "20", "50", "100"],
                          total:
                            this.state?.agencies?.total > 0 &&
                            this.state.agencies.total,
                          pageSize: this.state.agencies.per_page,
                          current: this.state.agencies.current_page,
                          showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                          showSizeChanger: true,
                          onShowSizeChange: onShowSizeChange,
                          itemRender: itemRender,
                        }}
                        style={{ overflowX: "auto", textAlign: "right" }}
                        columns={columns}
                  
                        dataSource={
                          this.state?.agencies?.data
                            ? this.state?.agencies?.data
                            : []
                        }
                        rowKey={(record) => record}
                      />
              
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  lang: state.auth.lang, 
});
export default connect(mapStateToProps)(ListAgencies);

