import React, { Component, useEffect, useState } from "react";

import i18next from "i18next";
import Swal from "sweetalert2";

import Moment from "react-moment";
import { Link } from "react-router-dom";
import './service.css'
import "antd/dist/antd.css";
import { Table, Avatar ,Breadcrumb,Modal } from "antd";
import { ArrowRightOutlined, ArrowLeftOutlined } from "@ant-design/icons";

import { itemRender, onShowSizeChange } from "../../../paginationfunction";
import "../../../antdstyle.css";


import {  baseurlImg } from "../../../../Components/BaseUrl";
import API from "../../../../utils/API";
import AddServiceProvider from "./AddServiceProvider";
import UpdateServiceProvider from "./UpdateServiceProvider";

function ServiceProviders () {

const [agencies,setAgencies] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
const [updateData,setUpdateData] = useState({})
  
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showUpdateModal = (record) => {


    setUpdateData(record)
    setIsUpdateModalOpen(true);
  };
  const handleUpdateOk = () => {
    setIsUpdateModalOpen(false);
  };
  const handleUpdateCancel = () => {
    setIsUpdateModalOpen(false);
  };

  useEffect(() => {
    const page = 1;
    const perPage = 10;
    const data = {searchData:""}
    fetchData(page, perPage,data);
  },[])

  




  const fetchData = (page, perPage,data) => {
  
    setIsLoading(true)
    API.get(`/service_providers?page=${page}&perPage=${perPage}`,{params:data})
      .then(async (response) => {
       
        await response.data.service_providers;
        setIsLoading(false)
        setAgencies(response.data.service_providers)      })
      .catch((e) => console.log("error"));
  };

const  handelDelete = (agency, e) => {
    e.preventDefault();

    if (!agency || agency < 1) {
      return;
    } else {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          API.delete("/service_providers/" + agency)
            .then((response) => {
              Swal.fire("Deleted!", "Your file has been deleted.", "success");
              const page = 1;
              const perPage = 10;
              const data = {searchData:""}
              fetchData(page, perPage,data);
            })
            .catch((err) => console.log(err));
        }
      });
    }
  };

 const  handleSearch = (e) =>{
    e.preventDefault()
    const page = 1;
    const perPage = 10;
    const data = {searchData : e.target.value}

     
    fetchData(page, perPage,data);
  }

 
    const current = new Date();
    const date = `${current.getDate()}/${
      current.getMonth() + 1
    }/${current.getFullYear()}`;
    const lang = localStorage.getItem("lang") || "en";
    const listStyles = {
      listStyleType: 'disc',
      marginLeft: '20px',
      paddingLeft: '20px',
    };
    const columns = [
      {
        title: "Service Types",
        dataIndex: "service_type",
        render: (text, record) => (
          <ul style={listStyles}>
            {record.service_types.map((service_type, index) => (
              <li key={index}>{service_type.label}</li>
            ))}
          </ul>
        )
        
      },
      {
        title: "Emirates",
        dataIndex: "emirate",
       // sorter: (a, b) => a.mobile.length - b.mobile.length,
      },
      {
        title: i18next.t("name"),
        dataIndex: "name_en",
        sorter: (a, b) => a.name_en.length - b.name_en.length,
      },
      {
        title: i18next.t("email"),
        dataIndex: "email",
        sorter: (a, b) => a.email.length - b.email.length,
      },
      {
        title: i18next.t("mobile"),
        dataIndex: "mobile",
       // sorter: (a, b) => a.mobile.length - b.mobile.length,
      },

      {
        title:  "Mobile 2" ,
        dataIndex: "mobile2",
       // sorter: (a, b) => a.mobile.length - b.mobile.length,
      },


      {
        title: lang == "en" ? "Website" : "رابط الموقع",
        dataIndex: "website_url",
       // sorter: (a, b) => a.mobile.length - b.mobile.length,
      },


      {
        title: lang == "en" ? "Logo" : "  شعار",
        render: (text, record) => (
          <>
            {record.profile != "" ||
            record.profile != null ||
            !record.profile ? (
              <Avatar
                size="large"
                style={{ backgroundColor: "#87d068", marginRight: "1rem" }}
                icon={
                  <img
                    style={{ height: "35px", width: "35px" }}
                    src={baseurlImg + "/uploads/services/" + record.image}
                  />
                }
              />
            ) : (
              <Avatar
                size="large"
                style={{ backgroundColor: "#87d068", marginRight: "1rem" }}
                icon={
                  <img
                    style={{ height: "35px", width: "35px" }}
                    src={baseurlImg + "/uploads/profiles/no_avatar.png"}
                  />
                }
              />
            )}
          </>
        ),
      },
 

      {
        title: lang == "en" ? "Actions" : "العمليات",
        render: (text, record) => (
          <div className="ms-auto">
            <button
              className="btn btn-sm btn-primary shadow  "
             
              onClick={() => showUpdateModal(record)}
            >
              <i className="fa fa-edit"></i>
            </button>
            <a
              className="btn btn-sm btn-danger shadow  "
              onClick={handelDelete.bind(this, record.id)}
            >
              <i className="fa fa-trash"></i>
            </a>
          </div>
        ),
      },
    ];
    const handleTableChange = (pagination) => {
      const data = {searchData:""}
      fetchData(pagination.current, pagination.pageSize,data);
    };

    const refreshPage = ()=>{
        const page = 1;
        const perPage = 10;
        const data = {searchData:""}
        setIsModalOpen(false)
        setIsUpdateModalOpen(false)

        fetchData(page, perPage,data); 
    }

    return (
      <div>
        <Modal title="New provider" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={null}>
      <AddServiceProvider xCLoseModal={() => refreshPage()} />
      </Modal>

      <Modal title="Update provider" open={isUpdateModalOpen} onOk={handleUpdateOk} onCancel={handleUpdateCancel} footer={null}>
      <UpdateServiceProvider xCLoseModal={() => refreshPage()} updateData = {updateData} />
      </Modal>
        <div className="container-fluid">
     

          <div class="card" style={{margin:'3px'}}>
  <div class="card-header justify-content-between">
  <Breadcrumb
            style={{
              margin: "16px 0",
            }}
          >

            <Breadcrumb.Item>
              {lang == "en" ? <ArrowRightOutlined /> : <ArrowLeftOutlined />}
            </Breadcrumb.Item>
            
            <Breadcrumb.Item>
              {lang == "en" ? "Service providers" : " مزودي الخدمات"}
            </Breadcrumb.Item>
          </Breadcrumb>
          <div class="col-md-5">
  
  <input type="email" class="form-control" onChange={handleSearch} placeholder="Search by Name , email or mobile" />
</div>

          <button onClick={showModal} className="btn btn-primary mb-2">
                +  {lang == "en" ? "New provider" : "مزود جديدة"}

              </button>{" "}
  </div>

</div>
        

          <div className="row">
            <div className="col-12">
              <div className="card">
            
                <div className="card-body">
                  <div className="table-responsive">
          
                      <Table
                        className="table-striped"
                        loading={isLoading}
                        onChange={handleTableChange}
                        pagination={{
                          pageSizeOptions: ["10", "20", "50", "100"],
                          total:
                            agencies?.total > 0 &&
                            agencies.total,
                          pageSize: agencies.per_page,
                          current: agencies.current_page,
                          showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                          showSizeChanger: true,
                          onShowSizeChange: onShowSizeChange,
                          itemRender: itemRender,
                        }}
                        style={{ overflowX: "auto", textAlign: "right" }}
                        columns={columns}
                  
                        dataSource={
                          agencies?.data
                            ? agencies?.data
                            : []
                        }
                        rowKey={(record) => record}
                      />
              
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

export default ServiceProviders;
